import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { curveCardinal } from "d3-shape";

const cardinal = curveCardinal.tension(0.2);

const LineGraph = ({ gradeData }) => {
  return (
    <div className="bg-white rounded-[20px] w-full h-[500px] py-6 px-6 flex justify-center items-center">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={gradeData?.grade_data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="student_name" />
          <YAxis />
          <Tooltip />
          <Area
            type={cardinal}
            dataKey="score"
            stroke="#82ca9d"
            fill="#82ca9d"
            fillOpacity={0.2}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default LineGraph;
