/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ButtonLoader_1 } from "../shared/Loaders";
import useAddClass from "../../hooks/useAddClass";
import useEditClass from "../../hooks/useEditClass";
const validationSchema = Yup.object({
  class_name: Yup.string()
    .required("Class name is required")
    .max(50, "Class name must be at most 50 characters"),
});

function AddClassModal(props) {
  const cancelButtonRef = useRef(null);
  const { loading, addClass } = useAddClass();
  const { loading1, editClass } = useEditClass();

  const handleClose = () => {
    props.setIsOpenModal(false);
    props.setSelectedClass(null);
    props.setIsEditMode(null);
    resetForm();
  };

  const initialValues = {
    class_id: props.selectedClass?.id,
    class_name: props.selectedClass?.class_name,
  };
  const { values, errors, handleChange, handleSubmit, touched, resetForm } =
    useFormik({
      initialValues: initialValues,
      enableReinitialize: true,
      validationSchema: validationSchema,
      onSubmit: async (values, action) => {
        if (props.isEditMode) {
          await editClass(
            {
              class_id: values.class_id,
              class_name: values.class_name,
            },
            handleClose
          );
        } else {
          await addClass(values, handleClose);
        }
      },
    });

  return (
    <Transition.Root show={props.isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 xs:p-1 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#fff]  text-left shadow-xl transition-all 2xl:w-[500px] lg:w-[500px] xs:w-full h-auto xs:mx-2 md:p-6 xs:p-3">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={handleClose} />
                </div>
                <div className="flex flex-col justify-center items-center">
                  <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[600] lg:text-[32px]  font-roboto text-[#1C1D1E]">
                    {props.isEditMode ? "Edit Class" : "Add Class"}
                  </h1>
                  <p className="font-[400] 2xl:text-[18px] lg:text-[15px] font-roboto text-[#737373]">
                    Unlock New Opportunities
                  </p>
                </div>
                <form onSubmit={handleSubmit} className="w-full flex mt-3">
                  <div className="w-full">
                    <div className="w-full flex flex-col ">
                      <div className="w-full flex gap-2 ">
                        <div className="w-full my-2">
                          <label
                            htmlFor=""
                            className="2xl:font-[400] 2xl:text-[20px] lg:font-[400] lg:text-[18px]  font-roboto"
                          >
                            Class name*
                          </label>
                          <input
                            placeholder="Enter class name..."
                            type="text"
                            name="class_name"
                            value={values.class_name}
                            onChange={handleChange}
                            className="border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
                          />
                          {errors.class_name && touched.class_name && (
                            <p className="text-red-700 text-xs mt-2">
                              {errors.class_name}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end w-full gap-2  mt-5 cursor-pointer text-white">
                      <button
                        type="submit"
                        className="bg-[#1C1D1E] font-[600] text-white w-[120px] h-[45px] rounded-[10px] flex justify-center items-center"
                        disabled={loading || loading1}
                      >
                        {loading || loading1 ? <ButtonLoader_1 /> : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
export default AddClassModal;
