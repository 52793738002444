import axios from "axios";
import { useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import useRefreshToken from "./useRefreshToken";
import { removeAccessToken, removeRefreshToken } from "../storage/storage";

const useCurrentUser = () => {
  const { RefreshToken } = useRefreshToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { setUserInfo, setUpdateProfile } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const getCurrentUser = async (token) => {
    setLoading(false);
    await axios
      .get(`${BASE_URL}/api/user-profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setUpdateProfile(false);
        setUserInfo(response.data);
      })
      .catch((error) => {
        setLoading(false);
        if (error?.response?.status === 401 && token) {
          RefreshToken();
          removeAccessToken();
          removeRefreshToken();
          window.location.href = "/";
        }
      });
  };
  return { loading, getCurrentUser };
};
export default useCurrentUser;
