import axios from "axios";
import { useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import useSessionStorage from "./useSessionStorage";
import { getAccessToken } from "../storage/storage";

const useGetTeachers = () => {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [teachersData, setTeachersData] = useState(null);
  const [teacherLoading, setTeacherLoading] = useState(true);
  const [teacherMessage, setTeacherMessage] = useState(null);
  const [totalPagesTeacher, setTotalPagesTeacher] = useState();
  const [currentPageTeacher, setCurrentPageTeacher] = useSessionStorage(
    "currentPageTeacher",
    1
  );
  const { setUpdateResponse } = useContext(GlobalContext);

  const GetTeachers = async (filter) => {
    await axios
      .get(`${BASE_URL}/api/teacher/${filter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTeacherLoading(false);
        setTeachersData(response.data.results);
        setTotalPagesTeacher(response.data.num_pages);
        setUpdateResponse(false);
        if (response.data.results.length === 0) {
          setTeacherMessage("No records found");
        } else {
          setTeacherMessage(null);
        }
      })
      .catch((error) => {
        setTeacherLoading(false);
        setTeacherMessage("Oops! Something went wrong");
      });
  };
  return {
    teacherLoading,
    GetTeachers,
    teachersData,
    teacherMessage,
    totalPagesTeacher,
    currentPageTeacher,
    setTotalPagesTeacher,
    setCurrentPageTeacher,
  };
};
export default useGetTeachers;
