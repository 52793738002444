import axios from "axios";
import { useState } from "react";
import { getAccessToken } from "../storage/storage";

function useGetTeachersDetail() {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [teachersDetail, setTeachersDetail] = useState(null);
  const [teacherDetailLoading, setTeacherDetailLoading] = useState(true);
  const [teachersDetailMessage, setTeachersDetailMessage] = useState(null);

  const GetTeachersDetail = async (id) => {
    await axios
      .get(`${BASE_URL}/api/teacher/details?id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTeachersDetail(response.data);
        setTeacherDetailLoading(false);
      })
      .catch((error) => {
        setTeacherDetailLoading(false);
        // setTeachersDetailMessage(
        //   "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later"
        // );
      });
  };

  return {
    GetTeachersDetail,
    teachersDetail,
    teacherDetailLoading,
    teachersDetailMessage,
  };
}

export default useGetTeachersDetail;
