import { useEffect, useState } from "react";

export default function useSessionStorage(key, initialValue) {
  const [storedValue, setStoredValue] = useState(initialValue);
  const [firstLoadDone, setFirstLoadDone] = useState(false);

  useEffect(() => {
    const fromSession = () => {
      if (typeof window === "undefined") {
        return initialValue;
      }
      try {
        const item = window.sessionStorage.getItem(key);
        return item ? JSON.parse(item) : initialValue;
      } catch (error) {
        return initialValue;
      }
    };

    setStoredValue(fromSession);
    setFirstLoadDone(true);
  }, [initialValue, key]);

  useEffect(() => {
    if (!firstLoadDone) {
      return;
    }

    try {
      if (typeof window !== "undefined") {
        window.sessionStorage.setItem(key, JSON.stringify(storedValue));
      }
    } catch (error) {}
  }, [storedValue, firstLoadDone, key]);

  return [storedValue, setStoredValue];
}
