import axios from "axios";
import { useState } from "react";
import { getAccessToken } from "../storage/storage";

function useGetTotalMemebrs() {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [members, setMembers] = useState(null);
  const [membersLoading, setMembersLoading] = useState(true);

  const GetMembers = async () => {
    await axios
      .get(`${BASE_URL}/api/total-member`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setMembersLoading(false);
        setMembers(response.data);
      })
      .catch((error) => {
        setMembersLoading(false);
      });
  };
  return { membersLoading, GetMembers, members };
}

export default useGetTotalMemebrs;
