/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useRef } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ButtonLoader_1 } from "../shared/Loaders";
import useEditGrade from "../../hooks/useEditGrade";
import useAddGrade from "../../hooks/useAddGrade";
const validationSchema = Yup.object({
  grade: Yup.number().required("Grade name is required"),
});

function AddGradeModal(props) {
  const cancelButtonRef = useRef(null);
  const { loading, addGrade } = useAddGrade();
  const { loading1, editGrade } = useEditGrade();

  const handleClose = () => {
    props.setIsOpenModal(false);
    props.setIsEditMode(false);
    props.setSelectedGrade(null);
    resetForm();
  };

  const initialValues = {
    grade: props.selectedGrade?.grade_id,
  };

  const { values, errors, handleChange, handleSubmit, resetForm } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, action) => {
      if (props.isEditMode) {
        await editGrade(
          {
            id: props.selectedGrade?.grade_id,
            class_id: parseInt(props.class_id),
            grade: values.grade,
          },
          handleClose
        );
      } else {
        await addGrade(
          {
            class_id: parseInt(props.class_id),
            grade: values.grade,
          },
          handleClose
        );
      }
    },
  });

  return (
    <Transition.Root show={props.isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 xs:p-1 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#fff]  text-left shadow-xl transition-all 2xl:w-[500px] lg:w-[500px] xs:w-full h-auto xs:mx-2 md:p-6 xs:p-3">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={handleClose} />
                </div>
                <div className="flex flex-col justify-center items-center">
                  <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[600] lg:text-[32px]  font-roboto text-[#1C1D1E]">
                    {props.isEditMode ? "Edit Grade" : "Add Grade"}
                  </h1>
                  <p className="font-[400] 2xl:text-[18px] lg:text-[15px] font-roboto text-[#737373]">
                    Unlock New Opportunities
                  </p>
                </div>
                <form onSubmit={handleSubmit} className="w-full flex mt-3">
                  <div className="w-full">
                    <div className="w-full flex flex-col ">
                      <div className="w-full flex gap-2 ">
                        <div className="w-full my-2">
                          <label
                            htmlFor=""
                            className="2xl:font-[400] 2xl:text-[20px] lg:font-[400] lg:text-[18px]  font-roboto"
                          >
                            Grade name*
                          </label>
                          <div className="custom-select w-full h-[50px]">
                            <select
                              id="demo-simple-select"
                              name="grade"
                              value={values.grade}
                              onChange={handleChange}
                              style={{
                                borderColor: "#9D9D9D",
                                borderRadius: "4px",
                              }}
                            >
                              <option disabled selected>
                                Select Grade
                              </option>
                              <option value={"0"}>Grade: 0</option>
                              <option value={"1"}>Grade: 1</option>
                              <option value={"2"}>Grade: 2</option>
                            </select>
                          </div>
                          {errors.grade && (
                            <p className="text-red-700 text-xs mt-2">
                              {errors.grade}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end w-full gap-2  mt-5 cursor-pointer text-white">
                      <button
                        type="submit"
                        className="bg-[#1C1D1E] font-[600] text-white w-[120px] h-[45px] rounded-[10px] flex justify-center items-center"
                        disabled={loading || loading1}
                      >
                        {loading || loading1 ? <ButtonLoader_1 /> : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
export default AddGradeModal;
