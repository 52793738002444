const config = {
  seo: {
    title: "ELEMENT EDUCATION",
    description:
      "Element of Education is a dynamic web application designed to enhance learning experiences with interactive modules and personalized curriculum pathways.",
    url: "https://proj-element-education.vercel.app/",
    image: "",
    site_name: "ELEMENT EDUCATION",
    twitter: {
      handle: "@handle",
      cardType: "summary_large_image",
    },
  },
  baseUrl: "",
  assetsUrl: "",
};

export default config;
