/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlobalContext from "../context/GlobalContext";
import { RiEditFill } from "react-icons/ri";
import useUpdateProfile from "../hooks/useUpdateProfile";
import { ButtonLoader_1 } from "./shared/Loaders";

const validationSchema = Yup.object({
  first_name: Yup.string()
    .required("Name is required")
    .max(50, "Name must be at most 50 characters"),
  email: Yup.string()
    .required("Email is required")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Invalid email format"
    )
    .test("has-at-symbol", "Email must contain @ symbol", (value) =>
      value ? value.includes("@") : true
    )
    .max(255, "Email must be at most 255 characters"),
  date_of_birth: Yup.string().nullable(),
  gender: Yup.string().required("Gender is required"),
  joining_date: Yup.string().nullable(),
  qualification: Yup.string()
    .nullable()
    .max(50, "Qualification must be at most 50 characters"),
  experience: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, "Experience must contain only digits")
    .max(10, "Experience must be at most 10 digits"),
  address: Yup.string()
    .nullable()
    .max(255, "Address must be at most 255 characters"),
  profile_picture: Yup.string().nullable(),
});

const UpdateProfile = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { userInfo } = useContext(GlobalContext);
  const [imageSrc, setImageSrc] = useState(null);
  const { UpdateProfile, loading } = useUpdateProfile();

  const initialValues = {
    first_name: userInfo?.user?.first_name,
    email: userInfo?.user?.email,
    date_of_birth: userInfo?.user?.date_of_birth,
    gender: userInfo?.user?.gender,
    joining_date: userInfo?.joining_date,
    qualification: userInfo?.qualification,
    experience: userInfo?.experience,
    address: userInfo?.user?.address,
    profile_picture: userInfo?.user?.profile_picture,
  };

  const { values, errors, handleChange, handleSubmit, touched, setFieldValue } =
    useFormik({
      enableReinitialize: true,
      initialValues: initialValues,
      validationSchema: validationSchema,
      onSubmit: async (values, action) => {
        if (imageSrc) {
          await UpdateProfile({
            id: userInfo?.id,
            user: userInfo?.user?.id,
            first_name: values?.first_name,
            email: values?.email,
            date_of_birth: values?.date_of_birth,
            gender: values?.gender,
            joining_date: values?.joining_date,
            qualification: values?.qualification,
            experience: values?.experience || 0,
            address: values?.address,
            profile_picture: values?.profile_picture,
          });
        } else {
          await UpdateProfile({
            id: userInfo?.id,
            user: userInfo?.user?.id,
            first_name: values?.first_name,
            email: values?.email,
            date_of_birth: values?.date_of_birth,
            gender: values?.gender,
            joining_date: values?.joining_date,
            qualification: values?.qualification,
            experience: values?.experience || 0,
            address: values?.address,
          });
        }
      },
    });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFieldValue("profile_picture", file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-2 mt-1">
      <div className="flex gap-6 items-center">
        <div className="large-circle relative">
          {imageSrc ? (
            <img
              className="rounded-full w-full h-full"
              src={imageSrc}
              style={{ aspectRatio: "1/1", objectFit: "cover" }}
              alt=""
            />
          ) : (
            <>
              {userInfo?.user?.profile_picture ? (
                <img
                  className="rounded-full w-full h-full"
                  src={`${BASE_URL}${userInfo?.user?.profile_picture}`}
                  style={{ aspectRatio: "1/1", objectFit: "cover" }}
                  alt=""
                />
              ) : (
                <img
                  className="rounded-full w-full h-full"
                  src="/avatar_dummy.png"
                  style={{ aspectRatio: "1/1", objectFit: "cover" }}
                  alt=""
                />
              )}
            </>
          )}
          <div className="flex items-center justify-center bg-white  rounded-full w-[30px] h-[30px] border-[2px] border-gray-200 absolute left-[42px] bottom-[-20px] cursor-pointer ">
            <label htmlFor="profile_picture" className="cursor-pointer">
              <RiEditFill className="edit" />
            </label>
            <input
              type="file"
              id="profile_picture"
              name="profile_picture"
              accept=".jpg, .png, .jpeg, .jfif"
              onChange={handleFileChange}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <div className="sm:w-[25%] w-[60%]">
          <h1 className="font-medium">Upload Profile Picture</h1>
          <span className="text-[#788BA5] text-[12px]">
            For Best Results, use an image at least 256px by 256px in either
            .jpg or.png format
          </span>
          {errors.profile_picture && touched.profile_picture && (
            <p className="text-red-700 text-xs mt-1">
              {errors.profile_picture}
            </p>
          )}
        </div>
      </div>

      <div className="w-full flex sm:flex-row flex-col gap-5">
        <div className="flex flex-col w-full">
          <label>Name</label>
          <input
            type="text"
            placeholder="First Name"
            name="first_name"
            value={values.first_name}
            onChange={handleChange}
            className="mt-1 border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
          />
          {errors.first_name && touched.first_name && (
            <p className="text-red-700 text-xs mt-1">{errors.first_name}</p>
          )}
        </div>
        <div className="flex flex-col w-full">
          <label>Email</label>
          <input
            type="text"
            placeholder="Email"
            name="email"
            value={values.email}
            readOnly
            className="mt-1 border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
          />
        </div>
      </div>

      <div className="w-full flex sm:flex-row flex-col gap-5 ">
        <div className="flex flex-col w-full">
          <label>Date of birth</label>
          <input
            type="date"
            name="date_of_birth"
            value={values.date_of_birth}
            onChange={handleChange}
            className="mt-1 border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
          />
          {errors.date_of_birth && touched.date_of_birth && (
            <p className="text-red-700 text-xs mt-1">{errors.date_of_birth}</p>
          )}
        </div>
        <div className="flex flex-col w-full">
          <label>Joining Date</label>
          <input
            type="date"
            name="joining_date"
            value={values.joining_date}
            onChange={handleChange}
            className="mt-1 border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
          />
          {errors.joining_date && touched.joining_date && (
            <p className="text-red-700 text-xs mt-1">{errors.joining_date}</p>
          )}
        </div>
      </div>

      <div className="w-full flex sm:flex-row flex-col gap-5 ">
        <div className="flex flex-col w-full">
          <label>Qualification</label>
          <input
            placeholder="Enter qualification..."
            type="text"
            name="qualification"
            value={values.qualification}
            onChange={handleChange}
            className="mt-1 border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
          />
          {errors.qualification && touched.qualification && (
            <p className="text-red-700 text-xs mt-1">{errors.qualification}</p>
          )}
        </div>
        <div className="flex flex-col w-full">
          <label>Experience</label>
          <input
            type="text"
            placeholder="Enter experience "
            name="experience"
            value={values.experience}
            onChange={handleChange}
            className="mt-1 border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
          />
          {errors.experience && touched.experience && (
            <p className="text-red-700 text-xs mt-1">{errors.experience}</p>
          )}
        </div>
      </div>

      <div className="w-full flex sm:flex-row flex-col gap-5 ">
        <div className="flex flex-col w-full">
          <label>Gender</label>
          <select
            className="mt-1 border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
            id="demo-simple-select"
            name="gender"
            value={values.gender}
            onChange={handleChange}
          >
            <option disabled selected>
              Select Gender
            </option>
            <option value={"M"}>Male</option>
            <option value={"F"}>Female</option>
          </select>
          {errors.gender && touched.gender && (
            <p className="text-red-700 text-xs mt-1">{errors.gender}</p>
          )}
        </div>
        <div className="flex flex-col w-full">
          <label>Address</label>
          <input
            type="text"
            placeholder="Address"
            name="address"
            value={values.address}
            onChange={handleChange}
            className="mt-1 border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
          />
          {errors.address && touched.address && (
            <p className="text-red-700 text-xs mt-1">{errors.address}</p>
          )}
        </div>
      </div>

      <button
        type="submit"
        disabled={loading}
        className="mt-2 w-[200px]  h-[45px] bg-[#1C1D1E] font-[600] text-white rounded-[10px] flex justify-center items-center  cursor-pointer"
      >
        {loading ? <ButtonLoader_1 /> : "Submit"}
      </button>
    </form>
  );
};

export default UpdateProfile;
