import React, { useContext, useEffect, useRef, useState } from "react";
import { HiOutlineDotsVertical } from "react-icons/hi";
import AttemptAssessmentModal from "./modals/AttemptAssessmentModal";
import DeleteModal from "./modals/DeleteModal";
import GlobalContext from "../context/GlobalContext";

function AssessmentCard({ item, index, NavigateToDetailsPage }) {
  const [newAssessmentOpenModal, setNewAssessmentOpenModal] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const percentageCompleted =
    (item.total_students_completed / item.total_students_assigned) * 100;
  const finalPercentage = Math.round(percentageCompleted);

  return (
    <>
      <div
        style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
        className="w-full bg-[#FFFFFF] rounded-[10px] p-[20px] flex flex-col relative cursor-pointer border border-transparent hover:border-[#017663]"
        key={index}
        onClick={NavigateToDetailsPage}
      >
        <span
          className={`2xl:text-[12px] lg:text-[12px] lg:font-[400] font-roboto absolute top-3 right-3 ${
            item.assessment_status === "completed"
              ? "text-[#017663]"
              : "text-[#AD2126]"
          }`}
        >
          {item.status}
        </span>
        <div className="flex flex-col gap-2 mb-4">
          <h1 className="2xl:font-[600] 2xl:text-[16px] lg:font-[500] lg:text-[14px]  font-roboto">
            {item.assessment_name}
          </h1>
          <p className="2xl:font-[400] 2xl:text-[12px] lg:font-[300] lg:text-[11px]  font-roboto text-[#7A7A7A]">
            {/* English literature */}
          </p>
        </div>
        <div className="mb-4">
          <h1 className="2xl:font-[600] 2xl:text-[20px] lg:font-[500] lg:text-[18px]  font-roboto text-[#2D2D2D]">
            {/* English */}
          </h1>
        </div>
        <div className="flex justify-between items-center mb-[5px]">
          <p className="2xl:font-[400] 2xl:text-[12px] lg:font-[300] lg:text-[12px]  font-roboto text-[#2D2D2D]">
            Attempted
          </p>
          <p className="2xl:font-[400] 2xl:text-[12px] lg:font-[300] lg:text-[12px]  font-roboto text-[#2D2D2D]">
            {item.total_students_completed}/{item.total_students_assigned}
          </p>
        </div>
        <div className="w-full bg-[#dcdcdc9d] rounded-full h-2.5">
          <div
            className={`bg-[#017663] h-2.5 rounded-full`}
            style={{ width: `${finalPercentage || 0}%` }}
          />
        </div>
        <div>
          <div
            className="absolute right-1 top-1 p-2 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              handleDropdownToggle();
            }}
          >
            <HiOutlineDotsVertical className="text-[20px] text-[#7A7A7A] z-40" />
          </div>
          {isDropdownOpen && (
            <DropMenu
              item={item}
              dropdownRef={dropdownRef}
              setNewAssessmentOpenModal={setNewAssessmentOpenModal}
              setSelectedAssessment={setSelectedAssessment}
              setDeleteModal={setDeleteModal}
              setIsDropdownOpen={setIsDropdownOpen}
            />
          )}
        </div>
      </div>
      <AttemptAssessmentModal
        setIsOpenModal={setNewAssessmentOpenModal}
        isOpenModal={newAssessmentOpenModal}
        data={selectedAssessment}
      />
      <DeleteModal
        setIsOpenModal={setDeleteModal}
        isOpenModal={deleteModal}
        setSelected={setSelectedAssessment}
        url={`/api/assessment/delete?assessment_id=${selectedAssessment?.id}`}
      />
    </>
  );
}

export default AssessmentCard;

function DropMenu({
  item,
  dropdownRef,
  setNewAssessmentOpenModal,
  setSelectedAssessment,
  setDeleteModal,
  setIsDropdownOpen,
}) {
  const { userInfo } = useContext(GlobalContext);

  return (
    <div
      className="w-[80px] rounded border border-[#DCDCDC] bg-[#F9F9FF]  absolute right-[2%] py-1 top-[15%]  flex flex-col z-[8888] items-center "
      ref={dropdownRef}
    >
      {userInfo?.user?.is_teacher && (
        <span
          onClick={(e) => {
            e.stopPropagation();
            setNewAssessmentOpenModal(true);
            setSelectedAssessment(item);
            setIsDropdownOpen(false);
          }}
          className=" font-[400] text-[13px] font-roboto text-[#1C1D1E]  hover:bg-[#f09ea073] rounded px-2 cursor-pointer "
        >
          Attempt
        </span>
      )}
      <span
        onClick={(e) => {
          e.stopPropagation();
          setDeleteModal(true);
          setSelectedAssessment(item);
          setIsDropdownOpen(false);
        }}
        className=" text-[13px] font-[400] font-roboto text-[#1C1D1E]  hover:bg-[#f09ea073] rounded px-2 cursor-pointer "
      >
        Remove
      </span>
    </div>
  );
}
