import React from "react";
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from "recharts";

function StudentProgressBarGraph({
  showPercentage,
  CalculatePercentage,
  studentAnalyticsResult,
}) {
  const data = [
    {
      name: "Fall",
      color: "#AD2126",
      Score: showPercentage
        ? CalculatePercentage(
            studentAnalyticsResult?.student_data?.reduce(
              (acc, item) => acc + (item?.total_score || 0),
              0
            ),
            studentAnalyticsResult?.student_data?.reduce(
              (acc, item) => acc + (item?.Fall || 0),
              0
            )
          )
        : studentAnalyticsResult?.student_data?.reduce(
            (acc, item) => acc + (item?.Fall || 0),
            0
          ),
    },
    {
      name: "Winter",
      color: "#FFC121",
      Score: showPercentage
        ? CalculatePercentage(
            studentAnalyticsResult?.student_data?.reduce(
              (acc, item) => acc + (item?.total_score || 0),
              0
            ),
            studentAnalyticsResult?.student_data?.reduce(
              (acc, item) => acc + (item?.Winter || 0),
              0
            )
          )
        : studentAnalyticsResult?.student_data?.reduce(
            (acc, item) => acc + (item?.Winter || 0),
            0
          ),
    },
    {
      name: "Spring",
      color: "#017663",
      Score: showPercentage
        ? CalculatePercentage(
            studentAnalyticsResult?.student_data?.reduce(
              (acc, item) => acc + (item?.total_score || 0),
              0
            ),
            studentAnalyticsResult?.student_data?.reduce(
              (acc, item) => acc + (item?.Spring || 0),
              0
            )
          )
        : studentAnalyticsResult?.student_data?.reduce(
            (acc, item) => acc + (item?.Spring || 0),
            0
          ),
    },
  ];

  const renderCustomLegend = () => {
    return (
      <ul
        style={{
          listStyleType: "none",
          margin: 0,
          padding: 0,
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {data.map((entry, index) => (
          <li key={`item-${index}`} style={{ color: entry.color }}>
            <span
              style={{
                display: "inline-block",
                width: 18,
                height: 10,
                backgroundColor: entry.color,
                marginRight: 5,
              }}
            ></span>
            {entry.name}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <>
      <div className="bg-white rounded-[20px] w-full h-[500px] py-6 px-6 flex justify-center items-center">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={data}
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend content={renderCustomLegend} />
            <Bar dataKey="Score">
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Bar>
            {/* <Bar
              dataKey="Score"
              fill="color"
              activeBar={<Rectangle fill="#AD2126" stroke="#AD2126" />}
            /> */}
            {/* <Bar
              dataKey="Winter"
              fill="#FFC121"
              activeBar={<Rectangle fill="#FFC121" stroke="#FFC121" />}
            />
            <Bar
              dataKey="Spring"
              fill="#017663"
              activeBar={<Rectangle fill="#017663" stroke="#017663" />}
            /> */}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
}

export default StudentProgressBarGraph;
