import axios from "axios";
import { useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import { getAccessToken } from "../storage/storage";

const useGetStudentsOnScroll = () => {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [studentsData, setStudentsData] = useState([]);
  const [studentLoading, setStudentLoading] = useState(true);
  const [studentDataLoading, setStudentDataLoading] = useState(false);
  const [studentMessage, setStudentMessage] = useState(null);
  const [totalPagesStudent, setTotalPagesStudent] = useState(null);
  const [currentPageStudent, setCurrentPageStudent] = useState(1);
  const { setUpdateResponse } = useContext(GlobalContext);

  const GetStudentsOnScroll = async (filter) => {
    await axios
      .get(`${BASE_URL}/api/students${filter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setStudentLoading(false);
        setStudentDataLoading(false);
        if (currentPageStudent === 1) {
          setStudentsData(response.data.results);
        } else {
          setStudentsData((previousData) => [
            ...previousData,
            ...response.data.results,
          ]);
        }

        setTotalPagesStudent(response.data.num_pages);
        setUpdateResponse(false);
        if (response.data.results.length === 0) {
          setStudentMessage("No records found");
        } else {
          setStudentMessage(null);
        }
      })
      .catch((error) => {
        setStudentDataLoading(false);
        if (studentsData?.length === 0) {
          setStudentMessage("Oops! Something went wrong");
        }
      });
  };
  return {
    GetStudentsOnScroll,
    studentLoading,
    studentDataLoading,
    studentsData,
    studentMessage,
    totalPagesStudent,
    currentPageStudent,
    setTotalPagesStudent,
    setCurrentPageStudent,
    setStudentDataLoading,
    setStudentLoading,
    setStudentsData,
  };
};
export default useGetStudentsOnScroll;
