import React, { useEffect, useRef, useState } from "react";
import useGetClasses from "../hooks/useGetClasses";
import { FaMinus, FaPlus } from "react-icons/fa6";
import useGetGradesList from "../hooks/useGetGradesList";
import { ButtonLoader_2 } from "./shared/Loaders";

function ClassGradeDropdown({
  formData,
  setFormData,
  selectedGrade,
  setSelectedGrade
}) {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);
    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  const {
    GetClasses,
    classesLoading,
    classesMessage,
    classessData,
    setClassesData
  } = useGetClasses();

  useEffect(() => {
    GetClasses();
  }, []);

  const {
    gradesList,
    gradesListLoading,
    gradesListMessage,
    getGradesList,
    setGradesList,
    setGradesListMessage,
    setGradesListLoading
  } = useGetGradesList();

  useEffect(() => {
    getGradesList(`?class_id=${selectedClass?.id}`);
  }, [selectedClass]);

  const handleSelectClass = (item) => {
    if (selectedClass?.id !== item.id) {
      setGradesList(null);
      setGradesListMessage(null);
      setGradesListLoading(true);
      setSelectedGrade(null);
      setSelectedClass(item);
      setFormData({
        ...formData,
        Class: item.id
      });
    } else {
      setGradesList(null);
      setGradesListMessage(null);
      setGradesListLoading(true);
      setSelectedGrade(null);
      setSelectedClass(null);
      setFormData({
        ...formData,
        Class: ""
      });
    }
  };

  const handleSelectGrade = (item) => {
    setSelectedGrade(item);
    setFormData({
      ...formData,
      grade: item
    });
  };

  return (
    <>
      <div className="relative" ref={dropdownRef}>
        <button
          id="dropdownSearchButton"
          onClick={toggleDropdown}
          className="bg-white outline-none border border-[#DCDCDC] text-[#1C1D1E] font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center gap-4 hover:border-[#017663] focus-within:border-[#017663]"
          type="button"
        >
          {selectedClass ? selectedClass?.class_name : "Select Class"}
          <svg
            className="w-2.5 h-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          id="dropdownSearch"
          className={`${
            isOpen ? "block" : "hidden"
          } z-10 bg-white rounded-lg shadow w-60 dark:bg-gray-700 absolute top-full right-0`}
        >
          <ul
            className="h-56 py-2 overflow-y-auto"
            aria-labelledby="dropdownSearchButton"
            id="student-dropdown"
          >
            {classessData?.map((item, index) => (
              <li key={index}>
                <div
                  className={`flex justify-between items-center px-4 py-1  hover:bg-[#FFEDEE] cursor-pointer ${
                    selectedClass?.id === item.id
                      ? "bg-[#FFEDEE]"
                      : "bg-transparent"
                  }`}
                  onClick={() => handleSelectClass(item)}
                >
                  <div className="flex items-center gap-2">
                    <div
                      className={`w-[15px] h-[15px] flex justify-center items-center rounded-full border ${
                        selectedClass?.id === item.id
                          ? "border-[#017663]"
                          : "border-[#7A7A7A]"
                      }`}
                    >
                      <div
                        className={`w-[10px] h-[10px] rounded-full ${
                          selectedClass?.id === item.id
                            ? "bg-[#017663]"
                            : "bg-transparent"
                        }`}
                      />
                    </div>
                    <p className="text-[#1C1D1E] text-[16px] font-[400]">
                      {item.class_name}
                    </p>
                  </div>
                  <div className="text-[#5E5E5E]">
                    {selectedClass?.id === item.id ? <FaMinus /> : <FaPlus />}
                  </div>
                </div>
                {selectedClass?.id === item.id && (
                  <ul className="py-2 bg-white shadow-md">
                    {gradesList?.map((gradeItem, gradeIndex) => (
                      <li key={gradeIndex}>
                        <div
                          className={`flex items-center px-10 py-1  hover:bg-[#FFEDEE] cursor-pointer `}
                          onClick={() => handleSelectGrade(gradeItem)}
                        >
                          <div className="flex items-center gap-2">
                            <div
                              className={`w-[15px] h-[15px] flex justify-center items-center rounded-full border ${
                                selectedGrade?.grade_id === gradeItem.grade_id
                                  ? "border-[#017663]"
                                  : "border-[#7A7A7A]"
                              }`}
                            >
                              <div
                                className={`w-[10px] h-[10px] rounded-full ${
                                  selectedGrade?.grade_id === gradeItem.grade_id
                                    ? "bg-[#017663]"
                                    : "bg-transparent"
                                }`}
                              />
                            </div>
                            <p className="text-[#1C1D1E] text-[16px] font-[400]">
                              Grade: {gradeItem.grade}
                            </p>
                          </div>
                        </div>
                      </li>
                    ))}
                    {gradesListLoading && (
                      <div className="flex justify-center items-center w-full h-[70px]">
                        <ButtonLoader_2 />
                      </div>
                    )}
                    {!gradesListLoading && gradesListMessage && (
                      <div className="flex justify-center items-center w-full h-full">
                        <p className="text-gray-600">{gradesListMessage}</p>
                      </div>
                    )}
                  </ul>
                )}
              </li>
            ))}

            {classesLoading && (
              <div className="flex justify-center items-center w-full h-full">
                <ButtonLoader_2 />{" "}
              </div>
            )}
            {!classesLoading && classesMessage && (
              <div className="flex justify-center items-center w-full h-full">
                <p className="text-gray-600">{classesMessage}</p>
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default ClassGradeDropdown;
