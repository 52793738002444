import axios from "axios";
import Cookies from "js-cookie";
import {
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
} from "../storage/storage";

function useRefreshToken() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const refresh = getRefreshToken();

  const RefreshToken = async () => {
    await axios
      .post(`${BASE_URL}/api/token/refresh/`, { refresh: refresh })
      .then((response) => {
        Cookies.set("token", response.data.access, { expires: 7 });
        Cookies.set("refresh", response.data.refresh, { expires: 7 });
        window.location.reload();
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          removeAccessToken();
          removeRefreshToken();
          window.location.href = "/";
        }
      });
  };
  return { RefreshToken };
}
export default useRefreshToken;
