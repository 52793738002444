/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { RiEditFill } from "react-icons/ri";
import { useFormik } from "formik";
import * as Yup from "yup";
import useAddStudent from "../../hooks/useAddStudent";
import useGetClasses from "../../hooks/useGetClasses";
import useGetGradesList from "../../hooks/useGetGradesList";
import useEditStudent from "../../hooks/useEditStudent";
import { ButtonLoader_1 } from "../shared/Loaders";
const validationSchema = Yup.object({
  name: Yup.string()
    .required("Name is required")
    .max(50, "Name must be at most 50 characters"),
  student_id: Yup.string()
    .required("Student ID is required")
    .max(10, "Student ID must be at most 10 characters"),
  date_of_birth: Yup.string().nullable(),
  gender: Yup.string().nullable(),
  admission_date: Yup.string().nullable(),
  Class: Yup.string().required("Class is required"),
  grade: Yup.string().required("Grade is required"),
  address: Yup.string()
    .max(255, "Address must be at most 255 characters")
    .nullable(),
  profile_picture: Yup.string().nullable(),
});

function AddTeacherModal(props) {
  const cancelButtonRef = useRef(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [imageSrc, setImageSrc] = useState(null);
  const { loading, addStudent } = useAddStudent();
  const { loading1, editStudent } = useEditStudent();
  const { classessData, GetClasses } = useGetClasses();
  const { getGradesList, gradesList } = useGetGradesList();

  const handleClose = () => {
    props.setSelectedStudent(null);
    props.setIsOpenModal(false);
    props.setIsEditMode(null);
    resetForm();
    setImageSrc(null);
  };

  const initialValues = {
    name: props.selectedStudent?.name,
    student_id: props.selectedStudent?.student_id,
    date_of_birth: props.selectedStudent?.date_of_birth,
    gender: props.selectedStudent?.gender,
    admission_date: props.selectedStudent?.admission_date,
    Class: props.selectedStudent?.class_id,
    grade: props.selectedStudent?.grade_id,
    address: props.selectedStudent?.address,
    profile_picture: props.selectedStudent?.profile,
  };
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values, action) => {
      if (props.isEditMode) {
        if (imageSrc) {
          await editStudent(
            {
              id: props.selectedStudent?.id,
              name: values?.name,
              student_id: values?.student_id,
              date_of_birth: values?.date_of_birth,
              gender: values?.gender,
              admission_date: values?.admission_date,
              Class: values?.Class,
              grade: values?.grade,
              address: values?.address,
              profile_picture: values?.profile_picture,
            },
            handleClose
          );
        } else {
          await editStudent(
            {
              id: props.selectedStudent?.id,
              name: values?.name,
              student_id: values?.student_id,
              date_of_birth: values?.date_of_birth,
              gender: values?.gender,
              admission_date: values?.admission_date,
              Class: values?.Class,
              grade: values?.grade,
              address: values?.address,
            },
            handleClose
          );
        }
      } else {
        await addStudent(values, handleClose);
      }
    },
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFieldValue("profile_picture", file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    GetClasses("");
  }, [props.selectedStudent]);

  useEffect(() => {
    if (values.Class) {
      getGradesList(`?class_id=${values.Class}`);
    }
  }, [values.Class]);

  return (
    <Transition.Root show={props.isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 xs:p-1 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#fff] text-left shadow-xl transition-all 2xl:w-[1100px] lg:w-[900px] xs:w-full h-auto xs:mx-2 md:p-6 xs:p-3">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={handleClose} />
                </div>
                <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[600] lg:text-[32px]  font-roboto">
                  {props.isEditMode ? "Edit Student" : "Add Student"}
                </h1>
                <form className="w-full flex ">
                  <div className="w-[20%]  flex  flex-col ">
                    <div className="large-circle relative my-4">
                      {imageSrc ? (
                        <img
                          className="rounded-full w-full h-full"
                          src={imageSrc}
                          style={{ aspectRatio: "1/1", objectFit: "cover" }}
                          alt=""
                        />
                      ) : (
                        <>
                          {props.selectedStudent?.profile ? (
                            <img
                              className="rounded-full w-full h-full"
                              src={`${BASE_URL}${props.selectedStudent?.profile}`}
                              style={{ aspectRatio: "1/1", objectFit: "cover" }}
                              alt=""
                            />
                          ) : (
                            <img
                              className="rounded-full w-full h-full"
                              src="/avatar_dummy.png"
                              style={{ aspectRatio: "1/1", objectFit: "cover" }}
                              alt=""
                            />
                          )}
                        </>
                      )}

                      <div className="flex items-center justify-center bg-white rounded-full w-[30px] h-[30px] border-[2px] border-gray-200 absolute left-[42px] bottom-[-20px] cursor-pointer ">
                        <label
                          htmlFor="profile_picture"
                          className="cursor-pointer"
                        >
                          <RiEditFill className="edit" />
                        </label>
                        <input
                          type="file"
                          id="profile_picture"
                          name="profile_picture"
                          accept=".jpg, .png, .jpeg, .jfif"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                    {errors.profile_picture && touched.profile_picture && (
                      <p className="text-red-700 text-xs mt-1">
                        {errors.profile_picture}
                      </p>
                    )}
                  </div>
                  <div className=" w-[3%] border-l border-gray-200"></div>
                  <div className="w-[77%]">
                    <div className="w-full flex flex-col ">
                      <div className="w-full flex gap-2 ">
                        <div className="w-full">
                          <label
                            htmlFor=""
                            className="2xl:font-[400] 2xl:text-[20px] lg:font-[400] lg:text-[18px]  font-roboto"
                          >
                            Student name*
                          </label>
                          <input
                            placeholder="Enter student name..."
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            className="border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
                          />
                          {errors.name && touched.name && (
                            <p className="text-red-700 text-xs mt-1">
                              {errors.name}
                            </p>
                          )}
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor=""
                            className="2xl:font-[400] 2xl:text-[20px] lg:font-[400] lg:text-[18px]  font-roboto"
                          >
                            Student ID*
                          </label>
                          <input
                            placeholder="Enter ID...  "
                            type="text"
                            name="student_id"
                            value={values.student_id}
                            onChange={handleChange}
                            className="border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
                          />
                          {errors.student_id && touched.student_id && (
                            <p className="text-red-700 text-xs mt-1">
                              {errors.student_id}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="w-full flex gap-2 mt-2">
                        <div className="w-full">
                          <label
                            htmlFor=""
                            className="2xl:font-[400] 2xl:text-[20px] lg:font-[400] lg:text-[18px]  font-roboto"
                          >
                            Date of birth
                          </label>
                          <input
                            placeholder="Enter teacher name..."
                            type="date"
                            name="date_of_birth"
                            value={values.date_of_birth}
                            onChange={handleChange}
                            className="border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
                          />
                          {errors.date_of_birth && touched.date_of_birth && (
                            <p className="text-red-700 text-xs mt-1">
                              {errors.date_of_birth}
                            </p>
                          )}
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor=""
                            className="2xl:font-[400] 2xl:text-[20px] lg:font-[400] lg:text-[18px]  font-roboto"
                          >
                            Gender
                          </label>
                          <div className="w-[100%] ">
                            <select
                              className="border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
                              id="demo-simple-select"
                              name="gender"
                              value={values.gender}
                              onChange={handleChange}
                            >
                              <option disabled selected>
                                Select Gender
                              </option>
                              <option value={"M"}>Male</option>
                              <option value={"F"}>Female</option>
                            </select>
                            {errors.gender && touched.gender && (
                              <p className="text-red-700 text-xs mt-1">
                                {errors.gender}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>{" "}
                      <div className="w-full flex gap-2 mt-2">
                        <div className="w-full">
                          <label
                            htmlFor=""
                            className="2xl:font-[400] 2xl:text-[20px] lg:font-[400] lg:text-[18px]  font-roboto"
                          >
                            Admission date
                          </label>
                          <input
                            type="date"
                            name="admission_date"
                            value={values.admission_date}
                            onChange={handleChange}
                            className="border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
                          />
                          {errors.admission_date && touched.admission_date && (
                            <p className="text-red-700 text-xs mt-1">
                              {errors.admission_date}
                            </p>
                          )}
                        </div>
                      </div>{" "}
                      <div className="w-full flex gap-2 mt-2">
                        <div className="w-full">
                          <label
                            htmlFor=""
                            className="2xl:font-[400] 2xl:text-[20px] lg:font-[400] lg:text-[18px]  font-roboto"
                          >
                            Class*
                          </label>
                          <div className="w-[100%] ">
                            <div className="custom-select w-full h-[50px]">
                              <select
                                id="demo-simple-select"
                                name="Class"
                                value={values.Class}
                                onChange={handleChange}
                                style={{
                                  borderColor: "#9D9D9D",
                                  borderRadius: "4px",
                                }}
                              >
                                <option disabled selected>
                                  Select Class
                                </option>
                                {classessData?.map((data, index) => (
                                  <option key={index} value={data?.id}>
                                    {data?.class_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {errors.Class && touched.Class && (
                              <p className="text-red-700 text-xs mt-1">
                                {errors.Class}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="w-full">
                          <label
                            htmlFor=""
                            className="2xl:font-[400] 2xl:text-[20px] lg:font-[400] lg:text-[18px]  font-roboto"
                          >
                            Grade*
                          </label>
                          <div className="w-[100%] ">
                            <div className="custom-select w-full h-[50px]">
                              <select
                                id="demo-simple-select"
                                name="grade"
                                value={values.grade}
                                onChange={handleChange}
                                style={{
                                  borderColor: "#9D9D9D",
                                  borderRadius: "4px",
                                }}
                              >
                                <option disabled selected>
                                  Select Grade
                                </option>
                                {gradesList?.map((data, index) => (
                                  <option key={index} value={data?.grade_id}>
                                    Grade: {data?.grade}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {errors.grade && touched.grade && (
                              <p className="text-red-700 text-xs mt-1">
                                {errors.grade}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="w-full flex gap-2 mt-2">
                        <div className="w-full">
                          <label
                            htmlFor=""
                            className="2xl:font-[400] 2xl:text-[20px] lg:font-[400] lg:text-[18px]  font-roboto"
                          >
                            Address
                          </label>
                          <input
                            type="text"
                            placeholder="Enter address..."
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            className="border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
                          />
                          {errors.address && touched.address && (
                            <p className="text-red-700 text-xs mt-1">
                              {errors.address}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-end w-full gap-2 mt-5 text-white">
                      <button
                        onClick={handleSubmit}
                        className="bg-[#1C1D1E] font-[600] text-white w-[120px] h-[45px] rounded-[10px] flex justify-center items-center  cursor-pointer"
                        disabled={loading || loading1}
                      >
                        {loading || loading1 ? <ButtonLoader_1 /> : "Submit"}
                      </button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
export default AddTeacherModal;
