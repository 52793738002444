import axios from "axios";
import { useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import useSessionStorage from "./useSessionStorage";
import { getAccessToken } from "../storage/storage";

const useGetStudents = () => {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [studentsData, setStudentsData] = useState(null);
  const [studentLoading, setStudentLoading] = useState(true);
  const [studentMessage, setStudentMessage] = useState(null);
  const [totalPagesStudent, setTotalPagesStudent] = useState();
  const [currentPageStudent, setCurrentPageStudent] = useSessionStorage(
    "currentPageStudent",
    1
  );
  const { setUpdateResponse } = useContext(GlobalContext);

  const GetStudents = async (filter) => {
    await axios
      .get(`${BASE_URL}/api/students${filter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setStudentLoading(false);
        setStudentsData(response.data);
        setTotalPagesStudent(response.data.num_pages);
        setUpdateResponse(false);
        if (
          response?.data?.results?.length === 0 ||
          response?.data?.length === 0
        ) {
          setStudentMessage("No records found");
        } else {
          setStudentMessage(null);
        }
      })
      .catch((error) => {
        setStudentLoading(false);
        setStudentMessage("Oops! Something went wrong");
      });
  };
  return {
    studentLoading,
    GetStudents,
    studentsData,
    studentMessage,
    totalPagesStudent,
    currentPageStudent,
    setTotalPagesStudent,
    setCurrentPageStudent,
  };
};
export default useGetStudents;
