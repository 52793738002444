import axios from "axios";
import { useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import { getAccessToken } from "../storage/storage";

const useGetGradesList = () => {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [gradesList, setGradesList] = useState(null);
  const [gradesListLoading, setGradesListLoading] = useState(true);
  const [gradesListMessage, setGradesListMessage] = useState(null);
  const { setUpdateResponse } = useContext(GlobalContext);

  const getGradesList = async (filter) => {
    await axios
      .get(`${BASE_URL}/api/grades${filter}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        setGradesListLoading(false);
        setGradesList(response.data);
        setUpdateResponse(false);
        if (response.data.length === 0) {
          setGradesListMessage("No grades found");
        } else {
          setGradesListMessage(null);
        }
      })
      .catch((error) => {
        setGradesListLoading(false);
        setGradesListMessage("Oops! Something went wrong");
      });
  };
  return {
    getGradesList,
    setGradesList,
    gradesListLoading,
    setGradesListLoading,
    gradesList,
    gradesListMessage,
    setGradesListMessage
  };
};
export default useGetGradesList;
