/* eslint-disable react/jsx-pascal-case */
import React, { useState } from "react";
import logo from "../../assets/logo.png";
import FloatingInput from "../../components/FloatingInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import login from "../../assets/login.png";
import { ButtonLoader_1 } from "../../components/shared/Loaders";
import Swal from "sweetalert2";
import axios from "axios";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required"),
});

function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const initialValues = {
    email: "",
  };

  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      await axios
        .post(`${BASE_URL}/api/password/reset-request/`, {
          email: values.email,
        })
        .then((response) => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            text: "Password reset link is send to your email address",
            customClass: {
              confirmButton: "custom-green-button",
            },
          });
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire({
            icon: "error",
            text: error?.response?.data?.error || "Oops! Something went wrong",
            customClass: {
              confirmButton: "custom-red-button",
            },
          });
        });
    },
  });
  return (
    <>
      <div className="flex bg-backgroundColor">
        {/* First */}
        <div className="w-[55%] relative">
          <div
            className="w-[90%] h-[100vh] flex justify-center flex-col p-2 relative border-r-4 border-white"
            style={{ boxShadow: "2px 4px 8px 0 #7373734D" }}
          ></div>
          <img
            src={login}
            alt=""
            className="absolute bottom-0 right-0 h-[100vh]"
            style={{ backgroundSize: "100% 100%", objectFit: "contain" }}
          />
        </div>
        {/* Second */}
        <form
          onSubmit={handleSubmit}
          className="w-[45%] flex justify-center bg-backgroundColor"
        >
          <div className="w-full h-full flex flex-col justify-center items-center gap-4 2xl:px-32 lg:px-20">
            <img src={logo} alt="" className="" />
            <div className="flex flex-col items-center">
              <h1 className="font-[700] text-[30px] text-[#1C1D1E] font-roboto">
                Forgot Password
              </h1>
              <p className="font-[400] text-[15px] text-center text-[#7A7A7A] font-roboto">
                Forgot your password? No worries, then let’s submit password
                reset. It will be send to your email.
              </p>
            </div>
            <div className="w-full flex flex-col lg:mt-4 xs:mt-4">
              <FloatingInput
                type="text"
                label="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
              {errors.email && touched.email && (
                <p className="text-red-700 text-xs mt-1 font-roboto">
                  {errors.email}
                </p>
              )}
            </div>
            <button
              type="submit"
              className="w-full bg-black text-white h-[45px] flex justify-center items-center rounded mt-4 font-[700] text-[18px] font-roboto"
              disabled={loading}
            >
              {loading ? <ButtonLoader_1 /> : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default ForgotPassword;
