import { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import GlobalContext from "../context/GlobalContext";
import { getAccessToken } from "../storage/storage";

function useAddAssessment() {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(false);
  const { setUpdateResponse } = useContext(GlobalContext);

  const addAssessment = async (payload, handleClose) => {
    setLoading(true);
    await axios
      .post(`${BASE_URL}/api/create-assessment-data/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      .then((response) => {
        setLoading(false);
        setUpdateResponse(true);
        handleClose();
        Swal.fire({
          icon: "success",
          text: "Assessment successfully created!",
          customClass: {
            confirmButton: "custom-green-button",
          },
        });
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: "error",
          text: error?.response?.data?.error || "Oops! Something went wrong",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading, addAssessment };
}

export default useAddAssessment;
