// import React from "react";
// import Sidebar from "../shared/Sidebar";
// import Navbar from "./Navbar";

// const DashboardLayout = ({ children }) => {
//   return (
//     <div className="w-full h-[100vh] flex bg-backgroundColor">
//       <div className="w-[20%] h-full">
//         <Sidebar />
//       </div>
//       <div className="overflow-y-auto w-[80%] h-full">
//         <Navbar />
//         <main>{children}</main>
//       </div>
//     </div>
//   );
// };

// export default DashboardLayout;

import React, { useEffect, useRef, useState } from "react";
import Sidebar from "../shared/Sidebar";
import Navbar from "./Navbar";

const DashboardLayout = ({ children }) => {
  const sidebarRef = useRef(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSidebarOpen]);

  return (
    <div className="w-full h-[100vh] flex bg-backgroundColor">
      {/* Sidebar */}
      <div
        ref={sidebarRef}
        className={`h-full bg-gray-800 transition-transform duration-300 ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0 lg:w-[20%] md:w-[30%] sm:w-[50%] xs:w-[65%] fixed lg:static z-50`}
      >
        <Sidebar toggleSidebar={toggleSidebar} />
      </div>

      {/* Main Content */}
      <div className="overflow-y-auto h-full flex-1 lg:w-[80%] w-full transition-all duration-300">
        <Navbar toggleSidebar={toggleSidebar} />
        <main>{children}</main>
      </div>
    </div>
  );
};

export default DashboardLayout;
