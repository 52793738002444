/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../pages/auth/Login";
import ForgotPassword from "../pages/auth/ForgotPassword.jsx";
import ResetPassword from "../pages/auth/ResetPassword.jsx";
import DashboardPage from "../pages/DashboardPage.jsx";
import TeacherPage from "../pages/TeacherPage";
import StudentPage from "../pages/StudentPage";
import TeachersDetailPage from "../pages/TeachersDetailPage";
import StudentsDetailPage from "../pages/StudentsDetailPage";
import useCurrentUser from "../hooks/useCurrentUser";
import ClassesPage from "../pages/ClassesPage";
import AssesmentsPage from "../pages/AssessmentsPage";
import AssessmentsDetailPage from "../pages/AssessmentsDetailPage";
import ClassAnalyticsPage from "../pages/ClassAnalyticsPage.jsx";
import StudentAnalyticsPage from "../pages/StudentAnalyticsPage.jsx";
import GradesPage from "../pages/GradesPage.jsx";
import GlobalContext from "../context/GlobalContext.jsx";
import { getAccessToken } from "../storage/storage.jsx";
import { PageLoader } from "../components/shared/Loaders.jsx";
import Settings from "../pages/Settings.jsx";

const Routing = () => {
  const { loading, getCurrentUser } = useCurrentUser();
  const { userInfo, updateProfile } = useContext(GlobalContext);
  const token = getAccessToken();
  useEffect(() => {
    if (token) {
      getCurrentUser(token);
    }
  }, [token, updateProfile]);

  return (
    <>
      {loading && <PageLoader />}
      <Router>
        <Routes>
          {token ? (
            <>
              <Route path="/" element={<DashboardPage />} />
              {userInfo?.is_superuser === true && (
                <>
                  <Route path="/teachers" element={<TeacherPage />} />
                  <Route
                    path="/teachers/:id"
                    element={<TeachersDetailPage />}
                  />
                </>
              )}
              <Route path="/students" element={<StudentPage />} />
              <Route path="/settings" element={<Settings />} />

              <Route path="/students/:id" element={<StudentsDetailPage />} />
              <Route
                path="/students/analytics/:id"
                element={<StudentAnalyticsPage />}
              />
              <Route path="/classes" element={<ClassesPage />} />
              <Route
                path="/classes/grades/:name/:id"
                element={<GradesPage />}
              />
              <Route path="/assessments" element={<AssesmentsPage />} />
              <Route
                path="/assessments/:id"
                element={<AssessmentsDetailPage />}
              />
              <Route path="/class-analytics" element={<ClassAnalyticsPage />} />
              <Route path="*" element={<DashboardPage />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/reset-password/:token"
                element={<ResetPassword />}
              />
              <Route path="*" element={<Login />} />
            </>
          )}
        </Routes>
      </Router>
    </>
  );
};

export default Routing;
