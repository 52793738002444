import React, { useEffect, useState } from "react";
import DashboardLayout from "../components/shared/DashboardLayout";
import assessment_1 from "../assets/assessment_1.png";
import assessment_2 from "../assets/assessment_2.png";
import useGetAssessmentAnalytics from "../hooks/useGetAssessmentAnalytics";
import { useNavigate, useParams } from "react-router-dom";
import ShowMessage from "../components/shared/ShowMessage";
import { BsTextareaT } from "react-icons/bs";
import { MdOutlineDateRange, MdOutlineGrade } from "react-icons/md";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { LuSchool2 } from "react-icons/lu";
import cap from "../assets/cap.png";
import { CardSkelton_10 } from "../components/shared/Skeltons";
import { skelton_data } from "../staticData/SkeltonData";
import axios from "axios";
import { ButtonLoader_2 } from "../components/shared/Loaders";
import { getAccessToken } from "../storage/storage";

function AssessmentsDetailPage() {
  const { id } = useParams();
  const [selectedFilter, setSelectedFilter] = useState("all");
  const {
    assessmentAnalyticsData,
    assessmentAnalyticsLoading,
    getAssessmentAnalytics,
    assessmentAnalyticsMessage,
  } = useGetAssessmentAnalytics();

  useEffect(() => {
    getAssessmentAnalytics(`${id}&filters=${selectedFilter}`);
  }, [id, selectedFilter]);

  return (
    <DashboardLayout>
      <div className="bg-backgroundColor px-4 py-2 flex flex-col w-full h-auto">
        <Assessment
          assessmentAnalyticsData={assessmentAnalyticsData}
          assessmentAnalyticsLoading={assessmentAnalyticsLoading}
          assessmentAnalyticsMessage={assessmentAnalyticsMessage}
        />
        <StudentsTable
          assessmentAnalyticsData={assessmentAnalyticsData}
          assessmentAnalyticsLoading={assessmentAnalyticsLoading}
          assessmentAnalyticsMessage={assessmentAnalyticsMessage}
          setSelectedFilter={setSelectedFilter}
        />
        <AssessmentResult />
      </div>
    </DashboardLayout>
  );
}

export default AssessmentsDetailPage;

function Assessment(props) {
  const { assessmentAnalyticsData, assessmentAnalyticsLoading } = props;
  const percentageCompleted =
    (assessmentAnalyticsData?.total_students_completed /
      assessmentAnalyticsData?.total_students_assigned) *
    100;

  const finalPercentage = Math.round(percentageCompleted);

  return (
    <>
      <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[500] lg:text-[30px] font-roboto text-[#1C1D1E] mb-1">
        Assessment
      </h1>
      <div
        className="w-full min-h-[300px] flex items-center justify-between gap-5 px-[50px] py-[20px] rounded-[20px]  border border-[#7a7a7a6c]"
        style={{ background: "rgba(220, 220, 220, 0.40)" }}
      >
        <div className="w-[60%]">
          {/* Title */}
          <div className="flex flex-col mb-5">
            <h1 className="2xl:font-[600] 2xl:text-[20px] lg:font-[500] lg:text-[15px]  font-roboto text-[#1C1D1E]">
              {assessmentAnalyticsData?.assessment_name}
            </h1>
          </div>
          {/* Count */}
          <div className="flex items-center justify-between mb-5">
            <div className="flex flex-col items-center">
              <p className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px]  font-roboto text-[#7A7A7A]">
                Total Assigned
              </p>
              <p className="2xl:font-[600] 2xl:text-[20px] lg:font-[500] lg:text-[15px]  font-roboto text-[#1C1D1E]">
                {assessmentAnalyticsData?.total_students_assigned || "0"}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <p className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px]  font-roboto text-[#7A7A7A]">
                Total Attempted
              </p>
              <p className="2xl:font-[600] 2xl:text-[20px] lg:font-[500] lg:text-[15px]  font-roboto text-[#1C1D1E]">
                {assessmentAnalyticsData?.total_students_completed || "0"}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <p className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px]  font-roboto text-[#7A7A7A]">
                Total Pending
              </p>
              <p className="2xl:font-[600] 2xl:text-[20px] lg:font-[500] lg:text-[15px]  font-roboto text-[#1C1D1E]">
                {assessmentAnalyticsData?.total_students_pending || "0"}
              </p>
            </div>
          </div>
          {/* Progress */}
          <div className="flex flex-col">
            <div class="w-full bg-[#dcdcdc9d] rounded-full h-2.5 mb-[5px]">
              <div
                className={`bg-[#AD2126] h-2.5 rounded-full`}
                style={{ width: `${finalPercentage || 0}%` }}
              />
            </div>
            <div className="flex justify-between items-center">
              <p className="font-[400] 2xl:text-[15px] lg:text-[15px]  font-roboto text-[#2D2D2D]">
                {finalPercentage || "0"}%
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-end justify-center w-[30%]">
          <img
            src={assessment_1}
            alt=""
            className="w-auto h-auto"
            style={{ backgroundSize: "100% 100%", objectFit: "contain" }}
          />
          <img
            src={assessment_2}
            alt=""
            className="w-auto h-auto"
            style={{ backgroundSize: "100% 100%", objectFit: "contain" }}
          />
        </div>
      </div>
    </>
  );
}

function StudentsTable(props) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const Navigate = useNavigate();
  const {
    assessmentAnalyticsData,
    assessmentAnalyticsLoading,
    assessmentAnalyticsMessage,
    setSelectedFilter,
  } = props;

  const NavigateToStudentsDetailPage = (studentId) => {
    Navigate(`/students/${studentId}`);
  };
  return (
    <>
      <div className="w-full h-auto relative flex flex-col gap-5 py-[20px] rounded-[20px] bg-[#ffffff] mt-6">
        <div className="w-full flex justify-end pe-[20px]">
          <div className="custom-select w-[150px] h-[50px]">
            <select onChange={(e) => setSelectedFilter(e.target.value)}>
              <option selected value="all">
                All
              </option>
              <option value="completed">Completed</option>
              <option value="pending">Pending</option>
            </select>
          </div>
        </div>
        <div className="h-[400px] overflow-y-scroll">
          <table className="w-full text-sm text-left rtl:text-right">
            <thead className="sticky z-[1] top-0 bg-gray-200 text-[#1C1D1E] font-semibold text-[16px] text-center">
              <tr className="">
                {["Student name", "Student ID", "Test Status", "Result"].map(
                  (item, index) => (
                    <th scope="col" className="py-5 w-[25%]" key={index}>
                      {item}
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {assessmentAnalyticsLoading ? (
                <>
                  {skelton_data.slice(0, 6).map((_, index) => (
                    <CardSkelton_10 key={index} />
                  ))}
                </>
              ) : (
                <>
                  {assessmentAnalyticsData?.student_completed?.map(
                    (item, index) => (
                      <tr
                        key={index}
                        className="bg-white text-center text-[16px] border border-[#EBEDF4] cursor-pointer hover:bg-[#ffedee]"
                        onClick={() =>
                          NavigateToStudentsDetailPage(item.student_id)
                        }
                      >
                        <td className="py-3 text-[#7A7A7A] flex items-center justify-center">
                          <div className="flex items-center gap-2 w-[50%]">
                            {item.student__profile_picture ? (
                              <img
                                src={`${BASE_URL}/media/${item.student__profile_picture}`}
                                alt="student"
                                className="w-[30px] h-[30px] rounded-full"
                              />
                            ) : (
                              <img
                                src={"/avatar_dummy.png"}
                                alt="student"
                                className="w-[30px] h-[30px] rounded-full"
                              />
                            )}
                            <p>{item.student__name}</p>
                          </div>
                        </td>
                        <td className="py-3 text-[#7A7A7A]">
                          {item.student__student_id}
                        </td>
                        <td className="py-3 text-[#7A7A7A] flex justify-center">
                          <div
                            className={`rounded-[6px] w-[100px] px-2 py-2 ${
                              item.assessment__assessment_status__status ===
                                "completed" && "bg-[#C9DCF7] text-[#017663]"
                            }  ${
                              item.assessment__assessment_status__status ===
                                "pending" && "bg-[#EBEBF1] text-[#7A7A7A]"
                            } ${
                              !item.assessment__assessment_status__status &&
                              "bg-[#EBEBF1] text-[#7A7A7A]"
                            }`}
                          >
                            {item.assessment__assessment_status__status
                              ? item.assessment__assessment_status__status
                              : "pending"}
                          </div>
                        </td>
                        <td className="py-3 text-[#7A7A7A]">
                          {item.total_correct ? `${item.total_correct}%` : "-"}
                        </td>
                      </tr>
                    )
                  )}
                </>
              )}
            </tbody>
          </table>
          {!assessmentAnalyticsLoading && assessmentAnalyticsMessage && (
            <div className="flex justify-center items-center w-full h-[340px]">
              <ShowMessage message={assessmentAnalyticsMessage} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function AssessmentResult() {
  const { id } = useParams();
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [assessmentResult, setAssessmentResult] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  // get assessment Result
  const GetAssessmentResult = async () => {
    await axios
      .get(`${BASE_URL}/api/student-catogery?assessment_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAssessmentResult(response.data);
        setLoading(false);
        if (!response.data) {
          setMessage("Record not found");
        }
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Oops! Something went wrong");
      });
  };
  useEffect(() => {
    GetAssessmentResult();
  }, [id]);
  return (
    <>
      <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[500] lg:text-[30px] font-roboto text-[#1C1D1E] mb-1 mt-8">
        Assessment Result
      </h1>
      <div className="w-full h-auto relative flex flex-col gap-5 p-[20px] rounded-[20px] bg-[#ffffff] mt-2">
        {/* Top Bar */}
        <ModalBar assessmentResult={assessmentResult} />
        <PerformanceLevels assessmentResult={assessmentResult} />
        <Results />
      </div>
    </>
  );
}

function ModalBar({ assessmentResult }) {
  return (
    <>
      <div className="w-[100%] py-4 px-10 min-h-[110px] rounded-[6px] mt-2 flex items-center bg-[#F0F0F5]">
        <div className="flex gap-2 w-[30%]">
          <BsTextareaT className="text-[20px] mt-1" />
          <div className="w-full">
            <h1 className="2xl:font-[700] lg:font-[500] 2xl:text-[20px] lg:text-[18px] font-roboto">
              Title
            </h1>
            <div className="w-full flex h-[35px]">
              <p>{assessmentResult?.assessment_name}</p>
            </div>
          </div>
        </div>

        <div className="flex gap-2 w-[30%]">
          <MdOutlineDateRange className="text-[20px] mt-1" />
          <div className="w-full">
            <h1 className="2xl:font-[700] lg:font-[500] 2xl:text-[20px] lg:text-[18px] font-roboto">
              Due Date
            </h1>
            <div className="w-full flex h-[35px]">
              <p>{assessmentResult?.due_date}</p>
            </div>
          </div>
        </div>

        <div className="flex gap-2 w-[20%]">
          <LuSchool2 className="text-[20px] mt-1" />
          <div className="w-full">
            <h1 className="2xl:font-[700] lg:font-[500] 2xl:text-[20px] lg:text-[18px] font-roboto">
              Class
            </h1>
            <div className="w-full flex h-[35px]">
              <p>{assessmentResult?.Class}</p>
            </div>
          </div>
        </div>

        <div className="flex gap-2 w-[20%]">
          <MdOutlineGrade className="text-[20px] mt-1" />
          <div className="w-full">
            <h1 className="2xl:font-[700] lg:font-[500] 2xl:text-[20px] lg:text-[18px] font-roboto">
              Grade
            </h1>
            <div className="w-full flex h-[35px]">
              <p>{assessmentResult?.grade}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function PerformanceLevels({ assessmentResult }) {
  const { id } = useParams();
  const Navigate = useNavigate();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [selectedFilter, setSelectedFilter] = useState("completed");
  const {
    assessmentAnalyticsData,
    assessmentAnalyticsLoading,
    getAssessmentAnalytics,
    assessmentAnalyticsMessage,
  } = useGetAssessmentAnalytics();

  useEffect(() => {
    getAssessmentAnalytics(`${id}&filters=${selectedFilter}`);
  }, [id, selectedFilter]);

  const NavigateToStudentsDetailPage = (studentId) => {
    Navigate(`/students/${studentId}`);
  };
  return (
    <>
      <div className="flex items-center justify-between">
        <h1 className="text-[#7A7A7A] text-[26px] font-[500] font-roboto">
          Performance levels
        </h1>
        <div className="custom-select w-[160px] h-[50px]">
          <select onChange={(e) => setSelectedFilter(e.target.value)}>
            <option value="completed" selected>
              All
            </option>
            <option value="completed">Highest Grade</option>
            <option value="completed">Lowest Grade</option>
          </select>
        </div>
      </div>
      <div className="flex xl:flex-row xs:flex-col gap-6 xl:h-[350px] xl:max-h-[350px] xs:h-auto">
        {/* First */}
        <div className="xl:w-[60%] xs:w-full xl:h-full xs:h-[350px] px-6 py-4 bg-[#F0F0F5] border border-[#ECECEC] rounded-[20px]">
          <h1 className="text-[22px] text-[#1C1D1E] font-[500]">
            {assessmentResult?.assessment_name}
          </h1>
          <div className="flex justify-start items-center gap-10 w-full h-full">
            <Circle assessmentResult={assessmentResult} />
            <div className="flex flex-col w-[80%] gap-3">
              <div className="flex gap-6 items-center justify-between border-b border-b-[#1C1D1E] py-1">
                <div className="w-[40%] flex items-center gap-3">
                  <div className="w-4 h-4 bg-[#93C8C0] rounded-full"></div>
                  <h1 className="text-[20px] text-[#1C1D1E] font-[600]">
                    Benchmark
                  </h1>
                </div>
                <div className="w-[60%] flex items-center justify-end gap-1">
                  <div className="w-[90%] h-5 flex justify-end">
                    <div
                      className={`bg-[#93C8C0] h-5 text-[12px] text-gray-500 flex justify-end items-center pr-2`}
                      style={{
                        width: `${assessmentResult?.benchmark_percentage}%`,
                      }}
                    >
                      {assessmentResult?.benchmark_percentage}%
                    </div>
                  </div>
                  <p className="w-[10%] text-[#7A7A7A] text-[14px] font-[400] text-end">
                    {assessmentResult?.benchmark}
                  </p>
                </div>
              </div>
              <div className="flex gap-6 items-center justify-between border-b border-b-[#1C1D1E] py-1">
                <div className="w-[40%] flex items-center gap-3">
                  <div className="w-4 h-4 bg-[#FFE398] rounded-full"></div>
                  <h1 className="text-[20px] text-[#1C1D1E] font-[600]">
                    Strategic
                  </h1>
                </div>
                <div className="w-[60%] flex items-center justify-end gap-1">
                  <div className="w-[90%] h-5 flex justify-end">
                    <div
                      className={`bg-[#FFE398] h-5 text-[12px] text-gray-500 flex justify-end items-center pr-2`}
                      style={{
                        width: `${assessmentResult?.strategic_percentage}%`,
                      }}
                    >
                      {assessmentResult?.strategic_percentage}%
                    </div>
                  </div>
                  <p className="w-[10%] text-[#7A7A7A] text-[14px] font-[400] text-end">
                    {assessmentResult?.strategic}
                  </p>
                </div>
              </div>
              <div className="flex gap-6 items-center justify-between border-b border-b-[#1C1D1E] py-1">
                <div className="w-[40%] flex items-center gap-3">
                  <div className="w-4 h-4 bg-[#F77B7F] rounded-full"></div>
                  <h1 className="text-[20px] text-[#1C1D1E] font-[600]">
                    Intensive
                  </h1>
                </div>
                <div className="w-[60%] flex items-center justify-end gap-1">
                  <div className="w-[90%] h-5 flex justify-end">
                    <div
                      className={`bg-[#F77B7F] h-5 text-[12px] text-gray-500 flex justify-end items-center pr-2`}
                      style={{
                        width: `${assessmentResult?.intensive_percentage}%`,
                      }}
                    >
                      {assessmentResult?.intensive_percentage}%
                    </div>
                  </div>
                  <p className="w-[10%] text-[#7A7A7A] text-[14px] font-[400] text-end">
                    {assessmentResult?.intensive}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Second */}
        <div className="xl:w-[40%] xs:w-full xl:h-full xs:max-h-[350px] bg-white border border-[#ECECEC] rounded-[20px] overflow-y-scroll overflow-x-scroll">
          <table className="w-full text-sm text-left rtl:text-right">
            <thead className="sticky z-[1] top-0 text-[#1C1D1E] bg-[#F0F0F5] rounded-[20px] font-semibold text-[16px]">
              <tr className="">
                {["Student name", "Student ID", "Test Status", "Result"].map(
                  (item, index) => (
                    <th scope="col" className="py-5 px-3" key={index}>
                      <div className="w-[150px]">{item}</div>
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              {assessmentAnalyticsLoading ? (
                <>
                  {skelton_data.slice(0, 6).map((_, index) => (
                    <CardSkelton_10 key={index} />
                  ))}
                </>
              ) : (
                <>
                  {assessmentAnalyticsData?.student_completed?.map(
                    (item, index) => (
                      <tr
                        key={index}
                        className="bg-white text-[16px] border border-[#EBEDF4] cursor-pointer hover:bg-[#ffedee]"
                        onClick={() =>
                          NavigateToStudentsDetailPage(item.student_id)
                        }
                      >
                        <td className="py-3 px-3 w-[150px] text-[#7A7A7A] flex items-center">
                          <div className="flex items-center gap-2 w-[50%]">
                            {item.student__profile_picture ? (
                              <img
                                src={`${BASE_URL}/media/${item.student__profile_picture}`}
                                alt="student"
                                className="w-[30px] h-[30px] rounded-full"
                              />
                            ) : (
                              <img
                                src={"/avatar_dummy.png"}
                                alt="student"
                                className="w-[30px] h-[30px] rounded-full"
                              />
                            )}
                            <p>{item.student__name}</p>
                          </div>
                        </td>
                        <td className="py-3 px-3 text-[#7A7A7A] w-[150px]">
                          {item.student__student_id}
                        </td>
                        <td className="py-3 px-3 text-[#7A7A7A] w-[150px]">
                          <div
                            className={`rounded-[6px] w-[100px] text-center px-2 py-1 ${
                              item.assessment__assessment_status__status ===
                                "completed" && "bg-[#C9DCF7] text-[#017663]"
                            }  ${
                              item.assessment__assessment_status__status ===
                                "pending" && "bg-[#EBEBF1] text-[#7A7A7A]"
                            } ${
                              !item.assessment__assessment_status__status &&
                              "bg-[#EBEBF1] text-[#7A7A7A]"
                            }`}
                          >
                            {item.assessment__assessment_status__status
                              ? item.assessment__assessment_status__status
                              : "pending"}
                          </div>
                        </td>
                        <td className="py-3 px-3 text-[#7A7A7A] w-[150px]">
                          {item.total_correct ? `${item.total_correct}%` : "-"}
                        </td>
                      </tr>
                    )
                  )}
                </>
              )}
            </tbody>
          </table>
          {!assessmentAnalyticsLoading && assessmentAnalyticsMessage && (
            <div className="flex justify-center items-center w-full h-[280px]">
              <ShowMessage message={assessmentAnalyticsMessage} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const Circle = ({ assessmentResult }) => {
  return (
    <>
      <div className="w-[40%] h-full flex justify-center items-center">
        <img
          src={cap}
          alt="cap"
          style={{
            width: 100,
            position: "absolute",
          }}
        />
        <div
          style={{
            width: 130,
            position: "absolute",
          }}
        >
          <CircularProgressbar
            value={assessmentResult?.intensive_percentage}
            strokeWidth={5}
            styles={buildStyles({
              rotation: 0.65,
              strokeLinecap: "butt",
              pathTransitionDuration: 0.5,
              pathColor: `#F77B7F`,
              trailColor: "#D8D8D8",
              backgroundColor: "#AD2126",
            })}
          />
        </div>
        <div
          style={{
            width: 180,
            position: "absolute",
          }}
        >
          <CircularProgressbar
            value={assessmentResult?.strategic_percentage}
            strokeWidth={5}
            styles={buildStyles({
              rotation: 0.65,
              strokeLinecap: "butt",
              pathTransitionDuration: 0.5,
              pathColor: `#FFE398`,
              trailColor: "#D8D8D8",
              backgroundColor: "#AD2126",
            })}
          />
        </div>
        <div
          style={{
            width: 220,
            position: "absolute",
          }}
        >
          <CircularProgressbar
            value={assessmentResult?.benchmark_percentage}
            strokeWidth={4}
            styles={buildStyles({
              rotation: 0.65,
              strokeLinecap: "butt",
              pathTransitionDuration: 0.5,
              pathColor: `#017663`,
              trailColor: "#D8D8D8",
              backgroundColor: "#017663",
            })}
          />
        </div>
      </div>
    </>
  );
};

function Results() {
  const { id } = useParams();
  const token = getAccessToken();
  const Navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState("Benchmark");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [students, setStudents] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);

  // get students based on filter
  const GetStudents = async () => {
    setLoading(true);
    setStudents(null);
    await axios
      .get(
        `${BASE_URL}/api/student-catogery?assessment_id=${id}&filters=${selectedButton}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setStudents(response.data);
        setLoading(false);
        if (response.data.students.length === 0) {
          setMessage("No students found");
        } else {
          setMessage(null);
        }
      })
      .catch((error) => {
        setLoading(false);
        setMessage("Oops! Something went wrong");
      });
  };
  useEffect(() => {
    GetStudents();
  }, [id, selectedButton]);

  const NavigateToDetailsPage = (item) => {
    Navigate(`/students/${item.id}`);
  };
  return (
    <>
      <h1 className="text-[#7A7A7A] text-[26px] font-[500] font-roboto">
        Results
      </h1>
      <div className="flex gap-6 h-auto border-t border-t-[#ECECEC]">
        {/* First */}
        <div className="w-[70%] h-full py-[20px] pr-[20px]">
          <div className="flex items-center gap-5">
            <button
              onClick={() => setSelectedButton("Benchmark")}
              className={`w-[140px] h-[40px] ${
                selectedButton === "Benchmark"
                  ? "bg-[#017663] text-[#fff]"
                  : "bg-[#DFDFDF] text-[#5E5E5E]"
              } text-[#5E5E5E] text-[18px] font-[400] font-roboto border-none outline-none rounded-[50px] hover:bg-[#017663] hover:text-[#fff]`}
            >
              Benchmark
            </button>
            <button
              onClick={() => setSelectedButton("Strategic")}
              className={`w-[140px] h-[40px] ${
                selectedButton === "Strategic"
                  ? "bg-[#FFC121] text-[#fff]"
                  : "bg-[#DFDFDF] text-[#5E5E5E]"
              } text-[#5E5E5E] text-[18px] font-[400] font-roboto border-none outline-none rounded-[50px] hover:bg-[#FFC121] hover:text-[#fff]`}
            >
              Strategic
            </button>
            <button
              onClick={() => setSelectedButton("Intensive")}
              className={`w-[140px] h-[40px] ${
                selectedButton === "Intensive"
                  ? "bg-[#AD2126] text-[#fff]"
                  : "bg-[#DFDFDF] text-[#5E5E5E]"
              }  text-[18px] font-[400] font-roboto border-none outline-none rounded-[50px] hover:bg-[#AD2126] hover:text-[#fff]`}
            >
              Intensive
            </button>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-4 mt-5">
            {students?.students?.map((item, index) => (
              <StudentCard
                item={item}
                index={index}
                selectedButton={selectedButton}
                NavigateToDetailsPage={() => NavigateToDetailsPage(item)}
              />
            ))}
          </div>

          {message && !loading && (
            <div className="flex justify-center items-center w-full h-[350px]">
              <ShowMessage message="No students found" />
            </div>
          )}

          {loading && (
            <div className="flex justify-center items-center w-full h-[350px]">
              <ButtonLoader_2 />
            </div>
          )}
        </div>

        {/* Second */}
        <div className="w-[30%] h-full bg-white border-l border-l-[#ECECEC] p-[20px]">
          <h1 className="text-[#1C1D1E] text-[22px] font-[500] font-roboto">
            Class Performance
          </h1>
          <div className="mt-5">
            <CircularProgressbar
              value={students?.class_performance}
              text={students && `${students?.class_performance}%`}
              styles={buildStyles({
                rotation: 0.22,
                strokeLinecap: "butt",
                textSize: "20px",
                pathTransitionDuration: 0.5,
                pathColor: `#017663`,
                textColor: "#1C1D1E",
                trailColor: "#EEEEEE",
                backgroundColor: "#AD2126",
                textAnchor: "middle",
              })}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function StudentCard({ item, index, selectedButton, NavigateToDetailsPage }) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    <div
      className="w-full bg-[#FFFFFF] rounded-[12px] p-[15px] shadow-sm flex flex-col justify-center cursor-pointer border border-[#DCDCDC] hover:border-[#017663]"
      key={index}
      onClick={NavigateToDetailsPage}
    >
      <div className="flex gap-2 mb-4">
        {item.profile && (
          <img
            src={`${BASE_URL}/media/${item?.profile}` || "/avatar_dummy.png"}
            alt=""
            className="w-[60px] h-[60px] rounded-full"
          />
        )}
        {!item?.profile && (
          <img
            src={"/avatar_dummy.png"}
            alt=""
            className="w-[60px] h-[60px] rounded-full"
          />
        )}
        <div className="flex flex-col">
          <span className="2xl:text-[20px] font-[500] lg:text-[15px] font-roboto text-[#1C1D1E]">
            {item?.student_name}
          </span>
          <span className="2xl:text-[16px] font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
            {item?.student_id}
          </span>
        </div>
      </div>
      <div className="flex items-center justify-between mt-3">
        <h2 className="font-[400] text-[12px] font-roboto text-[#1C1D1E]">
          {selectedButton}
        </h2>
        <div className="font-[400] text-[12px] font-roboto text-[#1C1D1E]">
          {item?.total_score}/100
        </div>
      </div>
      <div className="w-full bg-[#dcdcdc9d] rounded-full h-2">
        <div
          className={`bg-[#017663] h-2 rounded-full`}
          style={{ width: `${item?.total_score || 0}%` }}
        />
      </div>
      <p className="font-[400] text-[12px] font-roboto text-[#7A7A7A] mt-4">
        The highest marks taker, achieving benchmark proficiency with scores
        ranging from 65 to 83, excels in phonics skills, showcasing
        comprehensive understanding and adept application.
      </p>
    </div>
  );
}
