/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect } from "react";
import { BiExport } from "react-icons/bi";
import { useContext } from "react";
import GlobalContext from "../context/GlobalContext";
import useGetStudents from "../hooks/useGetStudents";
import { CardSkelton_6 } from "./shared/Skeltons";
import { skelton_data } from "../staticData/SkeltonData";
import ShowMessage from "./shared/ShowMessage";
import * as XLSX from "xlsx";

const tabs = ["Today", "Week", "Month", "Year"];

function TopPerformer() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { GetStudents, studentLoading, studentsData, studentMessage } =
    useGetStudents();
  const { activeTab, setActiveTab } = useContext(GlobalContext);

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  useEffect(() => {
    GetStudents(`?filter=top_student&time_filter=${activeTab}`);
  }, [activeTab]);

  const exportToExcel = () => {
    const headers = ["Student name", "ID number", "Marks", "Ranks"];

    const data = studentsData?.map((item) => {
      return [item.name, item.student_id, item.total_score, item.rank];
    });

    const finalData = [headers, ...data];
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet(finalData);
    // Calculate max length of data in each column to set column width
    const colWidths = headers.map((header, index) => {
      const maxLength = Math.max(
        header.length,
        ...data.map((row) => (row[index] ? row[index].toString().length : 0))
      );
      return { width: maxLength + 2 };
    });
    // Apply column widths to the worksheet
    ws["!cols"] = colWidths;
    XLSX.utils.book_append_sheet(wb, ws, "Grades");
    XLSX.writeFile(wb, `Top Performers ${activeTab}.xlsx`);
  };

  return (
    <>
      <div className="w-full bg-white rounded-[20px] py-4 px-5">
        <div className="flex items-center justify-between">
          <h1 className="2xl:font-[700] 2xl:text-[26px] lg:font-[500] lg:text-[20px]  font-roboto text-[#1C1D1E]">
            Top Performer
          </h1>
          <div className="flex items-center gap-2">
            {/* <div className="custom-select w-[160px] h-[50px]">
              <select>
                <option disabled selected>
                  Choose grade
                </option>
                <option value="0">Grade: 0</option>
                <option value="1">Grade: 1</option>
                <option value="2">Grade: 2</option>
              </select>
            </div> */}

            <div
              className="flex items-center border bg-[#017663] border-[#017663] rounded p-2 gap-2 cursor-pointer text-white"
              onClick={() => exportToExcel()}
            >
              <BiExport className="text-[18px]" />
              <p className="2xl:font-[500] 2xl:text-[16px] lg:font-[300] lg:text-[14px]  font-roboto">
                Export
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="flex gap-6 mt-3">
            {tabs?.map((item, index) => (
              <p
                key={index}
                className={`cursor-pointer hover:text-[#017663] hover:border-b-2 hover:border-[#017663] ${
                  activeTab === item
                    ? "text-[#017663] border-b-2 border-[#017663]"
                    : ""
                }`}
                onClick={() => handleTabClick(item)}
              >
                {item}
              </p>
            ))}
          </div>
          <hr />
          <div className="w-full mt-4 px-0">
            <div className="max-h-[400px] overflow-y-scroll">
              <table className="w-full text-sm text-left rtl:text-right">
                <thead className="sticky z-[1] top-0 text-[#1C1D1E] font-semibold text-[16px] bg-white">
                  <tr className="">
                    {["Student name", "ID number", "Marks", "Ranks"].map(
                      (item, index) => (
                        <th
                          scope="col"
                          className="py-5 px-3 w-[25%]"
                          key={index}
                        >
                          {item}
                        </th>
                      )
                    )}
                  </tr>
                </thead>

                <tbody>
                  {studentLoading ? (
                    <>
                      {skelton_data.slice(0, 6).map((_, index) => (
                        <CardSkelton_6 key={index} />
                      ))}
                    </>
                  ) : (
                    <>
                      {studentsData?.map((item, index) => (
                        <tr
                          key={index}
                          className="bg-white cursor-pointer hover:bg-[#ffedee]"
                        >
                          <td className="py-3 px-3 text-[#7A7A7A] w-[25%]">
                            <div className="flex items-center gap-2">
                              {item.profile ? (
                                <img
                                  src={`${BASE_URL}/${item.profile}`}
                                  alt="student"
                                  className="w-[30px] h-[30px] rounded-full"
                                />
                              ) : (
                                <img
                                  src={"/avatar_dummy.png"}
                                  alt="student"
                                  className="w-[30px] h-[30px] rounded-full"
                                />
                              )}
                              <p>{item.name}</p>
                            </div>
                          </td>
                          <td className="py-3 px-3 text-[#7A7A7A] w-[25%]">
                            {item.student_id}
                          </td>
                          <td className="py-3 px-3 text-[#7A7A7A] w-[25%]">
                            {item.total_score}
                          </td>
                          <td className="py-3 px-3 text-[#017663] w-[25%]">
                            {item?.rank || "-"}
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
              {!studentLoading && studentMessage && (
                <div className="flex justify-center items-center w-full h-[200px]">
                  <ShowMessage message={studentMessage} />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TopPerformer;
