/* eslint-disable react/jsx-pascal-case */
import { useState } from "react";
import { useFormik } from "formik";
import { BiHide, BiShow } from "react-icons/bi";
import * as Yup from "yup";
import useChangePassword from "../hooks/useChangePassword";
import { ButtonLoader_1 } from "./shared/Loaders";

const ChangePasswordValidationSchema = Yup.object().shape({
  old_password: Yup.string().required("Old Password is required"),
  password: Yup.string()
    .required("New password is required")
    .min(6, "Password length must be at least 6 characters long!")
    .matches(
      /^(?=.*[A-Za-z])(?=.*[@$!%*#?&]).*$/,
      "Password must contain at least one alphabetic character and one special character"
    )
    .max(20, "password must not exceed 20 characters"),
  confirm_password: Yup.string()
    .required("Confirm new password is required")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

const ChangePassword = () => {
  const [oldPassowrdVisible, setOldPassowrdVisible] = useState(false);
  const [passowrdVisible, setPassowrdVisible] = useState(false);
  const [confirmPassowrdVisible, setConfirmPassowrdVisible] = useState(false);
  const { loading, ChangePassword } = useChangePassword();

  const initialValues = {
    old_password: "",
    password: "",
    confirm_password: "",
  };
  const { values, errors, handleChange, handleSubmit, touched } = useFormik({
    initialValues: initialValues,
    validationSchema: ChangePasswordValidationSchema,
    onSubmit: async (values, action) => {
      await ChangePassword(
        {
          old_password: values.old_password,
          new_password: values.password,
        },
        action
      );
    },
  });

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="w-full flex flex-col justify-center rounded-[20px] gap-3 relative"
      >
        <div className="w-full">
          <label>Current password</label>
          <div className="relative">
            <input
              type={oldPassowrdVisible ? "text" : "password"}
              name="old_password"
              value={values.old_password}
              onChange={handleChange}
              placeholder="Enter current password"
              className="mt-1 border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
            />
            <div
              className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
              onClick={() => setOldPassowrdVisible(!oldPassowrdVisible)}
            >
              {oldPassowrdVisible ? (
                <BiShow className="text-2xl text-[#95969D]" />
              ) : (
                <BiHide className="text-2xl text-[#95969D]" />
              )}
            </div>
          </div>
          {errors.old_password && touched.old_password && (
            <p className="text-red-700 text-xs">{errors.old_password}</p>
          )}
        </div>
        <div className="w-full">
          <label>New password</label>
          <div className="relative">
            <input
              type={passowrdVisible ? "text" : "password"}
              name="password"
              value={values.password}
              onChange={handleChange}
              placeholder="Enter new password"
              className="mt-1 border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
            />
            <div
              className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
              onClick={() => setPassowrdVisible(!passowrdVisible)}
            >
              {passowrdVisible ? (
                <BiShow className="text-2xl text-[#95969D]" />
              ) : (
                <BiHide className="text-2xl text-[#95969D]" />
              )}
            </div>
          </div>
          {errors.password && touched.password && (
            <p className="text-red-700 text-xs">{errors.password}</p>
          )}
        </div>
        <div className="w-full">
          <label>Confirm new password</label>
          <div className="relative">
            <input
              type={confirmPassowrdVisible ? "text" : "password"}
              name="confirm_password"
              value={values.confirm_password}
              onChange={handleChange}
              placeholder="Confirm new password"
              className="mt-1 border border-[#9D9D9D] h-[50px] w-[100%] px-2 text-[#5A5A5A]  bg-transparent rounded outline-none hover:border-[#017663] focus:border-[#017663]"
            />
            <div
              className="h-full absolute top-0 right-3 cursor-pointer flex items-center justify-center text-center"
              onClick={() => setConfirmPassowrdVisible(!confirmPassowrdVisible)}
            >
              {confirmPassowrdVisible ? (
                <BiShow className="text-2xl text-[#95969D]" />
              ) : (
                <BiHide className="text-2xl text-[#95969D]" />
              )}
            </div>
          </div>
          {errors.confirm_password && touched.confirm_password && (
            <p className="text-red-700 text-xs">{errors.confirm_password}</p>
          )}
        </div>
        <button
          type="submit"
          disabled={loading}
          className="mt-2 w-[200px]  h-[45px] bg-[#1C1D1E] font-[600] text-white rounded-[10px] flex justify-center items-center  cursor-pointer"
        >
          {loading ? <ButtonLoader_1 /> : "Submit"}
        </button>
      </form>
    </>
  );
};

export default ChangePassword;
