import { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { getAccessToken } from "../storage/storage";
import GlobalContext from "../context/GlobalContext";

const useUpdateProfile = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const { setUpdateProfile } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const token = getAccessToken();

  const UpdateProfile = async (payload) => {
    setLoading(true);
    try {
      await axios.put(`${BASE_URL}/api/teacher/edit/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      setUpdateProfile(true);
      Swal.fire({
        icon: "success",
        text: "Your profile successfully updated!",
        customClass: {
          confirmButton: "custom-green-button",
        },
      });
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        text:
          error?.response?.data?.message ||
          "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later",
        customClass: {
          confirmButton: "custom-red-button",
        },
      });
    }
  };

  return { loading, UpdateProfile };
};

export default useUpdateProfile;
