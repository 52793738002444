import axios from "axios";
import { useState } from "react";
import { getAccessToken } from "../storage/storage";

function useGetStudentsDetail() {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [studentsDetail, setStudentsDetail] = useState(null);
  const [studentDetailLoading, setStudentDetailLoading] = useState(true);
  const [studentsDetailMessage, setStudentsDetailMessage] = useState(null);

  const GetStudentsDetail = async (id) => {
    await axios
      .get(`${BASE_URL}/api/student/details?student_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setStudentsDetail(response.data);
        setStudentDetailLoading(false);
      })
      .catch((error) => {
        setStudentDetailLoading(false);
        // setStudentsDetailMessage(
        //   "Oops! Something went wrong on our end. Please give us a moment to fix it. Feel free to try again later"
        // );
      });
  };

  return {
    GetStudentsDetail,
    studentsDetail,
    studentDetailLoading,
    studentsDetailMessage,
  };
}

export default useGetStudentsDetail;
