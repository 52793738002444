import React, { useEffect, useRef, useState } from "react";
import { FaMinus, FaPlus } from "react-icons/fa6";
import Checkbox from "./shared/Checkbox";

function AssessmentDropdown({
  filteredAssessments,
  setFilteredAssessments,
  selectedGrade,
  selectedTerm,
  setSelectedTerm,
  selectedParts,
  setSelectedParts,
}) {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const terms = ["Fall", "Winter", "Spring"];
  const partsData = {
    Fall: {
      0: ["Part A", "Part B"],
      1: ["Part A", "Part B", "Part C", "Part D", "Part E"],
      2: [
        "Part A",
        "Part B",
        "Part C",
        "Part D",
        "Part E",
        "Part F",
        "Part G",
        "Part H",
        "Part I",
        "Part J",
        "Part K",
        "Part L",
      ],
    },
    Winter: {
      0: ["Part A", "Part B", "Part C", "Part D", "Part E"],
      1: [
        "Part A",
        "Part B",
        "Part C",
        "Part D",
        "Part E",
        "Part F",
        "Part G",
        "Part H",
        "Part I",
        "Part J",
        "Part K",
      ],
      2: [
        "Part A",
        "Part B",
        "Part C",
        "Part D",
        "Part E",
        "Part F",
        "Part G",
        "Part H",
        "Part I",
        "Part J",
        "Part K",
        "Part L",
      ],
    },
    Spring: {
      0: ["Part A", "Part B", "Part C", "Part D", "Part E"],
      1: [
        "Part A",
        "Part B",
        "Part C",
        "Part D",
        "Part E",
        "Part F",
        "Part G",
        "Part H",
        "Part I",
        "Part J",
        "Part K",
        "Part L",
      ],
      2: [
        "Part A",
        "Part B",
        "Part C",
        "Part D",
        "Part E",
        "Part F",
        "Part G",
        "Part H",
        "Part I",
        "Part J",
        "Part K",
        "Part L",
      ],
    },
  };

  const initializeSelectedParts = () => {
    const initialState = {};
    terms.forEach((term) => {
      initialState[term] = {};
    });
    return initialState;
  };

  useEffect(() => {
    if (!selectedParts) {
      setSelectedParts(initializeSelectedParts());
    }
  }, [selectedParts, setSelectedParts]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);
    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  const handleSelectTerm = (term) => {
    if (selectedTerm !== term) {
      setSelectedTerm(term);
      const newSelectedParts = partsData[term][selectedGrade?.grade].reduce(
        (acc, part) => {
          acc[`Part ${part.split(" ")[1]}`] = true;
          return acc;
        },
        {}
      );
      setSelectedParts((prevSelectedParts) => ({
        ...prevSelectedParts,
        [term]: newSelectedParts,
      }));
    } else {
      setSelectedTerm(null);
    }
  };

  const handlePartSelection = (term, part) => {
    const partKey = part;

    setSelectedParts((prevSelectedParts) => {
      const currentTermParts = prevSelectedParts[term] || {};
      const isChecked = currentTermParts[partKey];

      const updatedTermParts = { ...currentTermParts };

      if (isChecked) {
        delete updatedTermParts[partKey];
      } else {
        updatedTermParts[partKey] = true;
      }

      return {
        ...prevSelectedParts,
        [term]: updatedTermParts,
      };
    });
  };

  return (
    <>
      <div className="relative" ref={dropdownRef}>
        <button
          id="dropdownSearchButton"
          onClick={toggleDropdown}
          className="bg-white outline-none border border-[#DCDCDC] text-[#1C1D1E] font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center gap-4 hover:border-[#017663] focus-within:border-[#017663]"
          type="button"
        >
          {selectedTerm ? selectedTerm : "Select Assessment"}
          <svg
            className="w-2.5 h-2.5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
        <div
          id="dropdownSearch"
          className={`${
            isOpen ? "block" : "hidden"
          } z-10 bg-white rounded-lg shadow w-60 dark:bg-gray-700 absolute top-full right-0`}
        >
          <ul
            className="min-h-48 max-h-80 py-2 overflow-y-auto"
            aria-labelledby="dropdownSearchButton"
          >
            {selectedGrade && (
              <>
                {/* Render terms (Fall, Winter, Spring) */}
                <ul className="bg-white">
                  {terms?.map((term, termIndex) => (
                    <li key={termIndex}>
                      <div
                        className={`flex justify-between items-center px-4 py-1  hover:bg-[#FFEDEE] cursor-pointer ${
                          selectedTerm === term
                            ? "bg-[#FFEDEE]"
                            : "bg-transparent"
                        }`}
                        onClick={() => handleSelectTerm(term)}
                      >
                        <div className="flex items-center gap-2">
                          <div
                            className={`w-[15px] h-[15px] flex justify-center items-center rounded-full border ${
                              selectedTerm === term
                                ? "border-[#017663]"
                                : "border-[#7A7A7A]"
                            }`}
                          >
                            <div
                              className={`w-[10px] h-[10px] rounded-full ${
                                selectedTerm === term
                                  ? "bg-[#017663]"
                                  : "bg-transparent"
                              }`}
                            />
                          </div>
                          <p className="text-[#1C1D1E] text-[16px] font-[400]">
                            {term}
                          </p>
                        </div>
                        <div className="text-[#5E5E5E]">
                          {selectedTerm === term ? <FaMinus /> : <FaPlus />}
                        </div>
                      </div>
                      {/* Render parts based on selected term and grade */}
                      {selectedTerm === term && (
                        <ul className="px-4 py-2 grid grid-cols-2 gap-1">
                          {partsData[term][selectedGrade?.grade]?.map(
                            (part, partIndex) => (
                              <li
                                key={partIndex}
                                className="flex items-center gap-2"
                              >
                                <Checkbox
                                  checked={!!selectedParts[term]?.[part]}
                                  onChange={() =>
                                    handlePartSelection(term, part)
                                  }
                                />
                                <p>{part}</p>
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </li>
                  ))}
                </ul>
              </>
            )}
            {!selectedGrade && (
              <div className="flex justify-center items-center w-full h-full">
                <p className="text-gray-600">Select class & grade</p>
              </div>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}

export default AssessmentDropdown;
