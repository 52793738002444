import axios from "axios";
import { useState } from "react";
import { getAccessToken } from "../storage/storage";

const useGetAssessmentAnalytics = () => {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [assessmentAnalyticsLoading, setAssessmentAnalyticsLoading] =
    useState(true);
  const [assessmentAnalyticsData, setAssessmentAnalyticsData] = useState(null);
  const [assessmentAnalyticsMessage, setAssessmentAnalyticsMessage] =
    useState(null);

  const getAssessmentAnalytics = async (id) => {
    await axios
      .get(`${BASE_URL}/api/assessment-analytics?assessment_id=${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAssessmentAnalyticsLoading(false);
        setAssessmentAnalyticsData(response.data);
        if (response.data?.student_completed?.length === 0) {
          setAssessmentAnalyticsMessage("No data found");
        } else {
          setAssessmentAnalyticsMessage(null);
        }
      })
      .catch((error) => {
        setAssessmentAnalyticsLoading(false);
        setAssessmentAnalyticsMessage("Oops! Something went wrong");
      });
  };
  return {
    assessmentAnalyticsLoading,
    assessmentAnalyticsData,
    getAssessmentAnalytics,
    assessmentAnalyticsMessage,
  };
};
export default useGetAssessmentAnalytics;
