import React from "react";

export const CardSkelton_1 = () => {
  return (
    <div
      role="status"
      className="bg-white rounded-[20px] w-[33%] py-12 px-6 flex relative border border-gray-200 shadow animate-pulse dark:border-gray-700"
      style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
    >
      <div>
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
      </div>
      <div class="absolute bottom-6 right-8">
        <div className="h-8 w-8 bg-gray-200 rounded-[5px] dark:bg-gray-700"></div>
      </div>
    </div>
  );
};

export const CardSkelton_2 = () => {
  return (
    <div
      role="status"
      className="bg-white rounded-[20px] w-full p-4 flex flex-col relative border border-gray-200 shadow animate-pulse dark:border-gray-700"
      style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
    >
      <div className="w-10 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 absolute top-3 right-3"></div>
      <div className="flex items-center gap-2">
        <svg
          class="w-14 h-14 text-gray-200 dark:text-gray-700"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
        </svg>
        <div>
          <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
          <div className="w-24 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </div>
      <div className="w-36 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700  mt-4 mb-2"></div>
      <div className="flex justify-between mt-3">
        <div className="w-[33.33%] flex flex-col items-center justify-center">
          <div className="w-10 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700  mb-2"></div>
          <div className="w-8 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="w-[33.33%] flex flex-col items-center justify-center">
          <div className="w-10 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700  mb-2"></div>
          <div className="w-8 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="w-[33.33%] flex flex-col items-center justify-center">
          <div className="w-10 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700  mb-2"></div>
          <div className="w-8 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </div>
    </div>
  );
};

export const CardSkelton_3 = () => {
  return (
    <div
      role="status"
      className="bg-white rounded-[20px] w-full p-4 flex flex-col relative border border-gray-200 shadow animate-pulse dark:border-gray-700"
      style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
    >
      <div className="w-2 h-8 bg-gray-200 rounded-full dark:bg-gray-700 absolute top-3 right-3"></div>
      <div className="flex flex-col gap-2 mb-4">
        <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-32 mb-2"></div>
        <div className="w-24 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
      <div className="w-36 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700  mt-4 mb-2"></div>
      <div className="flex items-center justify-between mt-3">
        <div className="w-10 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700  mb-2"></div>
        <div className="w-8 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
      <div className="w-full h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    </div>
  );
};

export const CardSkelton_4 = () => {
  return (
    <div
      role="status"
      className="bg-white rounded-[20px] w-full p-6 flex flex-col relative border border-gray-200 shadow animate-pulse dark:border-gray-700"
      style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
    >
      <div className="w-2 h-8 bg-gray-200 rounded-full dark:bg-gray-700 absolute top-3 right-3"></div>
      <div className="flex flex-col items-center justify-center gap-2">
        <svg
          class="w-14 h-14 text-gray-200 dark:text-gray-700"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
        </svg>
        <div className=" w-32 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 mt-2"></div>
        <div className="w-24 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
      <div className="flex justify-between mt-6">
        <div className="w-[33.33%] flex flex-col items-center justify-center">
          <div className="w-10 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700  mb-2"></div>
          <div className="w-8 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="w-[33.33%] flex flex-col items-center justify-center">
          <div className="w-10 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700  mb-2"></div>
          <div className="w-8 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="w-[33.33%] flex flex-col items-center justify-center">
          <div className="w-10 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700  mb-2"></div>
          <div className="w-8 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </div>
    </div>
  );
};

export const CardSkelton_5 = () => {
  return (
    <div
      role="status"
      className="bg-white rounded-[20px] w-full py-[30px] px-[20px] flex border border-gray-200 shadow animate-pulse dark:border-gray-700"
      style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
    >
      <div className="flex flex-col w-[50%]">
        <div className="w-32 h-3 bg-gray-200 rounded-full dark:bg-gray-700 mt-2"></div>
        <div className="flex items-center gap-5 mt-3">
          <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          <div className="w-24 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </div>
      <div className="flex flex-col justify-start items-center w-[25%] border-l border-r border-[#DCDCDC]">
        <div className="w-24 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        <div class="flex -space-x-4 rtl:space-x-reverse mt-2">
          <svg
            class="w-10 h-10 text-gray-200 dark:text-gray-700"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
          <svg
            class="w-10 h-10 text-gray-200 dark:text-gray-700"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
          <svg
            class="w-10 h-10 text-gray-200 dark:text-gray-700"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
        </div>
      </div>
      <div className="flex flex-col justify-start items-center w-[25%]">
        <div className="w-24 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        <div class="flex -space-x-4 rtl:space-x-reverse mt-2">
          <svg
            class="w-10 h-10 text-gray-200 dark:text-gray-700"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
          <svg
            class="w-10 h-10 text-gray-200 dark:text-gray-700"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
          <svg
            class="w-10 h-10 text-gray-200 dark:text-gray-700"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
        </div>
      </div>
    </div>
  );
};

export const CardSkelton_6 = () => {
  return (
    <tr role="status" className="animate-pulse mt-2">
      <td className="py-3 px-3">
        <div className="flex items-center gap-2">
          <svg
            class="w-8 h-8 text-gray-200 dark:text-gray-700"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
          <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </td>
      <td className="py-3 px-3">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
      <td className="py-3 px-3">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
      <td className="py-3 px-3">
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </td>
    </tr>
  );
};

export const CardSkelton_7 = () => {
  return (
    <div
      role="status"
      className="bg-white rounded-[20px] w-full flex items-center justify-between px-8 py-2 border border-gray-200 shadow animate-pulse dark:border-gray-700"
    >
      <div className="flex items-center gap-5">
        <svg
          class="w-[60px] h-[60px] text-gray-200 dark:text-gray-700"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
        >
          <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
        </svg>
        <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
      </div>
      <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
    </div>
  );
};

export const CardSkelton_8 = () => {
  return (
    <div
      role="status"
      className="w-[90px] h-[70px] bg-white flex items-center border border-gray-200 shadow animate-pulse dark:border-gray-700"
    >
      <div className="w-full h-full flex justify-center items-center bg-gray-200 dark:bg-gray-700"></div>
    </div>
  );
};

export const CardSkelton_9 = () => {
  return (
    <div
      role="status"
      className="bg-white rounded-[20px] w-full p-6 flex flex-col relative border border-gray-200 shadow animate-pulse dark:border-gray-700"
      style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
    >
      <div className="w-2 h-8 bg-gray-200 rounded-full dark:bg-gray-700 absolute top-3 right-3"></div>
      <div className="flex flex-col items-center justify-center gap-2">
        <div className=" w-32 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 mt-2"></div>
      </div>
      <div className="flex justify-between mt-6">
        <div className="w-[33.33%] flex flex-col items-center justify-center">
          <div className="w-10 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700  mb-2"></div>
          <div className="w-8 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="w-[33.33%] flex flex-col items-center justify-center">
          <div className="w-10 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700  mb-2"></div>
          <div className="w-8 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
        <div className="w-[33.33%] flex flex-col items-center justify-center">
          <div className="w-10 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700  mb-2"></div>
          <div className="w-8 h-2.5 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </div>
    </div>
  );
};

export const CardSkelton_10 = () => {
  return (
    <tr role="status" className="animate-pulse mt-2">
      <td className="py-3 px-3">
        <div className="flex items-center justify-center gap-2">
          <svg
            class="w-8 h-8 text-gray-200 dark:text-gray-700"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm0 5a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm0 13a8.949 8.949 0 0 1-4.951-1.488A3.987 3.987 0 0 1 9 13h2a3.987 3.987 0 0 1 3.951 3.512A8.949 8.949 0 0 1 10 18Z" />
          </svg>
          <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </td>
      <td className="py-3 px-3">
        <div className="flex justify-center items-center">
          <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </td>
      <td className="py-3 px-3">
        <div className="flex justify-center items-center">
          <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </td>
      <td className="py-3 px-3">
        <div className="flex justify-center items-center">
          <div className="w-20 h-3 bg-gray-200 rounded-full dark:bg-gray-700"></div>
        </div>
      </td>
    </tr>
  );
};
