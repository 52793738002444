/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useContext, useEffect, useState } from "react";
import DashboardLayout from "../components/shared/DashboardLayout";
import { CiSearch } from "react-icons/ci";
import AddAssessmentModal from "../components/modals/AddAssessmentModal";
import { useNavigate } from "react-router";
import AssessmentCard from "../components/AssessmentCard";
import Paginations from "../components/shared/Paginations";
import useGetAssessments from "../hooks/useGetAssessments";
import { skelton_data } from "../staticData/SkeltonData";
import { CardSkelton_3 } from "../components/shared/Skeltons";
import ShowMessage from "../components/shared/ShowMessage";
import GlobalContext from "../context/GlobalContext";
import { FaPlus } from "react-icons/fa6";
import AppHead from "../seo/AppHead";
import { debounce } from "lodash";

function AssessmentsPage() {
  return (
    <>
      <AppHead title="Assessments - ELEMENT EDUCATION" />
      <DashboardLayout>
        <div className="bg-backgroundColor px-5 py-2 flex flex-col w-full h-auto">
          <MainLayout />
        </div>
      </DashboardLayout>
    </>
  );
}

export default AssessmentsPage;

function MainLayout() {
  const { updateResponse, userInfo } = useContext(GlobalContext);
  const [dateFilter, setDateFilter] = useState("");
  const [query, setQuery] = useState("");
  const {
    GetAssessments,
    assessmentsData,
    assessmentLoading,
    assessmentMessage,
    currentPageAssessment,
    totalPagesAssessment,
    setCurrentPageAssessment,
  } = useGetAssessments();

  const debouncedSearch = useCallback(
    debounce((value) => {
      setCurrentPageAssessment(1);
      GetAssessments(
        `?page=${currentPageAssessment}&name=${value}&filter=${dateFilter}`
      );
    }, 1000),
    []
  );

  useEffect(() => {
    GetAssessments(
      `?page=${currentPageAssessment}&name=${query}&filter=${dateFilter}`
    );
  }, [currentPageAssessment, updateResponse, dateFilter]);

  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPageAssessment(value);
  };
  const Navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const NavigateToDetailsPage = (item) => {
    Navigate(`/assessments/${item.id}`);
  };

  return (
    <>
      <div className="flex md:flex-row xs:flex-col md:gap-0 xs:gap-2 md:items-center justify-between w-full">
        <div>
          <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[500] lg:text-[30px] font-roboto text-[#263238]">
            Assessments
          </h1>
        </div>
        <div className="flex items-center gap-3 xs:justify-end md:justify-start">
          <div className="flex items-center border bg-white border-[#DCDCDC] hover:border-[#017663] focus-within:border-[#017663] rounded-[10px] p-2 w-[280px] h-[50px]">
            <input
              type="search"
              value={query}
              onChange={handleChange}
              placeholder="Search..."
              className="outline-none border-none w-[100%] h-full bg-transparent px-3"
            />
            <CiSearch className="text-[20px] text-[#C1C1C1]" />
          </div>
          <div className="custom-select w-[130px] h-[50px]">
            <select onChange={(e) => setDateFilter(e.target.value)}>
              <option disabled selected>
                Select
              </option>
              <option value="24hr">24 hr</option>
              <option value="7days">7 days</option>
              <option value="1month">1 month</option>
              <option value="1year">1 year</option>
            </select>
          </div>
          {userInfo?.user?.is_teacher && (
            <div
              onClick={() => setIsOpenModal(true)}
              className="flex items-center border bg-[#017663] border-none rounded-[6px] px-5 gap-3 w-auto h-[50px] cursor-pointer text-white"
            >
              <FaPlus className="text-[18px] text-white" />
              <p className="2xl:font-[700] 2xl:text-[15px] lg:font-[500] lg:text-[14px] font-roboto  lg:block xs:hidden">
                Create Assessment
              </p>
            </div>
          )}
        </div>
      </div>
      {assessmentLoading ? (
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mt-5">
          {skelton_data.slice(0, 8).map((_, index) => (
            <CardSkelton_3 key={index} />
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mt-5">
          {assessmentsData?.map((item, index) => (
            <div key={index}>
              <AssessmentCard
                key={index}
                item={item}
                index={index}
                NavigateToDetailsPage={() => NavigateToDetailsPage(item)}
              />
            </div>
          ))}
        </div>
      )}
      {!assessmentLoading && assessmentMessage && (
        <div className="w-full h-[50vh] flex justify-center items-center">
          <ShowMessage message={assessmentMessage} />
        </div>
      )}
      <div className="w-full flex justify-center items-center mt-5">
        <Paginations
          totalPages={totalPagesAssessment}
          currentPage={currentPageAssessment}
          handlePageChange={handlePageChange}
        />
      </div>

      <AddAssessmentModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
      />
    </>
  );
}
