/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import DashboardLayout from "../components/shared/DashboardLayout";
import { CiSearch } from "react-icons/ci";
import Paginations from "../components/shared/Paginations";
import { skelton_data } from "../staticData/SkeltonData";
import { CardSkelton_5 } from "../components/shared/Skeltons";
import useGetClassList from "../hooks/useGetClassList";
import GlobalContext from "../context/GlobalContext";
import ShowMessage from "../components/shared/ShowMessage";
import BulkUpload from "../components/modals/BulkUpload";
import AddClassModal from "../components/modals/AddClassModal";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../components/modals/DeleteModal";
import AppHead from "../seo/AppHead";
import { debounce } from "lodash";

function ClassesPage() {
  return (
    <>
      <AppHead title="Classes - ELEMENT EDUCATION" />
      <DashboardLayout>
        <div className="bg-backgroundColor px-5 py-2 flex flex-col w-full h-auto">
          <MainLayout />
        </div>
      </DashboardLayout>
    </>
  );
}

export default ClassesPage;

function MainLayout() {
  const Navigate = useNavigate();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalBulkUpload, setIsOpenModalBulkUpload] = useState(false);
  const { userInfo, updateResponse } = useContext(GlobalContext);
  const [showMenuIndex, setShowMenuIndex] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [query, setQuery] = useState("");

  const {
    classesLoading,
    GetClassList,
    classList,
    classesMessage,
    totalPagesClasses,
    currentPageClasses,
    setCurrentPageClasses,
  } = useGetClassList();

  const debouncedSearch = useCallback(
    debounce((value) => {
      setCurrentPageClasses(1);
      GetClassList(`?page=${currentPageClasses}&search=${value}`);
    }, 1000),
    []
  );

  useEffect(() => {
    GetClassList(`?page=${currentPageClasses}&search=${query}`);
  }, [currentPageClasses, updateResponse]);

  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPageClasses(value);
  };

  const handleMenu = (item, e, index) => {
    e.stopPropagation();
    setShowMenuIndex(index);
    setSelectedClass(item);
  };

  const NavigateToGradePage = (item) => {
    Navigate(`/classes/grades/${item.class_name}/${item.id}`);
  };

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMenuIndex(null);
        setSelectedClass(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="flex md:flex-row xs:flex-col md:gap-0 xs:gap-2 md:items-center justify-between w-full">
        <div>
          <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[500] lg:text-[30px] font-roboto text-[#263238]">
            Classes
          </h1>
        </div>
        <div className="flex items-center gap-3 xs:justify-end md:justify-start">
          <div className="flex items-center border bg-white border-[#DCDCDC] hover:border-[#017663] focus-within:border-[#017663] rounded-[10px] p-2 w-[280px] h-[50px] ">
            <input
              type="search"
              value={query}
              onChange={handleChange}
              placeholder="Search..."
              className="outline-none border-none w-[100%] h-full bg-transparent px-3"
            />
            <CiSearch className="text-[20px] text-[#C1C1C1]" />
          </div>
          {userInfo?.is_superuser === true && (
            <>
              {/* <div
                className="flex items-center border bg-transparent border-[#017663] rounded-[6px] px-5 gap-3 min-w-[150px] h-[50px] cursor-pointer text-[#017663]"
                onClick={() => {
                  setIsOpenModalBulkUpload(true);
                }}
              >
                <TbCloudUpload className="text-[18px] text-[#017663]" />
                <p className="2xl:font-[700] 2xl:text-[14px] lg:font-[500] lg:text-[14px]  font-roboto">
                  Bulk Upload
                </p>
              </div> */}
              <div
                onClick={() => setIsOpenModal(true)}
                className="flex items-center border bg-[#017663] border-none rounded-[6px] px-5 gap-3 lg:w-[180px] xs:w-auto h-[50px] cursor-pointer text-white"
              >
                <FaPlus className="text-[18px] text-white" />
                <p className="2xl:font-[700] 2xl:text-[15px] lg:font-[500] lg:text-[14px] font-roboto  lg:block xs:hidden">
                  Add New Class
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      {classesLoading ? (
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4 mt-5">
          {skelton_data.slice(0, 8).map((_, index) => (
            <CardSkelton_5 key={index} />
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4 mt-5">
          {classList?.map((item, index) => (
            <div key={index}>
              <ClassCard
                key={index}
                item={item}
                index={index}
                show={showMenuIndex === index}
                handleMenu={(e) => handleMenu(item, e, index)}
                NavigateToGradePage={() => NavigateToGradePage(item)}
                setDeleteModal={setDeleteModal}
                setIsEditMode={setIsEditMode}
                setIsOpenModal={setIsOpenModal}
                setShowMenuIndex={setShowMenuIndex}
                dropdownRef={dropdownRef}
              />
            </div>
          ))}
        </div>
      )}

      {!classesLoading && classesMessage && (
        <div className="w-full h-[50vh] flex justify-center items-center">
          <ShowMessage message={classesMessage} />
        </div>
      )}

      <div className="w-full flex justify-center items-center mt-5">
        <Paginations
          totalPages={totalPagesClasses}
          currentPage={currentPageClasses}
          handlePageChange={handlePageChange}
        />
      </div>

      <AddClassModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        selectedClass={selectedClass}
        setSelectedClass={setSelectedClass}
      />

      <BulkUpload
        isOpenModal={isOpenModalBulkUpload}
        setIsOpenModal={setIsOpenModalBulkUpload}
        type="classes"
      />

      <DeleteModal
        setIsOpenModal={setDeleteModal}
        isOpenModal={deleteModal}
        setSelected={setSelectedClass}
        url={`/api/class/?id=${selectedClass?.id}`}
      />
    </>
  );
}

function ClassCard({
  item,
  index,
  show,
  handleMenu,
  NavigateToGradePage,
  setDeleteModal,
  setIsEditMode,
  setIsOpenModal,
  setShowMenuIndex,
  dropdownRef,
}) {
  const { userInfo } = useContext(GlobalContext);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    <>
      <div
        className="w-full bg-[#FFFFFF] rounded-[20px] p-[20px] flex items-center relative cursor-pointer border border-transparent hover:border-[#017663]"
        key={index}
        onClick={NavigateToGradePage}
      >
        <div className="flex flex-col w-[50%]">
          <span className="2xl:text-[28px] font-[500] lg:text-[20px] font-roboto text-[#1C1D1E]">
            {item.class_name}
          </span>
          <div className="flex items-center gap-5">
            <span className="text-[20px] font-[400] font-roboto text-[#7A7A7A]">
              {item.teachers?.length} Teachers
            </span>
            <span className="text-[20px] font-[400] font-roboto text-[#7A7A7A]">
              {item.students?.length} Students
            </span>
          </div>
        </div>
        <div className="flex flex-col justify-start items-center w-[25%] border-l border-r border-[#DCDCDC]">
          <span className="text-[20px] font-[400] font-roboto text-[#7A7A7A]">
            Teachers
          </span>
          <div class="flex -space-x-4 rtl:space-x-reverse">
            {item.teachers?.slice(0, 4)?.map((data, index) => (
              <img
                class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                src={`${
                  data.profile_picture
                    ? `${BASE_URL}/${data.profile_picture}`
                    : `/avatar_dummy.png`
                }`}
                alt=""
              />
            ))}
          </div>
        </div>
        <div className="flex flex-col justify-start items-center w-[25%]">
          <span className="text-[20px] font-[400] font-roboto text-[#7A7A7A]">
            Top Students
          </span>
          <div class="flex -space-x-4 rtl:space-x-reverse">
            {item.students?.slice(0, 4)?.map((data, index) => (
              <img
                class="w-10 h-10 border-2 border-white rounded-full dark:border-gray-800"
                src={`${
                  data.profile_picture
                    ? `${BASE_URL}/${data.profile_picture}`
                    : `/avatar_dummy.png`
                }`}
                alt=""
              />
            ))}
          </div>
        </div>
        {userInfo?.is_superuser === true && (
          <div className="absolute right-1 top-1 p-2">
            <HiOutlineDotsVertical
              className="text-[20px] text-[#7A7A7A] z-40  cursor-pointer"
              onClick={handleMenu}
            />
          </div>
        )}
        {show && (
          <DropMenu
            setDeleteModal={setDeleteModal}
            setIsEditMode={setIsEditMode}
            setIsOpenModal={setIsOpenModal}
            setShowMenuIndex={setShowMenuIndex}
            dropdownRef={dropdownRef}
          />
        )}
      </div>
    </>
  );
}

function DropMenu({
  setDeleteModal,
  setIsEditMode,
  setIsOpenModal,
  setShowMenuIndex,
  dropdownRef,
}) {
  return (
    <div
      className="w-[80px] rounded border border-[#DCDCDC] bg-[#F9F9FF] p-1 absolute right-[2%] top-[15%]  flex flex-col z-[8888] "
      ref={dropdownRef}
    >
      <span
        className=" font-[400] text-[13px] font-roboto text-[#1C1D1E]  hover:bg-[#f09ea073] rounded px-2 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setDeleteModal(true);
          setShowMenuIndex(null);
        }}
      >
        Remove
      </span>
      <span
        className=" font-[400] text-[13px] font-roboto text-[#1C1D1E]  hover:bg-[#f09ea073] rounded px-2 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setIsEditMode(true);
          setIsOpenModal(true);
          setShowMenuIndex(null);
        }}
      >
        Edit
      </span>
    </div>
  );
}
