import axios from "axios";
import { useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import { getAccessToken } from "../storage/storage";

const useGetTeachersOnScroll = () => {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [teachersData, setTeachersData] = useState([]);
  const [teacherLoading, setTeacherLoading] = useState(true);
  const [teacherDataLoading, setTeacherDataLoading] = useState(false);
  const [teacherMessage, setTeacherMessage] = useState(null);
  const [totalPagesTeacher, setTotalPagesTeacher] = useState();
  const [currentPageTeacher, setCurrentPageTeacher] = useState(1);
  const { setUpdateResponse } = useContext(GlobalContext);

  const GetTeachersOnScroll = async (filter) => {
    await axios
      .get(`${BASE_URL}/api/teacher/${filter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setTeacherLoading(false);
        setTeacherDataLoading(false);
        setTotalPagesTeacher(response.data.num_pages);
        setUpdateResponse(false);
        if (currentPageTeacher === 1) {
          setTeachersData(response.data.results);
        } else {
          setTeachersData((previousData) => [
            ...previousData,
            ...response.data.results,
          ]);
        }
        if (response.data.results.length === 0) {
          setTeacherMessage("No records found");
        } else {
          setTeacherMessage(null);
        }
      })
      .catch((error) => {
        setTeacherLoading(false);
        setTeacherDataLoading(false);
        if (teachersData?.length === 0) {
          setTeacherMessage("Oops! Something went wrong");
        }
      });
  };
  return {
    teacherLoading,
    teacherDataLoading,
    GetTeachersOnScroll,
    teachersData,
    teacherMessage,
    totalPagesTeacher,
    currentPageTeacher,
    setTotalPagesTeacher,
    setCurrentPageTeacher,
    setTeacherLoading,
    setTeachersData,
    setTeacherDataLoading,
  };
};
export default useGetTeachersOnScroll;
