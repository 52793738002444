import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ButtonLoader_2 } from "./shared/Loaders";
import { getAccessToken } from "../storage/storage";

function StudentsDropdown({
  handleSelectChange,
  selectedStudent,
  assessmentId,
}) {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [studentsData, setStudentsData] = useState([]);
  const [studentLoading, setStudentLoading] = useState(true);
  const [studentDataLoading, setStudentDataLoading] = useState(false);
  const [studentMessage, setStudentMessage] = useState(null);
  const [totalPagesStudent, setTotalPagesStudent] = useState();
  const [currentPageStudent, setCurrentPageStudent] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);
    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  const getStudents = async () => {
    await axios
      .get(`${BASE_URL}/api/assessment/student?assessment_id=${assessmentId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setStudentLoading(false);
        setStudentDataLoading(false);
        if (currentPageStudent === 1) {
          setStudentsData(response.data.results);
        } else {
          setStudentsData((previousData) => [
            ...previousData,
            ...response.data.results,
          ]);
        }

        setTotalPagesStudent(response.data.num_pages);
        if (response.data.results.length === 0) {
          setStudentMessage("Students not found in this class & grade");
        }
      })
      .catch((error) => {
        setStudentDataLoading(false);
        setStudentMessage("Oops! Something went wrong");
      });
  };

  useEffect(() => {
    if (assessmentId) {
      getStudents();
    }
  }, [currentPageStudent, query, assessmentId]);

  useEffect(() => {
    const element = document.getElementById("student-dropdown");
    const handleScroll = () => {
      if (element && currentPageStudent <= totalPagesStudent) {
        const { scrollTop, clientHeight, scrollHeight } = element;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
          setCurrentPageStudent((prevOffset) => prevOffset + 1);
          setStudentDataLoading(true);
        }
      }
    };

    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [getStudents, currentPageStudent]);
  return (
    <div className="relative w-full h-[35px]" ref={dropdownRef}>
      <button
        id="dropdownSearchButton"
        onClick={toggleDropdown}
        className="w-full bg-white outline-none border border-[#DCDCDC] text-[#263238] font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center justify-between hover:border-[#017663] focus-within:border-[#017663]"
        type="button"
      >
        <div className="flex items-center gap-2">
          {selectedStudent?.profile_picture ? (
            <img
              className="w-5 h-5 rounded-full"
              src={`${BASE_URL}/${selectedStudent?.profile_picture}`}
              alt=""
            />
          ) : (
            <img
              className="w-5 h-5 rounded-full"
              src={"/avatar_dummy.png"}
              alt="Jese image"
            />
          )}
          {selectedStudent ? selectedStudent?.name : "Select Student"}
        </div>
        <svg
          className="w-2.5 h-2.5 ms-3"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m1 1 4 4 4-4"
          />
        </svg>
      </button>
      <div
        id="dropdownSearch"
        className={`${
          isOpen ? "block" : "hidden"
        } z-10 bg-white rounded-lg shadow w-full absolute top-[120%] right-0`}
      >
        <div className="p-3">
          <div className="relative">
            <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              id="input-group-search"
              className="block w-full p-2 ps-10 text-sm border border-[#DCDCDC] rounded-lg bg-gray-50 hover:border-[#017663] focus-within:border-[#017663]"
              placeholder="Search"
              onChange={(e) => {
                setQuery(e.target.value);
                setStudentLoading(true);
                setStudentsData([]);
                setCurrentPageStudent(1);
              }}
            />
          </div>
        </div>

        <ul
          className="h-48 py-2 overflow-y-auto"
          aria-labelledby="dropdownSearchButton"
          id="student-dropdown"
        >
          {studentsData?.map((item, index) => (
            <li key={index}>
              <div
                className={`flex items-center gap-2 px-4 py-2 hover:bg-[#ffedee] hover:text-themeColor cursor-pointer ${
                  selectedStudent?.student_id === item.student_id
                    ? "bg-[#ffedee] text-themeColor"
                    : "bg-transparent text-[#1C1D1E]"
                }`}
                onClick={() => {
                  handleSelectChange(item);
                  setIsOpen(false);
                }}
              >
                {item.profile_picture ? (
                  <img
                    className="w-6 h-6 rounded-full"
                    src={`${BASE_URL}/${item.profile_picture}`}
                    alt=""
                  />
                ) : (
                  <img
                    className="w-6 h-6 rounded-full"
                    src={"/avatar_dummy.png"}
                    alt="Jese image"
                  />
                )}
                <p>{item.name}</p>
              </div>
            </li>
          ))}
          {studentLoading && (
            <div className="flex justify-center items-center w-full h-full">
              <ButtonLoader_2 />
            </div>
          )}
          {!studentLoading && studentMessage && (
            <div className="flex justify-center items-center w-full h-full">
              <p className="text-gray-600 text-center w-[70%]">
                {studentMessage}
              </p>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
}

export default StudentsDropdown;
