import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardLayout from "../components/shared/DashboardLayout";
import GlobalContext from "../context/GlobalContext";
import AddGradeModal from "../components/modals/AddGradeModal";
import DeleteModal from "../components/modals/DeleteModal";
import { CardSkelton_9 } from "../components/shared/Skeltons";
import { skelton_data } from "../staticData/SkeltonData";
import ShowMessage from "../components/shared/ShowMessage";
import { HiOutlineDotsVertical } from "react-icons/hi";
import useGetGradesList from "../hooks/useGetGradesList";
import { FaPlus } from "react-icons/fa6";
import AppHead from "../seo/AppHead";

function GradesPage() {
  return (
    <>
      <AppHead title="Grades - ELEMENT EDUCATION" />
      <DashboardLayout>
        <div className="bg-backgroundColor px-5 py-2 flex flex-col w-full h-auto">
          <MainLayout />
        </div>
      </DashboardLayout>
    </>
  );
}

export default GradesPage;

function MainLayout() {
  const { id, name } = useParams();
  const { userInfo, updateResponse } = useContext(GlobalContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showMenuIndex, setShowMenuIndex] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState(null);

  const { getGradesList, gradesListLoading, gradesList, gradesListMessage } =
    useGetGradesList();

  useEffect(() => {
    getGradesList(`?class_id=${id}`);
  }, [id, updateResponse]);

  const handleMenu = (item, e, index) => {
    e.stopPropagation();
    setShowMenuIndex(index);
    setSelectedGrade(item);
  };
  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMenuIndex(null);
        setSelectedGrade(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className=" flex items-center justify-between w-full px-1">
        <div>
          <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[500] lg:text-[30px] font-roboto text-[#263238]">
            {name}
          </h1>
        </div>
        {userInfo?.is_superuser === true && (
          <div className="flex items-center gap-3">
            <div
              className="flex items-center border bg-[#017663] border-none rounded-[6px] px-5 gap-3 min-w-[180px] h-[50px] cursor-pointer text-white"
              onClick={() => setIsOpenModal(true)}
            >
              <FaPlus className="text-[18px] text-white" />
              <p className="2xl:font-[700] 2xl:text-[15px] lg:font-[500] lg:text-[14px]  font-roboto">
                Add New Grade
              </p>
            </div>
          </div>
        )}
      </div>

      {gradesListLoading ? (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mt-5">
          {skelton_data.slice(0, 8).map((_, index) => (
            <CardSkelton_9 key={index} />
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mt-5">
          {gradesList?.map((item, index) => (
            <div key={index}>
              <GradesCard
                key={index}
                item={item}
                index={index}
                show={showMenuIndex === index}
                handleMenu={(e) => handleMenu(item, e, index)}
                setDeleteModal={setDeleteModal}
                setIsEditMode={setIsEditMode}
                setIsOpenModal={setIsOpenModal}
                setShowMenuIndex={setShowMenuIndex}
                dropdownRef={dropdownRef}
              />
            </div>
          ))}
        </div>
      )}
      {!gradesListLoading && gradesListMessage && (
        <div className="w-full h-[50vh] flex justify-center items-center">
          <ShowMessage message={gradesListMessage} />
        </div>
      )}

      <AddGradeModal
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        selectedGrade={selectedGrade}
        setSelectedGrade={setSelectedGrade}
        class_id={id}
      />

      <DeleteModal
        setIsOpenModal={setDeleteModal}
        isOpenModal={deleteModal}
        setSelected={setSelectedGrade}
        url={`/api/grade/?class_id=${id}&grade_id=${selectedGrade?.grade_id}`}
      />
    </>
  );
}

function GradesCard({
  item,
  index,
  show,
  handleMenu,
  setDeleteModal,
  setIsEditMode,
  setIsOpenModal,
  setShowMenuIndex,
  dropdownRef,
}) {
  const { userInfo } = useContext(GlobalContext);
  return (
    <div
      className="w-full bg-[#FFFFFF] rounded-[10px] p-[20px] flex flex-col items-center justify-center relative border border-transparent hover:border-[#017663]"
      key={index}
    >
      <div className="flex flex-col gap-1 items-center justify-center mb-4">
        <span className="2xl:text-[20px] font-[500] lg:text-[15px] font-roboto text-[#1C1D1E]">
          Grade: {item.grade}
        </span>
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col items-center">
          <span className="text-[12px] font-[400] font-roboto text-[#7A7A7A] text-center">
            Total Teachers
          </span>
          <span className="2xl:text-[20px] font-[400] lg:text-[15px] font-roboto text-[#1C1D1E]">
            {item.total_teachers || 0}
          </span>
        </div>
        <div className="border border-[#DCDCDC]"></div>
        <div className="flex flex-col items-center">
          <span className="text-[12px] font-[400] font-roboto text-[#7A7A7A] text-center">
            Total Students
          </span>
          <span className="2xl:text-[20px] font-[400] lg:text-[15px] font-roboto text-[#1C1D1E]">
            {item.total_students || 0}
          </span>
        </div>
        <div className="border border-[#DCDCDC]"></div>

        <div className="flex flex-col items-center">
          <span className="text-[12px] font-[400] font-roboto text-[#7A7A7A] text-center">
            Total Assessments
          </span>
          <span className="2xl:text-[20px] font-[400] lg:text-[15px] font-roboto text-[#1C1D1E]">
            {item.total_assessments || 0}
          </span>
        </div>
      </div>
      {userInfo?.is_superuser === true && (
        <div className="absolute right-1 top-1 p-2 cursor-pointer">
          <HiOutlineDotsVertical
            className="text-[20px] text-[#7A7A7A] z-40"
            onClick={handleMenu}
          />
        </div>
      )}
      {show && (
        <DropMenu
          setDeleteModal={setDeleteModal}
          setIsEditMode={setIsEditMode}
          setIsOpenModal={setIsOpenModal}
          setShowMenuIndex={setShowMenuIndex}
          dropdownRef={dropdownRef}
        />
      )}
    </div>
  );
}

function DropMenu({
  setDeleteModal,
  setIsEditMode,
  setIsOpenModal,
  setShowMenuIndex,
  dropdownRef,
}) {
  return (
    <div
      className="w-[80px] rounded border border-[#DCDCDC] bg-[#F9F9FF] p-1 absolute right-[2%] top-[15%]  flex flex-col z-[8888] "
      ref={dropdownRef}
    >
      <span
        className=" font-[400] text-[13px] font-roboto text-[#1C1D1E]  hover:bg-[#f09ea073] rounded px-2 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setDeleteModal(true);
          setShowMenuIndex(null);
        }}
      >
        Remove
      </span>
      {/* <span
        className=" font-[400] text-[13px] font-roboto text-[#1C1D1E]  hover:bg-[#f09ea073] rounded px-2 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setIsEditMode(true);
          setIsOpenModal(true);
          setShowMenuIndex(null);
        }}
      >
        Edit
      </span> */}
    </div>
  );
}
