import axios from "axios";
import { useContext, useState } from "react";
import GlobalContext from "../context/GlobalContext";
import useSessionStorage from "./useSessionStorage";
import { getAccessToken } from "../storage/storage";

const useGetClassList = () => {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [classList, setClassList] = useState(null);
  const [classesLoading, setClassesLoading] = useState(true);
  const [classesMessage, setClassesMessage] = useState(null);
  const [totalPagesClasses, setTotalPagesClasses] = useState();
  const [currentPageClasses, setCurrentPageClasses] = useSessionStorage(
    "currentPageClasses",
    1
  );
  const { setUpdateResponse } = useContext(GlobalContext);

  const GetClassList = async (filter) => {
    setClassesMessage(null);
    await axios
      .get(`${BASE_URL}/api/class/list${filter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setClassesLoading(false);
        setClassList(response.data.results);
        setUpdateResponse(false);
        setTotalPagesClasses(response.data?.num_pages);
        if (response.data.results.length === 0) {
          setClassesMessage("No classes found");
        } else {
          setClassesMessage(null);
        }
      })
      .catch((error) => {
        setClassesLoading(false);
        setClassesMessage("Oops! Something went wrong");
      });
  };
  return {
    classesLoading,
    GetClassList,
    classList,
    classesMessage,
    totalPagesClasses,
    currentPageClasses,
    setTotalPagesClasses,
    setCurrentPageClasses,
    setClassList,
  };
};
export default useGetClassList;
