export const assessments = [
  //  For Fall grade 0
  {
    grade_check: "0",
    title: "Parts A&B",
    checked: true,
    term: "Fall",
    assessment_data: [
      {
        name: "A - Letter Names - Uppercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "D",
          "A",
          "N",
          "S",
          "X",
          "Z",
          "J",
          "L",
          "H",
          "T",
          "Y",
          "E",
          "C",
          "O",
          "M",
          "R",
          "P",
          "W",
          "K",
          "U",
          "G",
          "B",
          "F",
          "Q",
          "V",
          "I"
        ]
      },
      {
        name: "B - Letter Names - Lowercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "a",
          "n",
          "s",
          "x",
          "z",
          "j",
          "l",
          "h",
          "t",
          "y",
          "e",
          "c",
          "o",
          "m",
          "r",
          "p",
          "w",
          "k",
          "u",
          "g",
          "b",
          "f",
          "q",
          "v",
          "i"
        ]
      }
    ]
  },
  // for Winter Grade 0
  {
    grade_check: "0",
    title: "Parts A-E",
    checked: true,
    term: "Winter",
    assessment_data: [
      {
        name: "A - Letter Names - Uppercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "D",
          "A",
          "N",
          "S",
          "X",
          "Z",
          "J",
          "L",
          "H",
          "T",
          "Y",
          "E",
          "C",
          "O",
          "M",
          "R",
          "P",
          "W",
          "K",
          "U",
          "G",
          "B",
          "F",
          "Q",
          "V",
          "I"
        ]
      },
      {
        name: "B - Letter Names - Lowercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "a",
          "n",
          "s",
          "x",
          "z",
          "j",
          "l",
          "h",
          "t",
          "y",
          "e",
          "c",
          "o",
          "m",
          "r",
          "p",
          "w",
          "k",
          "u",
          "g",
          "b",
          "f",
          "q",
          "v",
          "i"
        ]
      },
      {
        name: "C - Consonant Sounds",
        total_score: 21,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "l",
          "n",
          "s",
          "x",
          "z",
          "j",
          "t",
          "y",
          "p",
          "c",
          "h",
          "m",
          "r",
          "k",
          "w",
          "g",
          "b",
          "f",
          "q",
          "v"
        ]
      },
      {
        name: "D - Long Vowel Sounds - Short Vowel Sounds",
        total_score: 10,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: ["E", "e", "I", "i", "A", "a", "O", "o", "U", "u"]
      },
      // {
      //  name: "D - Short Vowel Sounds",
      //   total_score: 5,
      //   skill_to_review: "",
      //   skill_to_teach: "",
      //   headings: [],
      //   columns: 9,
      //   category: "Alphabetic Skills & Letter Sounds",
      //   terms: [
      //     { checked: true, name: "Fall" },
      //     { checked: true, name: "Winter" },
      //     { checked: true, name: "Spring" }
      //   ],
      //   counts: ["e", "i", "a", "o", "u"]
      // },
      {
        name: "E - Short Vowels in CVC Words",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "sip",
          "mat",
          "let",
          "bun",
          "hog",
          "rut",
          "fit",
          "bat",
          "hot",
          "set",
          "nop",
          "sut",
          "dit",
          "pem",
          "fap"
        ]
      }
    ]
  },
  // for Spring Grade 0
  {
    grade_check: "0",
    title: "Parts A-E",
    checked: true,
    term: "Spring",
    assessment_data: [
      {
        name: "A - Letter Names - Uppercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "D",
          "A",
          "N",
          "S",
          "X",
          "Z",
          "J",
          "L",
          "H",
          "T",
          "Y",
          "E",
          "C",
          "O",
          "M",
          "R",
          "P",
          "W",
          "K",
          "U",
          "G",
          "B",
          "F",
          "Q",
          "V",
          "I"
        ]
      },
      {
        name: "B - Letter Names - Lowercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "a",
          "n",
          "s",
          "x",
          "z",
          "j",
          "l",
          "h",
          "t",
          "y",
          "e",
          "c",
          "o",
          "m",
          "r",
          "p",
          "w",
          "k",
          "u",
          "g",
          "b",
          "f",
          "q",
          "v",
          "i"
        ]
      },
      {
        name: "C - Consonant Sounds",
        total_score: 21,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "l",
          "n",
          "s",
          "x",
          "z",
          "j",
          "t",
          "y",
          "p",
          "c",
          "h",
          "m",
          "r",
          "k",
          "w",
          "g",
          "b",
          "f",
          "q",
          "v"
        ]
      },
      {
        name: "D - Long Vowel Sounds - Short Vowel Sounds",
        total_score: 10,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: ["E", "e", "I", "i", "A", "a", "O", "o", "U", "u"]
      },
      // {
      //  name: "D - Short Vowel Sounds",
      //   total_score: 5,
      //   skill_to_review: "",
      //   skill_to_teach: "",
      //   headings: [],
      //   columns: 9,
      //   category: "Alphabetic Skills & Letter Sounds",
      //   terms: [
      //     { checked: true, name: "Fall" },
      //     { checked: true, name: "Winter" },
      //     { checked: true, name: "Spring" }
      //   ],
      //   counts: ["e", "i", "a", "o", "u"]
      // },
      {
        name: "E - Short Vowels in CVC Words",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "sip",
          "mat",
          "let",
          "bun",
          "hog",
          "rut",
          "fit",
          "bat",
          "hot",
          "set",
          "nop",
          "sut",
          "dit",
          "pem",
          "fap"
        ]
      }
    ]
  },
  // for fall grade 1
  {
    grade_check: "1",
    title: "Parts A-E",
    checked: true,
    term: "Fall",
    assessment_data: [
      {
        name: "A - Letter Names - Uppercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "D",
          "A",
          "N",
          "S",
          "X",
          "Z",
          "J",
          "L",
          "H",
          "T",
          "Y",
          "E",
          "C",
          "O",
          "M",
          "R",
          "P",
          "W",
          "K",
          "U",
          "G",
          "B",
          "F",
          "Q",
          "V",
          "I"
        ]
      },
      {
        name: "B - Letter Names - Lowercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "a",
          "n",
          "s",
          "x",
          "z",
          "j",
          "l",
          "h",
          "t",
          "y",
          "e",
          "c",
          "o",
          "m",
          "r",
          "p",
          "w",
          "k",
          "u",
          "g",
          "b",
          "f",
          "q",
          "v",
          "i"
        ]
      },
      {
        name: "C - Consonant Sounds",
        total_score: 21,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "l",
          "n",
          "s",
          "x",
          "z",
          "j",
          "t",
          "y",
          "p",
          "c",
          "h",
          "m",
          "r",
          "k",
          "w",
          "g",
          "b",
          "f",
          "q",
          "v"
        ]
      },
      {
        name: "D - Long Vowel Sounds - Short Vowel Sounds",
        total_score: 10,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: ["E", "e", "I", "i", "A", "a", "O", "o", "U", "u"]
      },
      // {
      //   name: "D - Short Vowel Sounds",
      //   total_score: 5,
      //   skill_to_review: "",
      //   skill_to_teach: "",
      //   headings: [],
      //   columns: 9,
      //   category: "Alphabetic Skills & Letter Sounds",

      //   counts: ["e", "i", "a", "o", "u"]
      // },

      {
        name: "E - Short Vowels in CVC Words",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "sip",
          "mat",
          "let",
          "bun",
          "hog",
          "rut",
          "fit",
          "bat",
          "hot",
          "set",
          "nop",
          "sut",
          "dit",
          "pem",
          "fap"
        ]
      }
    ]
  },
  // for winter grade 1
  {
    grade_check: "1",
    title: "Parts A-K",
    checked: true,
    term: "Winter",
    assessment_data: [
      {
        name: "A - Letter Names - Uppercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "D",
          "A",
          "N",
          "S",
          "X",
          "Z",
          "J",
          "L",
          "H",
          "T",
          "Y",
          "E",
          "C",
          "O",
          "M",
          "R",
          "P",
          "W",
          "K",
          "U",
          "G",
          "B",
          "F",
          "Q",
          "V",
          "I"
        ]
      },
      {
        name: "B - Letter Names - Lowercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "a",
          "n",
          "s",
          "x",
          "z",
          "j",
          "l",
          "h",
          "t",
          "y",
          "e",
          "c",
          "o",
          "m",
          "r",
          "p",
          "w",
          "k",
          "u",
          "g",
          "b",
          "f",
          "q",
          "v",
          "i"
        ]
      },
      {
        name: "C - Consonant Sounds",
        total_score: 21,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "l",
          "n",
          "s",
          "x",
          "z",
          "j",
          "t",
          "y",
          "p",
          "c",
          "h",
          "m",
          "r",
          "k",
          "w",
          "g",
          "b",
          "f",
          "q",
          "v"
        ]
      },
      {
        name: "D - Long Vowel Sounds - Short Vowel Sounds",
        total_score: 10,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: ["E", "e", "I", "i", "A", "a", "O", "o", "U", "u"]
      },
      // {
      //   name: "D - Short Vowel Sounds",
      //   total_score: 5,
      //   skill_to_review: "",
      //   skill_to_teach: "",
      //   headings: [],
      //   columns: 9,
      //   category: "Alphabetic Skills & Letter Sounds",

      //   counts: ["e", "i", "a", "o", "u"]
      // },

      {
        name: "E - Short Vowels in CVC Words",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "sip",
          "mat",
          "let",
          "bun",
          "hog",
          "rut",
          "fit",
          "bat",
          "hot",
          "set",
          "nop",
          "sut",
          "dit",
          "pem",
          "fap"
        ]
      },
      {
        name: "F - Short Vowels and Consonant Blends",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "stop",
          "trap",
          "quit",
          "spell",
          "plan",
          "silk",
          "fast",
          "sank",
          "lump",
          "held",
          "nask",
          "dilt",
          "qued",
          "cang",
          "dran"
        ]
      },
      {
        name: "G - Short Vowels, Digraphs, and –tch Trigraph",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "when",
          "chop",
          "thin",
          "shut",
          "wick",
          "dodge",
          "rash",
          "ring",
          "then",
          "match",
          "chid",
          "shom",
          "dath",
          "phid",
          "futch"
        ]
      },
      {
        name: "H - R–Controlled Vowels",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "harm",
          "dirt",
          "form",
          "fern",
          "surf",
          "worn",
          "pert",
          "bark",
          "turn",
          "bird",
          "nerm",
          "sirt",
          "gorf",
          "murd",
          "carn"
        ]
      },

      {
        name: "I - Long Vowels Spellings",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "tape",
          "key",
          "toe",
          "paid",
          "feet",
          "leap",
          "boat",
          "tie",
          "ray",
          "blow",
          "loe",
          "hine",
          "beap",
          "faim",
          "soat"
        ]
      },
      {
        name: "J - Variant Vowels",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "few",
          "down",
          "moon",
          "hawk",
          "coin",
          "cue",
          "loud",
          "cook",
          "haunt",
          "toy",
          "voot",
          "rew",
          "fout",
          "zoy",
          "bawk"
        ]
      },
      {
        name: "K - Low Frequency Vowel and Consonant Spellings",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "kneel",
          "cent",
          "type",
          "ghost",
          "wrist",
          "giant",
          "sweat",
          "gnat",
          "bomb",
          "sigh",
          "bice",
          "knod",
          "dimb",
          "tigh",
          "wrep"
        ]
      }
    ]
  },
  // for spring grade 1
  {
    grade_check: "1",
    title: "Parts A-L",
    checked: true,
    term: "Spring",
    assessment_data: [
      {
        name: "A - Letter Names - Uppercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "D",
          "A",
          "N",
          "S",
          "X",
          "Z",
          "J",
          "L",
          "H",
          "T",
          "Y",
          "E",
          "C",
          "O",
          "M",
          "R",
          "P",
          "W",
          "K",
          "U",
          "G",
          "B",
          "F",
          "Q",
          "V",
          "I"
        ]
      },
      {
        name: "B - Letter Names - Lowercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "a",
          "n",
          "s",
          "x",
          "z",
          "j",
          "l",
          "h",
          "t",
          "y",
          "e",
          "c",
          "o",
          "m",
          "r",
          "p",
          "w",
          "k",
          "u",
          "g",
          "b",
          "f",
          "q",
          "v",
          "i"
        ]
      },
      {
        name: "C - Consonant Sounds",
        total_score: 21,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "l",
          "n",
          "s",
          "x",
          "z",
          "j",
          "t",
          "y",
          "p",
          "c",
          "h",
          "m",
          "r",
          "k",
          "w",
          "g",
          "b",
          "f",
          "q",
          "v"
        ]
      },
      {
        name: "D - Long Vowel Sounds - Short Vowel Sounds",
        total_score: 10,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: ["E", "e", "I", "i", "A", "a", "O", "o", "U", "u"]
      },
      // {
      //   name: "D - Short Vowel Sounds",
      //   total_score: 5,
      //   skill_to_review: "",
      //   skill_to_teach: "",
      //   headings: [],
      //   columns: 9,
      //   category: "Alphabetic Skills & Letter Sounds",

      //   counts: ["e", "i", "a", "o", "u"]
      // },

      {
        name: "E - Short Vowels in CVC Words",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "sip",
          "mat",
          "let",
          "bun",
          "hog",
          "rut",
          "fit",
          "bat",
          "hot",
          "set",
          "nop",
          "sut",
          "dit",
          "pem",
          "fap"
        ]
      },
      {
        name: "F - Short Vowels and Consonant Blends",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "stop",
          "trap",
          "quit",
          "spell",
          "plan",
          "silk",
          "fast",
          "sank",
          "lump",
          "held",
          "nask",
          "dilt",
          "qued",
          "cang",
          "dran"
        ]
      },
      {
        name: "G - Short Vowels, Digraphs, and –tch Trigraph",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "when",
          "chop",
          "thin",
          "shut",
          "wick",
          "dodge",
          "rash",
          "ring",
          "then",
          "match",
          "chid",
          "shom",
          "dath",
          "phid",
          "futch"
        ]
      },
      {
        name: "H - R–Controlled Vowels",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "harm",
          "dirt",
          "form",
          "fern",
          "surf",
          "worn",
          "pert",
          "bark",
          "turn",
          "bird",
          "nerm",
          "sirt",
          "gorf",
          "murd",
          "carn"
        ]
      },

      {
        name: "I - Long Vowels Spellings",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "tape",
          "key",
          "toe",
          "paid",
          "feet",
          "leap",
          "boat",
          "tie",
          "ray",
          "blow",
          "loe",
          "hine",
          "beap",
          "faim",
          "soat"
        ]
      },
      {
        name: "J - Variant Vowels",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "few",
          "down",
          "moon",
          "hawk",
          "coin",
          "cue",
          "loud",
          "cook",
          "haunt",
          "toy",
          "voot",
          "rew",
          "fout",
          "zoy",
          "bawk"
        ]
      },
      {
        name: "K - Low Frequency Vowel and Consonant Spellings",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "kneel",
          "cent",
          "type",
          "ghost",
          "wrist",
          "giant",
          "sweat",
          "gnat",
          "bomb",
          "sigh",
          "bice",
          "knod",
          "dimb",
          "tigh",
          "wrep"
        ]
      },
      {
        name: "L - Multisyllabic words",
        total_score: 24,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [
          "Closed-closed",
          "Closed silent e",
          "Open/closed-other",
          "Open or closed",
          "Silent e",
          "Consonant –le",
          "r-Controlled",
          "Vowel team"
        ],
        columns: 3,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "unless",
          "consent",
          "timbut",
          "competes",
          "admire",
          "rompete",
          "depend",
          "radishes",
          "podated",
          "zero",
          "menu",
          "gromu",
          "locate",
          "inhaled",
          "pentate",
          "stable",
          "dimple",
          "morkle",
          "further",
          "bordered",
          "darber",
          "railways",
          "roaring",
          "fauntoon"
        ]
      }
    ]
  },
  // for fall grade 2
  {
    grade_check: "2",
    title: "Parts A-L",
    checked: true,
    term: "Fall",
    assessment_data: [
      {
        name: "A - Letter Names - Uppercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "D",
          "A",
          "N",
          "S",
          "X",
          "Z",
          "J",
          "L",
          "H",
          "T",
          "Y",
          "E",
          "C",
          "O",
          "M",
          "R",
          "P",
          "W",
          "K",
          "U",
          "G",
          "B",
          "F",
          "Q",
          "V",
          "I"
        ]
      },
      {
        name: "B - Letter Names - Lowercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "a",
          "n",
          "s",
          "x",
          "z",
          "j",
          "l",
          "h",
          "t",
          "y",
          "e",
          "c",
          "o",
          "m",
          "r",
          "p",
          "w",
          "k",
          "u",
          "g",
          "b",
          "f",
          "q",
          "v",
          "i"
        ]
      },
      {
        name: "C - Consonant Sounds",
        total_score: 21,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "l",
          "n",
          "s",
          "x",
          "z",
          "j",
          "t",
          "y",
          "p",
          "c",
          "h",
          "m",
          "r",
          "k",
          "w",
          "g",
          "b",
          "f",
          "q",
          "v"
        ]
      },
      {
        name: "D - Long Vowel Sounds - Short Vowel Sounds",
        total_score: 10,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: ["E", "e", "I", "i", "A", "a", "O", "o", "U", "u"]
      },
      // {
      //   name: "D - Short Vowel Sounds",
      //   total_score: 5,
      //   skill_to_review: "",
      //   skill_to_teach: "",
      //   headings: [],
      //   columns: 9,
      //   category: "Alphabetic Skills & Letter Sounds",

      //   counts: ["e", "i", "a", "o", "u"]
      // },

      {
        name: "E - Short Vowels in CVC Words",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "sip",
          "mat",
          "let",
          "bun",
          "hog",
          "rut",
          "fit",
          "bat",
          "hot",
          "set",
          "nop",
          "sut",
          "dit",
          "pem",
          "fap"
        ]
      },
      {
        name: "F - Short Vowels and Consonant Blends",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "stop",
          "trap",
          "quit",
          "spell",
          "plan",
          "silk",
          "fast",
          "sank",
          "lump",
          "held",
          "nask",
          "dilt",
          "qued",
          "cang",
          "dran"
        ]
      },
      {
        name: "G - Short Vowels, Digraphs, and –tch Trigraph",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "when",
          "chop",
          "thin",
          "shut",
          "wick",
          "dodge",
          "rash",
          "ring",
          "then",
          "match",
          "chid",
          "shom",
          "dath",
          "phid",
          "futch"
        ]
      },
      {
        name: "H - R–Controlled Vowels",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "harm",
          "dirt",
          "form",
          "fern",
          "surf",
          "worn",
          "pert",
          "bark",
          "turn",
          "bird",
          "nerm",
          "sirt",
          "gorf",
          "murd",
          "carn"
        ]
      },

      {
        name: "I - Long Vowels Spellings",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "tape",
          "key",
          "toe",
          "paid",
          "feet",
          "leap",
          "boat",
          "tie",
          "ray",
          "blow",
          "loe",
          "hine",
          "beap",
          "faim",
          "soat"
        ]
      },
      {
        name: "J - Variant Vowels",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "few",
          "down",
          "moon",
          "hawk",
          "coin",
          "cue",
          "loud",
          "cook",
          "haunt",
          "toy",
          "voot",
          "rew",
          "fout",
          "zoy",
          "bawk"
        ]
      },
      {
        name: "K - Low Frequency Vowel and Consonant Spellings",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "kneel",
          "cent",
          "type",
          "ghost",
          "wrist",
          "giant",
          "sweat",
          "gnat",
          "bomb",
          "sigh",
          "bice",
          "knod",
          "dimb",
          "tigh",
          "wrep"
        ]
      },
      {
        name: "L - Multisyllabic words",
        total_score: 24,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [
          "Closed-closed",
          "Closed silent e",
          "Open/closed-other",
          "Open or closed",
          "Silent e",
          "Consonant –le",
          "r-Controlled",
          "Vowel team"
        ],
        columns: 3,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "unless",
          "consent",
          "timbut",
          "competes",
          "admire",
          "rompete",
          "depend",
          "radishes",
          "podated",
          "zero",
          "menu",
          "gromu",
          "locate",
          "inhaled",
          "pentate",
          "stable",
          "dimple",
          "morkle",
          "further",
          "bordered",
          "darber",
          "railways",
          "roaring",
          "fauntoon"
        ]
      }
    ]
  },
  // for Winter grade 2
  {
    grade_check: "2",
    title: "Parts A-L",
    checked: true,
    term: "Winter",
    assessment_data: [
      {
        name: "A - Letter Names - Uppercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "D",
          "A",
          "N",
          "S",
          "X",
          "Z",
          "J",
          "L",
          "H",
          "T",
          "Y",
          "E",
          "C",
          "O",
          "M",
          "R",
          "P",
          "W",
          "K",
          "U",
          "G",
          "B",
          "F",
          "Q",
          "V",
          "I"
        ]
      },
      {
        name: "B - Letter Names - Lowercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "a",
          "n",
          "s",
          "x",
          "z",
          "j",
          "l",
          "h",
          "t",
          "y",
          "e",
          "c",
          "o",
          "m",
          "r",
          "p",
          "w",
          "k",
          "u",
          "g",
          "b",
          "f",
          "q",
          "v",
          "i"
        ]
      },
      {
        name: "C - Consonant Sounds",
        total_score: 21,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "l",
          "n",
          "s",
          "x",
          "z",
          "j",
          "t",
          "y",
          "p",
          "c",
          "h",
          "m",
          "r",
          "k",
          "w",
          "g",
          "b",
          "f",
          "q",
          "v"
        ]
      },
      {
        name: "D - Long Vowel Sounds - Short Vowel Sounds",
        total_score: 10,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: ["E", "e", "I", "i", "A", "a", "O", "o", "U", "u"]
      },
      // {
      //   name: "D - Short Vowel Sounds",
      //   total_score: 5,
      //   skill_to_review: "",
      //   skill_to_teach: "",
      //   headings: [],
      //   columns: 9,
      //   category: "Alphabetic Skills & Letter Sounds",

      //   counts: ["e", "i", "a", "o", "u"]
      // },

      {
        name: "E - Short Vowels in CVC Words",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "sip",
          "mat",
          "let",
          "bun",
          "hog",
          "rut",
          "fit",
          "bat",
          "hot",
          "set",
          "nop",
          "sut",
          "dit",
          "pem",
          "fap"
        ]
      },
      {
        name: "F - Short Vowels and Consonant Blends",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "stop",
          "trap",
          "quit",
          "spell",
          "plan",
          "silk",
          "fast",
          "sank",
          "lump",
          "held",
          "nask",
          "dilt",
          "qued",
          "cang",
          "dran"
        ]
      },
      {
        name: "G - Short Vowels, Digraphs, and –tch Trigraph",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "when",
          "chop",
          "thin",
          "shut",
          "wick",
          "dodge",
          "rash",
          "ring",
          "then",
          "match",
          "chid",
          "shom",
          "dath",
          "phid",
          "futch"
        ]
      },
      {
        name: "H - R–Controlled Vowels",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "harm",
          "dirt",
          "form",
          "fern",
          "surf",
          "worn",
          "pert",
          "bark",
          "turn",
          "bird",
          "nerm",
          "sirt",
          "gorf",
          "murd",
          "carn"
        ]
      },

      {
        name: "I - Long Vowels Spellings",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "tape",
          "key",
          "toe",
          "paid",
          "feet",
          "leap",
          "boat",
          "tie",
          "ray",
          "blow",
          "loe",
          "hine",
          "beap",
          "faim",
          "soat"
        ]
      },
      {
        name: "J - Variant Vowels",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "few",
          "down",
          "moon",
          "hawk",
          "coin",
          "cue",
          "loud",
          "cook",
          "haunt",
          "toy",
          "voot",
          "rew",
          "fout",
          "zoy",
          "bawk"
        ]
      },
      {
        name: "K - Low Frequency Vowel and Consonant Spellings",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "kneel",
          "cent",
          "type",
          "ghost",
          "wrist",
          "giant",
          "sweat",
          "gnat",
          "bomb",
          "sigh",
          "bice",
          "knod",
          "dimb",
          "tigh",
          "wrep"
        ]
      },
      {
        name: "L - Multisyllabic words",
        total_score: 24,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [
          "Closed-closed",
          "Closed silent e",
          "Open/closed-other",
          "Open or closed",
          "Silent e",
          "Consonant –le",
          "r-Controlled",
          "Vowel team"
        ],
        columns: 3,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "unless",
          "consent",
          "timbut",
          "competes",
          "admire",
          "rompete",
          "depend",
          "radishes",
          "podated",
          "zero",
          "menu",
          "gromu",
          "locate",
          "inhaled",
          "pentate",
          "stable",
          "dimple",
          "morkle",
          "further",
          "bordered",
          "darber",
          "railways",
          "roaring",
          "fauntoon"
        ]
      }
    ]
  },
  // for Spring grade 2
  {
    grade_check: "2",
    title: "Parts A-L",
    checked: true,
    term: "Spring",
    assessment_data: [
      {
        name: "A - Letter Names - Uppercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "D",
          "A",
          "N",
          "S",
          "X",
          "Z",
          "J",
          "L",
          "H",
          "T",
          "Y",
          "E",
          "C",
          "O",
          "M",
          "R",
          "P",
          "W",
          "K",
          "U",
          "G",
          "B",
          "F",
          "Q",
          "V",
          "I"
        ]
      },
      {
        name: "B - Letter Names - Lowercase Sounds",
        total_score: 26,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "a",
          "n",
          "s",
          "x",
          "z",
          "j",
          "l",
          "h",
          "t",
          "y",
          "e",
          "c",
          "o",
          "m",
          "r",
          "p",
          "w",
          "k",
          "u",
          "g",
          "b",
          "f",
          "q",
          "v",
          "i"
        ]
      },
      {
        name: "C - Consonant Sounds",
        total_score: 21,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "d",
          "l",
          "n",
          "s",
          "x",
          "z",
          "j",
          "t",
          "y",
          "p",
          "c",
          "h",
          "m",
          "r",
          "k",
          "w",
          "g",
          "b",
          "f",
          "q",
          "v"
        ]
      },
      {
        name: "D - Long Vowel Sounds - Short Vowel Sounds",
        total_score: 10,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [],
        columns: 9,
        category: "Alphabetic Skills & Letter Sounds",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: ["E", "e", "I", "i", "A", "a", "O", "o", "U", "u"]
      },
      // {
      //   name: "D - Short Vowel Sounds",
      //   total_score: 5,
      //   skill_to_review: "",
      //   skill_to_teach: "",
      //   headings: [],
      //   columns: 9,
      //   category: "Alphabetic Skills & Letter Sounds",

      //   counts: ["e", "i", "a", "o", "u"]
      // },

      {
        name: "E - Short Vowels in CVC Words",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "sip",
          "mat",
          "let",
          "bun",
          "hog",
          "rut",
          "fit",
          "bat",
          "hot",
          "set",
          "nop",
          "sut",
          "dit",
          "pem",
          "fap"
        ]
      },
      {
        name: "F - Short Vowels and Consonant Blends",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "stop",
          "trap",
          "quit",
          "spell",
          "plan",
          "silk",
          "fast",
          "sank",
          "lump",
          "held",
          "nask",
          "dilt",
          "qued",
          "cang",
          "dran"
        ]
      },
      {
        name: "G - Short Vowels, Digraphs, and –tch Trigraph",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "when",
          "chop",
          "thin",
          "shut",
          "wick",
          "dodge",
          "rash",
          "ring",
          "then",
          "match",
          "chid",
          "shom",
          "dath",
          "phid",
          "futch"
        ]
      },
      {
        name: "H - R–Controlled Vowels",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "harm",
          "dirt",
          "form",
          "fern",
          "surf",
          "worn",
          "pert",
          "bark",
          "turn",
          "bird",
          "nerm",
          "sirt",
          "gorf",
          "murd",
          "carn"
        ]
      },

      {
        name: "I - Long Vowels Spellings",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "tape",
          "key",
          "toe",
          "paid",
          "feet",
          "leap",
          "boat",
          "tie",
          "ray",
          "blow",
          "loe",
          "hine",
          "beap",
          "faim",
          "soat"
        ]
      },
      {
        name: "J - Variant Vowels",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "few",
          "down",
          "moon",
          "hawk",
          "coin",
          "cue",
          "loud",
          "cook",
          "haunt",
          "toy",
          "voot",
          "rew",
          "fout",
          "zoy",
          "bawk"
        ]
      },
      {
        name: "K - Low Frequency Vowel and Consonant Spellings",
        total_score: 15,
        skill_to_review: "",
        skill_to_teach: "",
        headings: ["Real", "Real", "pseudo"],
        columns: 5,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "kneel",
          "cent",
          "type",
          "ghost",
          "wrist",
          "giant",
          "sweat",
          "gnat",
          "bomb",
          "sigh",
          "bice",
          "knod",
          "dimb",
          "tigh",
          "wrep"
        ]
      },
      {
        name: "L - Multisyllabic words",
        total_score: 24,
        skill_to_review: "",
        skill_to_teach: "",
        headings: [
          "Closed-closed",
          "Closed silent e",
          "Open/closed-other",
          "Open or closed",
          "Silent e",
          "Consonant –le",
          "r-Controlled",
          "Vowel team"
        ],
        columns: 3,
        category: "Reading & Decoding Skills",
        terms: [
          { checked: true, name: "Fall" },
          { checked: true, name: "Winter" },
          { checked: true, name: "Spring" }
        ],
        counts: [
          "unless",
          "consent",
          "timbut",
          "competes",
          "admire",
          "rompete",
          "depend",
          "radishes",
          "podated",
          "zero",
          "menu",
          "gromu",
          "locate",
          "inhaled",
          "pentate",
          "stable",
          "dimple",
          "morkle",
          "further",
          "bordered",
          "darber",
          "railways",
          "roaring",
          "fauntoon"
        ]
      }
    ]
  }

  // {
  //   grade_check: "2",
  //   title: "Parts A-K",
  //   checked: true,
  //   assessment_data: [
  //     {
  //       name: "A - Letter Names - Uppercase Sounds",
  //       total_score: 26,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: [],
  //       columns: 9,
  //       category: "Alphabetic Skills & Letter Sounds",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "D",
  //         "A",
  //         "N",
  //         "S",
  //         "X",
  //         "Z",
  //         "J",
  //         "L",
  //         "H",
  //         "T",
  //         "Y",
  //         "E",
  //         "C",
  //         "O",
  //         "M",
  //         "R",
  //         "P",
  //         "W",
  //         "K",
  //         "U",
  //         "G",
  //         "B",
  //         "F",
  //         "Q",
  //         "V",
  //         "I",
  //       ],
  //     },
  //     {
  //       name: "B - Letter Names - Lowercase Sounds",
  //       total_score: 26,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: [],
  //       columns: 9,
  //       category: "Alphabetic Skills & Letter Sounds",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "d",
  //         "a",
  //         "n",
  //         "s",
  //         "x",
  //         "z",
  //         "j",
  //         "l",
  //         "h",
  //         "t",
  //         "y",
  //         "e",
  //         "c",
  //         "o",
  //         "m",
  //         "r",
  //         "p",
  //         "w",
  //         "k",
  //         "u",
  //         "g",
  //         "b",
  //         "f",
  //         "q",
  //         "v",
  //         "i",
  //       ],
  //     },
  //     {
  //       name: "C - Consonant Sounds",
  //       total_score: 21,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: [],
  //       columns: 9,
  //       category: "Alphabetic Skills & Letter Sounds",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "d",
  //         "l",
  //         "n",
  //         "s",
  //         "x",
  //         "z",
  //         "j",
  //         "t",
  //         "y",
  //         "p",
  //         "c",
  //         "h",
  //         "m",
  //         "r",
  //         "k",
  //         "w",
  //         "g",
  //         "b",
  //         "f",
  //         "q",
  //         "v",
  //       ],
  //     },
  //     {
  //       name: "D - Long Vowel Sounds",
  //       total_score: 5,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: [],
  //       columns: 9,
  //       category: "Alphabetic Skills & Letter Sounds",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: ["E", "I", "A", "O", "U"],
  //     },
  //     {
  //       name: "D - Short Vowel Sounds",
  //       total_score: 5,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: [],
  //       columns: 9,
  //       category: "Alphabetic Skills & Letter Sounds",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: ["e", "i", "a", "o", "u"],
  //     },
  //     {
  //       name: "E - Short Vowels in CVC Words",
  //       total_score: 15,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: ["Real", "Real", "pseudo"],
  //       columns: 5,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "sip",
  //         "mat",
  //         "let",
  //         "bun",
  //         "hog",
  //         "rut",
  //         "fit",
  //         "bat",
  //         "hot",
  //         "set",
  //         "nop",
  //         "sut",
  //         "dit",
  //         "pem",
  //         "fap",
  //       ],
  //     },
  //     {
  //       name: "F - Short Vowels and Consonant Blends",
  //       total_score: 15,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: ["Real", "Real", "pseudo"],
  //       columns: 5,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "stop",
  //         "trap",
  //         "quit",
  //         "spell",
  //         "plan",
  //         "silk",
  //         "fast",
  //         "sank",
  //         "lump",
  //         "held",
  //         "nask",
  //         "dilt",
  //         "qued",
  //         "cang",
  //         "dran",
  //       ],
  //     },
  //     {
  //       name: "G - Short Vowels, Digraphs, and –tch Trigraph",
  //       total_score: 15,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: ["Real", "Real", "pseudo"],
  //       columns: 5,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "when",
  //         "chop",
  //         "thin",
  //         "shut",
  //         "wick",
  //         "dodge",
  //         "rash",
  //         "ring",
  //         "then",
  //         "match",
  //         "chid",
  //         "shom",
  //         "dath",
  //         "phid",
  //         "futch",
  //       ],
  //     },
  //     {
  //       name: "H - R–Controlled Vowels",
  //       total_score: 15,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: ["Real", "Real", "pseudo"],
  //       columns: 5,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "harm",
  //         "dirt",
  //         "form",
  //         "fern",
  //         "surf",
  //         "worn",
  //         "pert",
  //         "bark",
  //         "turn",
  //         "bird",
  //         "nerm",
  //         "sirt",
  //         "gorf",
  //         "murd",
  //         "carn",
  //       ],
  //     },
  //     {
  //       name: "I - Long Vowels Spellings",
  //       total_score: 15,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: ["Real", "Real", "pseudo"],
  //       columns: 5,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "tape",
  //         "key",
  //         "toe",
  //         "paid",
  //         "feet",
  //         "leap",
  //         "boat",
  //         "tie",
  //         "ray",
  //         "blow",
  //         "loe",
  //         "hine",
  //         "beap",
  //         "faim",
  //         "soat",
  //       ],
  //     },
  //     {
  //       name: "J - Variant Vowels",
  //       total_score: 15,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: ["Real", "Real", "pseudo"],
  //       columns: 5,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "few",
  //         "down",
  //         "moon",
  //         "hawk",
  //         "coin",
  //         "cue",
  //         "load",
  //         "cook",
  //         "haunt",
  //         "toy",
  //         "voot",
  //         "rew",
  //         "fout",
  //         "zoy",
  //         "bawk",
  //       ],
  //     },
  //     {
  //       name: "K - Low Frequency Vowel and Consonant Spellings",
  //       total_score: 15,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: ["Real", "Real", "pseudo"],
  //       columns: 5,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "kneel",
  //         "cent",
  //         "type",
  //         "ghost",
  //         "wrist",
  //         "giant",
  //         "sweat",
  //         "gnat",
  //         "bomb",
  //         "sigh",
  //         "bice",
  //         "knod",
  //         "dimb",
  //         "tigh",
  //         "wrep",
  //       ],
  //     },
  //   ],
  // },
  // {
  //   grade_check: "2",
  //   title: "Part L",
  //   checked: true,
  //   assessment_data: [
  //     {
  //       name: "L - Multisyllabic words",
  //       total_score: 24,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: [
  //         "Closed-closed",
  //         "Closed silent e",
  //         "Open/closed-other",
  //         "Open or closed",
  //         "Silent e",
  //         "Consonant –le",
  //         "r-Controlled",
  //         "Vowel team",
  //       ],
  //       columns: 3,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "unless",
  //         "consent",
  //         "timbut",
  //         "competes",
  //         "admire",
  //         "rompete",
  //         "depend",
  //         "radishes",
  //         "podated",
  //         "zero",
  //         "menu",
  //         "gromu",
  //         "locate",
  //         "inhaled",
  //         "pentate",
  //         "stable",
  //         "dimple",
  //         "morkle",
  //         "further",
  //         "bordered",
  //         "darber",
  //         "railways",
  //         "roaring",
  //         "fauntoon",
  //       ],
  //     },
  //   ],
  // },
  // {
  //   grade_check: "3",
  //   title: "Parts A-K",
  //   checked: true,
  //   assessment_data: [
  //     {
  //       name: "A - Letter Names - Uppercase Sounds",
  //       total_score: 26,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: [],
  //       columns: 9,
  //       category: "Alphabetic Skills & Letter Sounds",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "D",
  //         "A",
  //         "N",
  //         "S",
  //         "X",
  //         "Z",
  //         "J",
  //         "L",
  //         "H",
  //         "T",
  //         "Y",
  //         "E",
  //         "C",
  //         "O",
  //         "M",
  //         "R",
  //         "P",
  //         "W",
  //         "K",
  //         "U",
  //         "G",
  //         "B",
  //         "F",
  //         "Q",
  //         "V",
  //         "I",
  //       ],
  //     },
  //     {
  //       name: "B - Letter Names - Lowercase Sounds",
  //       total_score: 26,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: [],
  //       columns: 9,
  //       category: "Alphabetic Skills & Letter Sounds",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "d",
  //         "a",
  //         "n",
  //         "s",
  //         "x",
  //         "z",
  //         "j",
  //         "l",
  //         "h",
  //         "t",
  //         "y",
  //         "e",
  //         "c",
  //         "o",
  //         "m",
  //         "r",
  //         "p",
  //         "w",
  //         "k",
  //         "u",
  //         "g",
  //         "b",
  //         "f",
  //         "q",
  //         "v",
  //         "i",
  //       ],
  //     },
  //     {
  //       name: "C - Consonant Sounds",
  //       total_score: 21,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: [],
  //       columns: 9,
  //       category: "Alphabetic Skills & Letter Sounds",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "d",
  //         "l",
  //         "n",
  //         "s",
  //         "x",
  //         "z",
  //         "j",
  //         "t",
  //         "y",
  //         "p",
  //         "c",
  //         "h",
  //         "m",
  //         "r",
  //         "k",
  //         "w",
  //         "g",
  //         "b",
  //         "f",
  //         "q",
  //         "v",
  //       ],
  //     },
  //     {
  //       name: "D - Long Vowel Sounds",
  //       total_score: 5,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: [],
  //       columns: 9,
  //       category: "Alphabetic Skills & Letter Sounds",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: ["E", "I", "A", "O", "U"],
  //     },
  //     {
  //       name: "D - Short Vowel Sounds",
  //       total_score: 5,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: [],
  //       columns: 9,
  //       category: "Alphabetic Skills & Letter Sounds",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: ["e", "i", "a", "o", "u"],
  //     },
  //     {
  //       name: "E - Short Vowels in CVC Words",
  //       total_score: 15,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: ["Real", "Real", "pseudo"],
  //       columns: 5,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "sip",
  //         "mat",
  //         "let",
  //         "bun",
  //         "hog",
  //         "rut",
  //         "fit",
  //         "bat",
  //         "hot",
  //         "set",
  //         "nop",
  //         "sut",
  //         "dit",
  //         "pem",
  //         "fap",
  //       ],
  //     },
  //     {
  //       name: "F - Short Vowels and Consonant Blends",
  //       total_score: 15,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: ["Real", "Real", "pseudo"],
  //       columns: 5,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "stop",
  //         "trap",
  //         "quit",
  //         "spell",
  //         "plan",
  //         "silk",
  //         "fast",
  //         "sank",
  //         "lump",
  //         "held",
  //         "nask",
  //         "dilt",
  //         "qued",
  //         "cang",
  //         "dran",
  //       ],
  //     },
  //     {
  //       name: "G - Short Vowels, Digraphs, and –tch Trigraph",
  //       total_score: 15,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: ["Real", "Real", "pseudo"],
  //       columns: 5,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "when",
  //         "chop",
  //         "thin",
  //         "shut",
  //         "wick",
  //         "dodge",
  //         "rash",
  //         "ring",
  //         "then",
  //         "match",
  //         "chid",
  //         "shom",
  //         "dath",
  //         "phid",
  //         "futch",
  //       ],
  //     },
  //     {
  //       name: "H - R–Controlled Vowels",
  //       total_score: 15,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: ["Real", "Real", "pseudo"],
  //       columns: 5,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "harm",
  //         "dirt",
  //         "form",
  //         "fern",
  //         "surf",
  //         "worn",
  //         "pert",
  //         "bark",
  //         "turn",
  //         "bird",
  //         "nerm",
  //         "sirt",
  //         "gorf",
  //         "murd",
  //         "carn",
  //       ],
  //     },
  //     {
  //       name: "I - Long Vowels Spellings",
  //       total_score: 15,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: ["Real", "Real", "pseudo"],
  //       columns: 5,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "tape",
  //         "key",
  //         "toe",
  //         "paid",
  //         "feet",
  //         "leap",
  //         "boat",
  //         "tie",
  //         "ray",
  //         "blow",
  //         "loe",
  //         "hine",
  //         "beap",
  //         "faim",
  //         "soat",
  //       ],
  //     },
  //     {
  //       name: "J - Variant Vowels",
  //       total_score: 15,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: ["Real", "Real", "pseudo"],
  //       columns: 5,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "few",
  //         "down",
  //         "moon",
  //         "hawk",
  //         "coin",
  //         "cue",
  //         "load",
  //         "cook",
  //         "haunt",
  //         "toy",
  //         "voot",
  //         "rew",
  //         "fout",
  //         "zoy",
  //         "bawk",
  //       ],
  //     },
  //     {
  //       name: "K - Low Frequency Vowel and Consonant Spellings",
  //       total_score: 15,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: ["Real", "Real", "pseudo"],
  //       columns: 5,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "kneel",
  //         "cent",
  //         "type",
  //         "ghost",
  //         "wrist",
  //         "giant",
  //         "sweat",
  //         "gnat",
  //         "bomb",
  //         "sigh",
  //         "bice",
  //         "knod",
  //         "dimb",
  //         "tigh",
  //         "wrep",
  //       ],
  //     },
  //   ],
  // },
  // {
  //   grade_check: "3",
  //   title: "Part L",
  //   checked: true,
  //   assessment_data: [
  //     {
  //       name: "L - Multisyllabic words",
  //       total_score: 24,
  //       skill_to_review: "",
  //       skill_to_teach: "",
  //       headings: [
  //         "Closed-closed",
  //         "Closed silent e",
  //         "Open/closed-other",
  //         "Open or closed",
  //         "Silent e",
  //         "Consonant –le",
  //         "r-Controlled",
  //         "Vowel team",
  //       ],
  //       columns: 3,
  //       category: "Reading & Decoding Skills",
  //       terms: [
  //         { checked: true, name: "Fall" },
  //         { checked: true, name: "Winter" },
  //         { checked: true, name: "Spring" },
  //       ],
  //       counts: [
  //         "unless",
  //         "consent",
  //         "timbut",
  //         "competes",
  //         "admire",
  //         "rompete",
  //         "depend",
  //         "radishes",
  //         "podated",
  //         "zero",
  //         "menu",
  //         "gromu",
  //         "locate",
  //         "inhaled",
  //         "pentate",
  //         "stable",
  //         "dimple",
  //         "morkle",
  //         "further",
  //         "bordered",
  //         "darber",
  //         "railways",
  //         "roaring",
  //         "fauntoon",
  //       ],
  //     },
  //   ],
  // },
];

export const alphabetic_skills = [
  {
    name: "A - Letter Names - Uppercase Sounds",
    total_score: 26,
    skill_to_review: "",
    skill_to_teach: "",
    headings: [],
    columns: 9,
    category: "Alphabetic Skills & Letter Sounds",
    grades: ["0", "1", "2", "3"],
    counts: [
      "D",
      "A",
      "N",
      "S",
      "X",
      "Z",
      "J",
      "L",
      "H",
      "T",
      "Y",
      "E",
      "C",
      "O",
      "M",
      "R",
      "P",
      "W",
      "K",
      "U",
      "G",
      "B",
      "F",
      "Q",
      "V",
      "I"
    ]
  },
  {
    name: "B - Letter Names - Lowercase Sounds",
    total_score: 26,
    skill_to_review: "",
    skill_to_teach: "",
    headings: [],
    columns: 9,
    category: "Alphabetic Skills & Letter Sounds",
    grades: ["0", "1", "2", "3"],
    counts: [
      "d",
      "a",
      "n",
      "s",
      "x",
      "z",
      "j",
      "l",
      "h",
      "t",
      "y",
      "e",
      "c",
      "o",
      "m",
      "r",
      "p",
      "w",
      "k",
      "u",
      "g",
      "b",
      "f",
      "q",
      "v",
      "i"
    ]
  },
  {
    name: "C - Consonant Sounds",
    total_score: 21,
    skill_to_review: "",
    skill_to_teach: "",
    headings: [],
    columns: 9,
    category: "Alphabetic Skills & Letter Sounds",
    grades: ["0", "1", "2", "3"],
    counts: [
      "d",
      "l",
      "n",
      "s",
      "x",
      "z",
      "j",
      "t",
      "y",
      "p",
      "c",
      "h",
      "m",
      "r",
      "k",
      "w",
      "g",
      "b",
      "f",
      "q",
      "v"
    ]
  },
  {
    name: "D - Long Vowel Sounds - Short Vowel Sounds",
    total_score: 10,
    skill_to_review: "",
    skill_to_teach: "",
    headings: [],
    columns: 9,
    category: "Alphabetic Skills & Letter Sounds",
    grades: ["1", "2", "3"],
    counts: ["E", "e", "I", "i", "A", "a", "O", "o", "U", "u"]
  }
  // {
  //   name: "D - Short Vowel Sounds",
  //   total_score: 5,
  //   skill_to_review: "",
  //   skill_to_teach: "",
  //   headings: [],
  //   columns: 9,
  //   category: "Alphabetic Skills & Letter Sounds",
  //   grades: ["1", "2", "3"],
  //   counts: ["e", "i", "a", "o", "u"]
  // }
];

export const reading_decoding_skills = [
  {
    name: "E - Short Vowels in CVC Words",
    total_score: 15,
    skill_to_review: "",
    skill_to_teach: "",
    headings: ["Real", "Real", "pseudo"],
    columns: 5,
    category: "Reading & Decoding Skills",
    grades: ["0", "1", "2", "3"],
    counts: [
      "sip",
      "mat",
      "let",
      "bun",
      "hog",
      "rut",
      "fit",
      "bat",
      "hot",
      "set",
      "nop",
      "sut",
      "dit",
      "pem",
      "fap"
    ]
  },
  {
    name: "F - Short Vowels and Consonant Blends",
    total_score: 15,
    skill_to_review: "",
    skill_to_teach: "",
    headings: ["Real", "Real", "pseudo"],
    columns: 5,
    category: "Reading & Decoding Skills",
    grades: ["1", "2", "3"],
    counts: [
      "stop",
      "trap",
      "quit",
      "spell",
      "plan",
      "silk",
      "fast",
      "sank",
      "lump",
      "held",
      "nask",
      "dilt",
      "qued",
      "cang",
      "dran"
    ]
  },
  {
    name: "G - Short Vowels, Digraphs, and –tch Trigraph",
    total_score: 15,
    skill_to_review: "",
    skill_to_teach: "",
    headings: ["Real", "Real", "pseudo"],
    columns: 5,
    category: "Reading & Decoding Skills",
    grades: ["1", "2", "3"],
    counts: [
      "when",
      "chop",
      "thin",
      "shut",
      "wick",
      "dodge",
      "rash",
      "ring",
      "then",
      "match",
      "chid",
      "shom",
      "dath",
      "phid",
      "futch"
    ]
  },
  {
    name: "H - R–Controlled Vowels",
    total_score: 15,
    skill_to_review: "",
    skill_to_teach: "",
    headings: ["Real", "Real", "pseudo"],
    columns: 5,
    category: "Reading & Decoding Skills",
    grades: ["1", "2", "3"],
    counts: [
      "harm",
      "dirt",
      "form",
      "fern",
      "surf",
      "worn",
      "pert",
      "bark",
      "turn",
      "bird",
      "nerm",
      "sirt",
      "gorf",
      "murd",
      "carn"
    ]
  },
  {
    name: "I - Long Vowels Spellings",
    total_score: 15,
    skill_to_review: "",
    skill_to_teach: "",
    headings: ["Real", "Real", "pseudo"],
    columns: 5,
    category: "Reading & Decoding Skills",
    grades: ["1", "2", "3"],
    counts: [
      "tape",
      "key",
      "toe",
      "paid",
      "feet",
      "leap",
      "boat",
      "tie",
      "ray",
      "blow",
      "loe",
      "hine",
      "beap",
      "faim",
      "soat"
    ]
  },
  {
    name: "J - Variant Vowels",
    total_score: 15,
    skill_to_review: "",
    skill_to_teach: "",
    headings: ["Real", "Real", "pseudo"],
    columns: 5,
    category: "Reading & Decoding Skills",
    grades: ["1", "2", "3"],
    counts: [
      "few",
      "down",
      "moon",
      "hawk",
      "coin",
      "cue",
      "load",
      "cook",
      "haunt",
      "toy",
      "voot",
      "rew",
      "fout",
      "zoy",
      "bawk"
    ]
  },
  {
    name: "K - Low Frequency Vowel and Consonant Spellings",
    total_score: 15,
    skill_to_review: "",
    skill_to_teach: "",
    headings: ["Real", "Real", "pseudo"],
    columns: 5,
    category: "Reading & Decoding Skills",
    grades: ["1", "2", "3"],
    counts: [
      "kneel",
      "cent",
      "type",
      "ghost",
      "wrist",
      "giant",
      "sweat",
      "gnat",
      "bomb",
      "sigh",
      "bice",
      "knod",
      "dimb",
      "tigh",
      "wrep"
    ]
  },
  {
    name: "L - Multisyllabic words",
    total_score: 24,
    skill_to_review: "",
    skill_to_teach: "",
    headings: [
      "Closed-closed",
      "Closed silent e",
      "Open/closed-other",
      "Open or closed",
      "Silent e",
      "Consonant –le",
      "r-Controlled",
      "Vowel team"
    ],
    columns: 3,
    category: "Reading & Decoding Skills",
    grades: ["1", "2", "3"],
    counts: [
      "unless",
      "consent",
      "timbut",
      "competes",
      "admire",
      "rompete",
      "depend",
      "radishes",
      "podated",
      "zero",
      "menu",
      "gromu",
      "locate",
      "inhaled",
      "pentate",
      "stable",
      "dimple",
      "morkle",
      "further",
      "bordered",
      "darber",
      "railways",
      "roaring",
      "fauntoon"
    ]
  }
];
