import React, { useContext } from "react";
import { PiStudent } from "react-icons/pi";
import { TbPresentationAnalytics } from "react-icons/tb";
import { FaRegUser } from "react-icons/fa";
import { CardSkelton_1 } from "./shared/Skeltons";
import { skelton_data } from "../staticData/SkeltonData";
import GlobalContext from "../context/GlobalContext";

function DashboardCard({ membersLoading, members }) {
  const { userInfo } = useContext(GlobalContext);
  const dashboard = [
    {
      id: 1,
      title: "Total Students",
      student: members?.total_student || 0,
      icon: <PiStudent />,
      bgColor: "bg-green-300",
      textColor: "text-green-600",
    },
    {
      id: 2,
      title:
        userInfo?.user?.is_teacher === true
          ? "Total Assessments"
          : "Total Teachers",
      student:
        userInfo?.user?.is_teacher === true
          ? members?.total_assessments || 0
          : members?.total_teacher || 0,
      icon: <TbPresentationAnalytics />,
      bgColor: "bg-red-200",
      textColor: "text-red-600",
    },
    {
      id: 3,
      title: "Grades",
      student: members?.total_grade || members?.total_classes,
      icon: <FaRegUser />,
      bgColor: "bg-gray-200",
      textColor: "text-gray-600",
    },
  ];
  return (
    <>
      <div className="flex md:flex-row xs:flex-col  gap-2">
        {membersLoading ? (
          <>
            {skelton_data.slice(0, 3).map((_, index) => (
              <CardSkelton_1 key={index} />
            ))}
          </>
        ) : (
          <>
            {dashboard?.map((data, index) => (
              <div
                key={index}
                className="bg-white rounded-[20px] md:w-[33%] xs:w-full py-6 px-6 flex relative"
                style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
              >
                <div>
                  <p className="2xl:font-[400] 2xl:text-[22px] lg:font-[300] lg:text-[20px]  font-robo text-[#737373]">
                    {data.title}
                  </p>
                  <h1 className="2xl:font-[700] 2xl:text-[40px] lg:font-[500] lg:text-[37px] font-robo text-[#263238]">
                    {data.student}
                  </h1>
                </div>
                <div
                  className={`text-[22px] ${data.textColor} ${data.bgColor} rounded p-1 absolute bottom-6 right-8`}
                >
                  {data.icon}
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </>
  );
}

export default DashboardCard;
