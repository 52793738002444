import React, { useContext } from "react";
import logo from "../../assets/logo.png";
import { RxDashboard } from "react-icons/rx";
import { FaRegUser } from "react-icons/fa";
import { PiStudent } from "react-icons/pi";
import { FaChalkboardUser } from "react-icons/fa6";
import { TbPresentationAnalytics } from "react-icons/tb";
import { VscCommentDiscussion } from "react-icons/vsc";
import { IoMdLogOut } from "react-icons/io";
import { Link, NavLink } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import { MdOutlineClose } from "react-icons/md";
import { removeAccessToken, removeRefreshToken } from "../../storage/storage";
import { CiSettings } from "react-icons/ci";

function Sidebar({ toggleSidebar }) {
  const { userInfo } = useContext(GlobalContext);
  const handleLogout = () => {
    removeAccessToken();
    removeRefreshToken();
    window.location.href = "/";
  };
  return (
    <>
      <div className="w-full bg-backgroundColor py-6 px-6 h-[100vh] flex flex-col justify-between relative">
        <div className="lg:hidden flex absolute top-2 right-2">
          <MdOutlineClose
            onClick={toggleSidebar}
            className="text-[30px] font-roboto text-[#1C1D1E] hover:text-themeColor cursor-pointer"
          />
        </div>
        <div>
          <Link to="/">
            <img src={logo} alt="" className="" />
          </Link>

          <div className=" flex flex-col 2xl:gap-6 lg:gap-4 mt-10">
            <NavLink
              to="/"
              className={`flex items-center gap-2 px-5 py-2 rounded text-[#263238] cursor-pointer hover:text-themeColor hover:bg-[#ffedee]`}
            >
              <RxDashboard className="text-[20px]" />
              <p className="2xl:text-[20px] lg:text-[18px] font-roboto ">
                Dashboard
              </p>
            </NavLink>
            {userInfo?.is_superuser === true && (
              <NavLink
                to="/teachers"
                className={`flex items-center gap-2 px-5 py-2 rounded text-[#263238] cursor-pointer hover:text-themeColor hover:bg-[#ffedee]`}
              >
                <FaRegUser className="text-[20px]" />
                <p className=" 2xl:text-[20px] lg:text-[18px] font-roboto ">
                  Teachers
                </p>
              </NavLink>
            )}

            <NavLink
              to="/students"
              className={`flex items-center gap-2 px-5 py-2 rounded text-[#263238] cursor-pointer hover:text-themeColor hover:bg-[#ffedee]`}
            >
              <PiStudent className="text-[20px]" />
              <p className=" 2xl:text-[20px]  lg:text-[18px] font-roboto ">
                Students
              </p>
            </NavLink>
            <NavLink
              to="/classes"
              className={`flex items-center gap-2 px-5 py-2 rounded text-[#263238] cursor-pointer hover:text-themeColor hover:bg-[#ffedee]`}
            >
              <FaChalkboardUser className="text-[20px]" />
              <p className=" 2xl:text-[20px]  lg:text-[18px] font-roboto ">
                Classes
              </p>
            </NavLink>
            <NavLink
              to="/assessments"
              className={`flex items-center gap-2 px-5 py-2 rounded text-[#263238] cursor-pointer hover:text-themeColor hover:bg-[#ffedee]`}
            >
              <VscCommentDiscussion className="text-[20px]" />
              <p className=" 2xl:text-[20px] lg:text-[18px] font-roboto ">
                Assessments
              </p>
            </NavLink>
            <NavLink
              to="/class-analytics"
              className={`flex items-center gap-2 px-5 py-2 rounded text-[#263238] cursor-pointer hover:text-themeColor hover:bg-[#ffedee]`}
            >
              <TbPresentationAnalytics className="text-[20px]" />
              <p className=" 2xl:text-[20px]  lg:text-[18px] font-roboto ">
                Class analytics
              </p>
            </NavLink>

            <NavLink
              to="/settings"
              className={`flex items-center gap-2 px-5 py-2 rounded text-[#263238] cursor-pointer hover:text-themeColor hover:bg-[#ffedee]`}
            >
              <CiSettings className="text-[20px]" />
              <p className=" 2xl:text-[20px]  lg:text-[18px] font-roboto ">
                Settings
              </p>
            </NavLink>
          </div>
        </div>
        <div
          onClick={handleLogout}
          className="flex items-center justify-center gap-3 border border-[#DCDCDC] rounded py-2 cursor-pointer hover:border-themeColor hover:text-themeColor"
        >
          <IoMdLogOut className="text-[20px]" />
          <p className="font-[500] text-[18px] font-roboto ">Log out</p>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
