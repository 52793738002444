import axios from "axios";
import { useState } from "react";
import { getAccessToken } from "../storage/storage";

const useGetClasses = () => {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [classessData, setClassesData] = useState([]);
  const [classesLoading, setClassesLoading] = useState(true);
  const [classesMessage, setClassesMessage] = useState(null);

  const GetClasses = async () => {
    await axios
      .get(`${BASE_URL}/api/classes`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setClassesLoading(false);
        setClassesData(response.data);
        if (response.data.length === 0) {
          setClassesMessage("No classes found");
        } else {
          setClassesMessage(null);
        }
      })
      .catch((error) => {
        setClassesLoading(false);
        setClassesMessage("Oops! Something went wrong");
      });
  };
  return {
    classesLoading,
    GetClasses,
    classessData,
    classesMessage,
    setClassesData,
  };
};
export default useGetClasses;
