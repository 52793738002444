/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import DashboardLayout from "../components/shared/DashboardLayout";
import { useNavigate, useParams } from "react-router-dom";
import useGetStudentsDetail from "../hooks/useGetStudentsDetail";
import { CardSkelton_7 } from "../components/shared/Skeltons";
import { skelton_data } from "../staticData/SkeltonData";
import useGetStudentsOnScroll from "../hooks/useGetStudentsOnScroll";
import ShowMessage from "../components/shared/ShowMessage";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { curveCardinal } from "d3-shape";
import { debounce } from "lodash";
const cardinal = curveCardinal.tension(0.2);

function StudentsDetailPage() {
  return (
    <DashboardLayout>
      <div className="bg-backgroundColor px-5 py-2 flex gap-4 w-full h-auto">
        <Students />
        <ProfileDetails />
      </div>
    </DashboardLayout>
  );
}

export default StudentsDetailPage;
function Students() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const Navigate = useNavigate();
  const { id } = useParams();
  const [query, setQuery] = useState("");
  const {
    GetStudentsOnScroll,
    studentLoading,
    studentsData,
    studentDataLoading,
    studentMessage,
    totalPagesStudent,
    currentPageStudent,
    setCurrentPageStudent,
    setStudentDataLoading,
    setStudentLoading,
    setStudentsData,
  } = useGetStudentsOnScroll();

  const debouncedSearch = useCallback(
    debounce((value) => {
      setCurrentPageStudent(1);
      GetStudentsOnScroll(`?page=${currentPageStudent}&search=${value}`);
    }, 1000),
    []
  );

  useEffect(() => {
    GetStudentsOnScroll(`?page=${currentPageStudent}&search=${query}`);
  }, [currentPageStudent]);

  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
    setStudentLoading(true);
    setStudentsData([]);
  };

  useEffect(() => {
    const element = document.getElementById("table");
    const handleScroll = () => {
      if (element && currentPageStudent <= totalPagesStudent) {
        const { scrollTop, clientHeight, scrollHeight } = element;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
          setCurrentPageStudent((prevOffset) => prevOffset + 1);
          setStudentDataLoading(true);
        }
      }
    };

    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [GetStudentsOnScroll, currentPageStudent]);

  return (
    <div
      className="flex-[.6] px-5 py-5 rounded-[20px]"
      style={{ background: "rgba(220, 220, 220, 0.25)" }}
    >
      <div className="flex items-center justify-between w-full mb-3">
        <h1 className="2xl:font-[700] 2xl:text-[32px] lg:font-[500] lg:text-[30px] font-roboto text-[#263238]">
          Students
        </h1>
        {/* <div className="custom-select w-[150px] h-[40px]">
          <select>
            <option disabled selected>
              Choose grade
            </option>
            <option value="0">Grade: 0</option>
            <option value="1">Grade: 1</option>
            <option value="2">Grade: 2</option>
          </select>
        </div> */}
      </div>
      <div className="flex items-center border bg-[#FCFCFC] border-[#FCFCFC] hover:border-[#017663] focus-within:border-[#017663] rounded-[10px] p-2 w-full h-[40px]">
        <input
          type="search"
          value={query}
          onChange={handleChange}
          placeholder="Search..."
          className="outline-none border-none w-[100%] bg-transparent px-2"
        />
        <CiSearch className="text-[20px] text-[#C1C1C1]" />
      </div>
      <div>
        <div className="flex items-center justify-between px-8 py-4">
          <span className="2xl:text-[18px] font-[700] lg:text-[15px] font-roboto text-[#2D2D2D]">
            Student name
          </span>
          <span className="2xl:text-[18px] font-[700] lg:text-[15px] font-roboto text-[#2D2D2D]">
            Student ID
          </span>
        </div>
        <div className="h-[80vh] overflow-y-scroll pe-2" id="table">
          <div className="flex flex-col gap-2">
            {studentLoading ? (
              <>
                {skelton_data.slice(0, 6).map((_, index) => (
                  <CardSkelton_7 key={index} />
                ))}
              </>
            ) : (
              <>
                {studentsData?.map((item, index) => (
                  <div
                    className={`${
                      item.id == id
                        ? "bg-white border-transparent rounded-[20px]"
                        : ""
                    } cursor-pointer flex items-center px-8 py-2 justify-between border-b border-[#C1C1C1] hover:rounded-[20px] hover:bg-[#FFFFFF] hover:border-transparent`}
                    key={index}
                    onClick={() => Navigate(`/students/${item.id}`)}
                  >
                    <div className="flex items-center gap-5">
                      {item.profile && (
                        <img
                          src={`${BASE_URL}${item?.profile}`}
                          alt=""
                          className="w-[60px] h-[60px] rounded-full"
                        />
                      )}
                      {item.profile === null && (
                        <img
                          src={"/avatar_dummy.png"}
                          alt=""
                          className="w-[60px] h-[60px] rounded-full"
                        />
                      )}
                      <span>{item.name}</span>
                    </div>
                    <span>{item.student_id}</span>
                  </div>
                ))}
              </>
            )}
          </div>
          {!studentLoading && !studentDataLoading && studentMessage && (
            <div className="w-full h-full flex justify-center items-center">
              <ShowMessage message={studentMessage} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ProfileDetails() {
  const Navigate = useNavigate();
  const { id } = useParams();

  const goToAnalyticsPage = () => {
    Navigate(`/students/analytics/${id}`);
  };

  const { GetStudentsDetail, studentsDetail } = useGetStudentsDetail();

  useEffect(() => {
    if (id) {
      GetStudentsDetail(id);
    }
  }, [id]);

  return (
    <div className="flex-1 bg-[#FFFFFF] px-5 py-5 flex-col rounded-[20px]">
      <div className="w-full flex items-center justify-between">
        <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[500] lg:text-[30px] font-roboto text-[#263238]">
          Profile/Personal details
        </h1>
        <div
          className=" flex items-center border justify-center bg-[#017663] border-none rounded-[6px] w-[80px] h-[35px] cursor-pointer text-white"
          onClick={goToAnalyticsPage}
        >
          <p className="2xl:font-[700] 2xl:text-[15px] lg:font-[500] lg:text-[14px]  font-roboto">
            Analytics
          </p>
        </div>
      </div>
      {/*   Basic information */}
      <span className="2xl:font-[700] 2xl:text-[26px] lg:font-[500] lg:text-[20px] font-roboto text-[#263238] mt-2">
        Basic info
      </span>
      <div className="border border-[#DCDCDC] rounded-[20px] w-full px-10 py-5 flex justify-between ">
        <div className="flex flex-col gap-6">
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              Name:{" "}
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {studentsDetail?.student_data?.name}
            </span>
          </div>
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              DOB:{" "}
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {studentsDetail?.student_data?.date_of_birth}
            </span>
          </div>{" "}
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              Class:
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {studentsDetail?.student_data?.classgrade?.class_name}
            </span>
          </div>{" "}
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              Address:
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {studentsDetail?.student_data?.address}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              ID:
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {studentsDetail?.student_data?.student_id}
            </span>
          </div>
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              Gender:
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {studentsDetail?.student_data?.gender === "M" && "Male"}
              {studentsDetail?.student_data?.gender === "F" && "Female"}
            </span>
          </div>{" "}
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              Grade:
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {studentsDetail?.student_data?.classgrade?.grade}
            </span>
          </div>{" "}
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              Admission date:
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {studentsDetail?.student_data?.admission_date}
            </span>
          </div>{" "}
        </div>
      </div>
      {/* About Teacher */}
      {/* <div className="mt-4">
        <span className="2xl:font-[700] 2xl:text-[24px] lg:font-[500] lg:text-[22px] font-roboto text-[#263238]">
          About student
        </span>
        <p className="font-[400] 2xl:text-[16px] lg:text-[14px] font-roboto text-[#737373] mt-2">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Lorem ipsum
          dolor sit amet, consectetuer adipiscing elit.
        </p>
      </div> */}
      {studentsDetail?.student_performance?.length > 0 && (
        <div className="w-full mt-6">
          <LineGraph graphData={studentsDetail?.student_performance} />
        </div>
      )}
    </div>
  );
}

const LineGraph = ({ graphData }) => {
  return (
    <div className="bg-white w-full h-[350px] flex justify-center items-center">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={graphData}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="assessment_name" />
          <YAxis />
          <Tooltip />
          <Area
            type={cardinal}
            dataKey="percentage"
            stroke="#017663"
            fill="#017663"
            fillOpacity={0.2}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
