/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import DashboardLayout from "../components/shared/DashboardLayout";
import useGetTeachersDetail from "../hooks/useGetTeachersDetail";
import { CardSkelton_7 } from "../components/shared/Skeltons";
import { skelton_data } from "../staticData/SkeltonData";
import { useNavigate, useParams } from "react-router-dom";
import useGetTeachersOnScroll from "../hooks/useGetTeachersOnScroll";
import ShowMessage from "../components/shared/ShowMessage";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { curveCardinal } from "d3-shape";
import { format, parseISO } from "date-fns";
import { debounce } from "lodash";
const cardinal = curveCardinal.tension(0.2);

function TeachersDetailPage() {
  return (
    <>
      <DashboardLayout>
        <div className="bg-backgroundColor px-5 py-2 flex gap-4 w-full h-auto">
          <Teachers />
          <ProfileDetails />
        </div>
      </DashboardLayout>
    </>
  );
}

export default TeachersDetailPage;

function Teachers() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const Navigate = useNavigate();
  const { id } = useParams();
  const [query, setQuery] = useState("");
  const {
    GetTeachersOnScroll,
    teachersData,
    teacherLoading,
    teacherDataLoading,
    teacherMessage,
    totalPagesTeacher,
    currentPageTeacher,
    setCurrentPageTeacher,
    setTeacherLoading,
    setTeachersData,
    setTeacherDataLoading,
  } = useGetTeachersOnScroll();

  const debouncedSearch = useCallback(
    debounce((value) => {
      setCurrentPageTeacher(1);
      GetTeachersOnScroll(`?page=${currentPageTeacher}&search=${value}`);
    }, 1000),
    []
  );

  useEffect(() => {
    GetTeachersOnScroll(`?page=${currentPageTeacher}&search=${query}`);
  }, [currentPageTeacher]);

  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
    setTeacherLoading(true);
    setTeachersData([]);
  };

  useEffect(() => {
    const element = document.getElementById("table");
    const handleScroll = () => {
      if (element && currentPageTeacher <= totalPagesTeacher) {
        const { scrollTop, clientHeight, scrollHeight } = element;
        if (scrollTop + clientHeight >= scrollHeight - 1) {
          setCurrentPageTeacher((prevOffset) => prevOffset + 1);
          setTeacherDataLoading(true);
        }
      }
    };

    if (element) {
      element.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (element) {
        element.removeEventListener("scroll", handleScroll);
      }
    };
  }, [GetTeachersOnScroll, currentPageTeacher]);
  return (
    <div
      className="flex-[.6] px-5 py-5 rounded-[20px]"
      style={{ background: "rgba(220, 220, 220, 0.25)" }}
    >
      <div className="flex items-center justify-between w-full mb-3">
        <h1 className="2xl:font-[700] 2xl:text-[32px] lg:font-[500] lg:text-[30px] font-roboto text-[#263238]">
          Teachers
        </h1>
        {/* <div className="custom-select w-[120px] h-[40px]">
          <select>
            <option value="all" selected>
              All
            </option>
            <option value="new">Newest</option>
            <option value="old">Old</option>
          </select>
        </div> */}
      </div>
      <div className="flex items-center border bg-[#FCFCFC] border-[#FCFCFC] hover:border-[#017663] focus-within:border-[#017663] rounded-[10px] p-2 w-full h-[40px]">
        <input
          type="search"
          value={query}
          onChange={handleChange}
          placeholder="Search..."
          className="outline-none border-none w-[100%] bg-transparent px-2"
        />
        <CiSearch className="text-[20px] text-[#C1C1C1]" />
      </div>
      <div>
        <div className="flex items-center justify-between px-8 py-4">
          <span className="2xl:text-[18px] font-[700] lg:text-[15px] font-roboto text-[#2D2D2D]">
            Teacher Name
          </span>
          <span className="2xl:text-[18px] font-[700] lg:text-[15px] font-roboto text-[#2D2D2D]">
            Grade
          </span>
        </div>
        <div className="h-[80vh] overflow-y-scroll pe-2" id="table">
          <div className="flex flex-col gap-2">
            {teacherLoading ? (
              <>
                {skelton_data.slice(0, 6).map((_, index) => (
                  <CardSkelton_7 key={index} />
                ))}
              </>
            ) : (
              <>
                {teachersData?.map((item, index) => (
                  <div
                    className={`${
                      item.id == id
                        ? "bg-white border-transparent rounded-[20px]"
                        : ""
                    } cursor-pointer flex items-center px-8 py-2 justify-between border-b border-[#C1C1C1] hover:rounded-[20px] hover:bg-[#FFFFFF] hover:border-transparent`}
                    key={index}
                    onClick={() => Navigate(`/teachers/${item.id}`)}
                  >
                    <div className="flex items-center gap-5">
                      {item.profile_picture && (
                        <img
                          src={`${BASE_URL}${item?.profile_picture}`}
                          alt=""
                          className="w-[60px] h-[60px] rounded-full"
                        />
                      )}
                      {item.profile_picture === null && (
                        <img
                          src={"/avatar_dummy.png"}
                          alt=""
                          className="w-[60px] h-[60px] rounded-full"
                        />
                      )}
                      <span>{item.first_name}</span>
                    </div>
                    <span>{item.grade_name}</span>
                  </div>
                ))}
              </>
            )}
          </div>
          {!teacherLoading && !teacherDataLoading && teacherMessage && (
            <div className="w-full h-full flex justify-center items-center">
              <ShowMessage message={teacherMessage} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function ProfileDetails() {
  const { id } = useParams();
  const { GetTeachersDetail, teachersDetail } = useGetTeachersDetail();
  useEffect(() => {
    if (id) {
      GetTeachersDetail(id);
    }
  }, [id]);
  console.log(teachersDetail?.teacher?.classes);
  return (
    <div className="flex-1 bg-[#FFFFFF] px-5 py-5 flex-col rounded-[20px]">
      <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[500] lg:text-[30px] font-roboto text-[#263238]">
        Profile/Personal details
      </h1>
      {/*   Basic information */}
      <span className="2xl:font-[700] 2xl:text-[26px] lg:font-[500] lg:text-[20px] font-roboto text-[#263238]">
        Basic info
      </span>
      <div className="border border-[#DCDCDC] rounded-[20px] w-full px-10 py-5 flex justify-between mt-2">
        <div className="flex flex-col gap-6">
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              Teacher name:{" "}
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {teachersDetail?.teacher?.user.first_name}
            </span>
          </div>
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              Qualification:{" "}
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {teachersDetail?.teacher?.qualification}
            </span>
          </div>{" "}
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              Email:
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {teachersDetail?.teacher?.user.email}
            </span>
          </div>{" "}
          {teachersDetail?.teacher?.classes.map((item, index) => (
            <div className="flex gap-2 items-center" key={index}>
              <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
                Class:
              </span>
              <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
                {item?.class_name}
              </span>
            </div>
          ))}
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              Joining date:
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {teachersDetail?.teacher?.joining_date}
            </span>
          </div>{" "}
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              Address:
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {teachersDetail?.teacher?.user.address}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              Gender:
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {teachersDetail?.teacher?.user.gender === "M" && "Male"}
              {teachersDetail?.teacher?.user.gender === "F" && "Female"}
            </span>
          </div>
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              Experience:
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {teachersDetail?.teacher?.experience}
            </span>
          </div>{" "}
          <div className="flex gap-2 items-center">
            <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
              DOB:
            </span>
            <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
              {teachersDetail?.teacher?.user.date_of_birth}
            </span>
          </div>{" "}
          {teachersDetail?.teacher?.classes.map((item, index) => (
            <div className="flex gap-2 items-center" key={index}>
              <span className="2xl:font-[400] 2xl:text-[15px] lg:font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
                Grade:
              </span>
              <span className="2xl:font-[600] 2xl:text-[15px] lg:font-[500] lg:text-[12px] font-roboto text-[#263238]">
                {item?.grade}
              </span>
            </div>
          ))}
        </div>
      </div>
      {/* About Teacher */}
      {/* <div className="mt-4">
        <span className="2xl:font-[700] 2xl:text-[24px] lg:font-[500] lg:text-[22px] font-roboto text-[#263238]">
          About teacher
        </span>
        <p className="font-[400] 2xl:text-[16px] lg:text-[14px] font-roboto text-[#737373] mt-2">
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean
          commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus
          et magnis dis parturient montes, nascetur ridiculus mus. Lorem ipsum
          dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula
          eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis
          parturient montes, nascetur ridiculus mus. Lorem ipsum dolor sit amet,
          consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean
          massa. Cum sociis natoque penatibus et magnis dis parturient montes,
          nascetur ridiculus mus.Lorem ipsum dolor sit amet, consectetuer
          adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum
          sociis natoque penatibus et magnis dis parturient montes, nascetur
          ridiculus mus.
        </p>
      </div> */}
      {teachersDetail?.graph_data?.length > 0 && (
        <div className="w-full mt-6">
          <LineGraph graphData={teachersDetail?.graph_data} />
        </div>
      )}
    </div>
  );
}

const LineGraph = ({ graphData }) => {
  const formattedData = graphData?.map((item) => ({
    ...item,
    month: format(parseISO(item.month), "MMMM"),
  }));
  return (
    <div className="bg-white w-full h-[350px] flex justify-center items-center">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={formattedData}
          margin={{
            top: 10,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Area
            type={cardinal}
            dataKey="assessments"
            stroke="#017663"
            fill="#017663"
            fillOpacity={0.2}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};
