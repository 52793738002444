import { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import GlobalContext from "../context/GlobalContext";
import { getAccessToken } from "../storage/storage";

const useAssessmentNextPageDetail = () => {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [nextPageLoading, setNextPageLoading] = useState(false);
  const [nextPageData, setNextPageData] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [correctCounts, setCorrectCounts] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const { setUpdateResponse } = useContext(GlobalContext);

  const assessmentNextpage = async (
    payload,
    sectionId,
    handleClearData,
    setSectionID,
    handleClose
  ) => {
    await axios
      .post(`${BASE_URL}/api/assement-detail`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setNextPageLoading(false);
        if (response.data?.message) {
          handleClearData();
          setSectionID(0);
          handleClose();
          setUpdateResponse(true);
          Swal.fire({
            icon: "success",
            text: "Assessment attempted!",
            customClass: {
              confirmButton: "custom-green-button",
            },
          });
        } else {
          setNextPageData(response.data);
          if (response.data?.answers?.length > 0) {
            setActiveIndex(response.data?.answers?.length);
          }
          if (response?.data?.answers) {
            setAnswers(response?.data?.answers);
          }
          if (response?.data?.total_correct) {
            setCorrectCounts(response?.data?.total_correct);
          }
        }
      })
      .catch((error) => {
        setNextPageLoading(false);
      });
  };

  return {
    nextPageLoading,
    assessmentNextpage,
    setNextPageLoading,
    setNextPageData,
    nextPageData,
    setCorrectCounts,
    setAnswers,
    answers,
    correctCounts,
    activeIndex,
    setActiveIndex,
  };
};
export default useAssessmentNextPageDetail;
