import { useContext, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import GlobalContext from "../context/GlobalContext";
import { getAccessToken } from "../storage/storage";

const useEditClass = () => {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [loading1, setLoading1] = useState(false);
  const { setUpdateResponse } = useContext(GlobalContext);

  const editClass = async (payload, handleClose) => {
    setLoading1(true);
    await axios
      .put(`${BASE_URL}/api/class/`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        handleClose();
        setLoading1(false);
        setUpdateResponse(true);
        Swal.fire({
          icon: "success",
          text: "Class successfully updated!",
          customClass: {
            confirmButton: "custom-green-button",
          },
        });
      })
      .catch((error) => {
        setLoading1(false);
        Swal.fire({
          icon: "error",
          text: error?.response?.data?.message || "Oops! Something went wrong",
          customClass: {
            confirmButton: "custom-red-button",
          },
        });
      });
  };

  return { loading1, editClass };
};
export default useEditClass;
