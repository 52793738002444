import { useContext, useState } from "react";
import axios from "axios";
import GlobalContext from "../context/GlobalContext";
import useSessionStorage from "./useSessionStorage";
import { getAccessToken } from "../storage/storage";

function useGetAssessments() {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [assessmentsData, setAssessmentsData] = useState(null);
  const [assessmentLoading, setAssessmentLoading] = useState(true);
  const [assessmentMessage, setAssessmentMessage] = useState(null);
  const [totalPagesAssessment, setTotalPagesAssessment] = useState();
  const [currentPageAssessment, setCurrentPageAssessment] = useSessionStorage(
    "currentPageAssessment",
    1
  );
  const { setUpdateResponse } = useContext(GlobalContext);

  const GetAssessments = async (filter) => {
    await axios
      .get(`${BASE_URL}/api/create-assessment-data${filter}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAssessmentLoading(false);
        setAssessmentsData(response.data.results);
        setUpdateResponse(false);
        setTotalPagesAssessment(response.data.num_pages);
        if (response.data.results.length === 0) {
          setAssessmentMessage("No assessments found");
        } else {
          setAssessmentMessage(null);
        }
      })
      .catch((error) => {
        setAssessmentLoading(false);
        setAssessmentMessage("Oops! Something went wrong");
      });
  };

  return {
    assessmentLoading,
    GetAssessments,
    assessmentsData,
    assessmentMessage,
    totalPagesAssessment,
    currentPageAssessment,
    setCurrentPageAssessment,
  };
}

export default useGetAssessments;
