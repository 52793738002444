import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "../components/shared/DashboardLayout";
import ChangePassword from "../components/ChangePassword";
import AppHead from "../seo/AppHead";
import UpdateProfile from "../components/UpdateProfile";
import GlobalContext from "../context/GlobalContext";

const Settings = () => {
  const { userInfo } = useContext(GlobalContext);
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    if (userInfo?.user?.is_teacher) {
      setSelectedTab("update-profile");
    } else {
      setSelectedTab("change-password");
    }
  }, [userInfo]);

  return (
    <>
      <AppHead title="Settings - ELEMENT EDUCATION" />
      <DashboardLayout>
        <div className="px-5 pt-4 pb-5">
          <div
            className="bg-[#FCFCFC] p-4 rounded-[10px]"
            style={{ boxShadow: "0px 0px 7px #e0e0e0" }}
          >
            <div className="flex">
              {userInfo?.user?.is_teacher === true && (
                <button
                  className={`w-[150px] h-[50px] ${
                    selectedTab === "update-profile"
                      ? "text-themeColor font-bold border-b-4 border-themeColor"
                      : "text-gray-500 border-b-4 border-[#e5e5e5]"
                  }`}
                  onClick={() => setSelectedTab("update-profile")}
                >
                  Update Profile
                </button>
              )}
              <button
                className={`w-[150px] h-[50px] ${
                  selectedTab === "change-password"
                    ? "text-themeColor font-bold border-b-4 border-themeColor"
                    : "text-gray-500 border-b-4 border-[#e5e5e5] "
                }`}
                onClick={() => setSelectedTab("change-password")}
              >
                Change Password
              </button>
            </div>

            <div className="mt-4">
              {selectedTab === "update-profile" && <UpdateProfile />}
              {selectedTab === "change-password" && <ChangePassword />}
            </div>
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default Settings;
