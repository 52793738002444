import React from "react";
import "../../styles/checkbox.css";

function Checkbox({ onChange, checked }) {
  return (
    <div class="checkbox-wrapper-40">
      <label>
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span class="checkbox"></span>
      </label>
    </div>
  );
}

export default Checkbox;
