/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useRef, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "axios";
import { ButtonLoader_1 } from "../shared/Loaders";
import { AiOutlineClose } from "react-icons/ai";
import Swal from "sweetalert2";
import { getAccessToken } from "../../storage/storage";

function AudioRecorder({
  isOpenModal,
  setIsOpenModal,
  selectedLetter,
  setActiveIndex,
  activeIndex,
  setAnswers,
  correctCounts,
  setCorrectCounts,
  assessment_type,
}) {
  const token = getAccessToken();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const cancelButtonRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const [isRunning, setIsRunning] = React.useState(false);
  const [elapsedTime, setElapsedTime] = React.useState(0);
  const [recordBlobLink, setRecordBlobLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const mediaRecorder = useRef(null);

  const blob = new Blob(audioChunks, { type: "audio/wav" });
  const formData = new FormData();
  formData.append("letter", selectedLetter);
  formData.append("voice", blob);
  if (assessment_type) {
    formData.append("assessment_type", assessment_type);
  }

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${BASE_URL}/api/voice/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      });
      setActiveIndex(activeIndex + 1);
      setAnswers((previousData) => [...previousData, response.data.letter]);
      if (response.data.letter !== " ") {
        setCorrectCounts(correctCounts + 1);
      }
      setIsOpenModal(false);
      clearHandle();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        text: error?.response?.data?.error || "Oops! Something went wrong",
        customClass: {
          confirmButton: "custom-red-button",
        },
      });
    }
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);
      mediaRecorder.current.ondataavailable = handleDataAvailable;
      mediaRecorder.current.start();
      setIsRecording(true);
      setElapsedTime(0);
      setIsRunning(true);
    } catch (error) {}
  };
  const stopRecording = () => {
    if (mediaRecorder.current && isRecording) {
      mediaRecorder.current.stop();
      setIsRecording(false);
      setIsRunning(false);
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      setAudioChunks((prevChunks) => [...prevChunks, event.data]);
    }
  };

  const clearHandle = () => {
    setAudioChunks([]);
    setElapsedTime(0);
    setIsRunning(false);
    setRecordBlobLink(null);
  };

  const handleClose = () => {
    setIsOpenModal(false);
    setAudioChunks([]);
    setElapsedTime(0);
    setIsRunning(false);
    setRecordBlobLink(null);
  };

  // Convert recorded audio chunks into a URL
  React.useEffect(() => {
    if (audioChunks.length > 0) {
      const blob = new Blob(audioChunks, { type: "audio/wav" });
      const url = URL.createObjectURL(blob);
      setRecordBlobLink(url);
    }
  }, [audioChunks]);

  return (
    <Transition.Root show={isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[999999999999999]"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <div className="fixed inset-0 z-20 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 xs:p-1 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                style={{ boxShadow: "0px 0px 5px gray" }}
                className="relative transform overflow-hidden rounded-lg bg-[#fff]   text-left shadow-xl transition-all 2xl:w-[500px] lg:w-[500px] xs:w-full h-auto xs:mx-2 md:p-6 xs:p-3"
              >
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={handleClose} />
                </div>
                <div className="w-full">
                  <h2 className=" text-[22px] font-semibold ">
                    Audio Recorder
                  </h2>
                  {recordBlobLink ? (
                    <audio controls src={recordBlobLink} className="mt-6" />
                  ) : (
                    <div className="mt-3">
                      <AudioTimer
                        isRunning={isRunning}
                        elapsedTime={elapsedTime}
                        setElapsedTime={setElapsedTime}
                      />
                      {!isRecording ? (
                        <button
                          className="mt-2 bg-[#017663] py-2 px-6 rounded text-white cursor-pointer"
                          onClick={startRecording}
                          disabled={isRecording}
                        >
                          Start
                        </button>
                      ) : (
                        <button
                          className="mt-2 bg-gray-200 py-2 px-6 rounded cursor-pointer"
                          onClick={stopRecording}
                          disabled={!isRecording}
                        >
                          Stop
                        </button>
                      )}
                    </div>
                  )}
                  <div className=" mt-10 flex gap-2 justify-end">
                    <button
                      onClick={clearHandle}
                      className=" bg-gray-200 h-[45px] px-6 rounded  cursor-pointer font-bold"
                    >
                      Clear
                    </button>
                    {recordBlobLink && (
                      <button
                        onClick={handleSubmit}
                        className="bg-[#017663] text-white w-[120px] h-[45px] rounded  cursor-pointer font-bold flex items-center justify-center"
                        disabled={loading}
                      >
                        {loading ? <ButtonLoader_1 /> : "Submit"}
                      </button>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
export default AudioRecorder;

const AudioTimer = ({
  isRunning,
  setIsRunning,
  elapsedTime,
  setElapsedTime,
}) => {
  React.useEffect(() => {
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(() => setElapsedTime(elapsedTime + 1), 10);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, elapsedTime]);

  //  calculation
  const hours = Math.floor(elapsedTime / 360000);
  const minutes = Math.floor((elapsedTime % 360000) / 6000);
  const seconds = Math.floor((elapsedTime % 6000) / 100);
  const milliseconds = elapsedTime % 100;

  return (
    <div className=" text-[25px] mt-4 font-semibold ">
      <div className="time">
        {hours}:{minutes.toString().padStart(2, "0")}:
        <span className=" w-[23px] inline-block ">
          {" "}
          {seconds.toString().padStart(2, "0")}:
        </span>
        <span className=" w-[23px] inline-block ml-3">
          {milliseconds.toString().padStart(2, "0")}
        </span>
      </div>
    </div>
  );
};
