/* eslint-disable react/jsx-pascal-case */
import React, { Fragment, useRef, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import { ButtonLoader_1 } from "../shared/Loaders";
import { TbCloudUpload } from "react-icons/tb";
import useBulkUpload from "../../hooks/useBulkUpload";

function BulkUpload({ isOpenModal, setIsOpenModal }) {
  const cancelButtonRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const { bulkLoading, addBulk, setBulkLoading } = useBulkUpload();

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedFileTypes = [".xlsx"];
      const selectedFileType = file.name.toLowerCase();
      const isValidFileType = allowedFileTypes.some((type) =>
        selectedFileType.endsWith(type)
      );

      if (!isValidFileType) {
        setFileError("Please select only xlsx file");
        setSelectedFile(null);
        return;
      }

      setSelectedFile(file);
      setFileError("");
    } else {
      setFileError("Please upload file");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setSelectedFile(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const formatFileSize = (sizeInBytes) => {
    const sizeInMb = sizeInBytes / (1024 * 1024);
    return sizeInMb.toFixed(2) + " MB";
  };

  const handleClose = () => {
    setIsOpenModal(false);
    setFileError("");
    setSelectedFile(null);
  };

  const handleSubmit = async () => {
    if (selectedFile) {
      setBulkLoading(true);
      await addBulk({ file: selectedFile }, handleClose);
    } else {
      setBulkLoading(false);
      setFileError("Please upload file");
    }
  };
  return (
    <Transition.Root show={isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 xs:p-1 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#fff]  text-left shadow-xl transition-all 2xl:w-[500px] lg:w-[500px] xs:w-full h-auto xs:mx-2 md:p-6 xs:p-3">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={handleClose} />
                </div>
                <div className="flex flex-col">
                  <h1 className="2xl:text-[28px] font-[600] lg:text-[24px] font-roboto">
                    Bulk Upload
                  </h1>
                  <div
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    className="rounded-[10px] border border-dotted border-[#017663]  py-10 flex flex-col justify-center items-center w-full mt-2"
                  >
                    <TbCloudUpload className="text-[40px] text-[#017663]" />
                    <div className="d-flex align-items-center justify-center text-center">
                      <span className="text-[16px] text-[#7A7A7A]">
                        Drag and drop to upload <br />
                        or
                      </span>
                      <label
                        className="text-[#017663] text-[16px] font-normal cursor-pointer underline ml-1"
                        htmlFor="file"
                      >
                        Browse
                      </label>
                    </div>
                    <input
                      type="file"
                      id="file"
                      accept=".xlsx"
                      name="file"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </div>
                  {fileError && (
                    <p className="text-red-600 font-normal text-xs mt-[2px]">
                      {fileError}
                    </p>
                  )}
                  {selectedFile && (
                    <div className="w-full justify-start py-1">
                      <p className="text-[#017663] font-normal text-xs">
                        {selectedFile.name}
                      </p>
                      <p className="text-[#017663] font-normal text-[10px]">
                        {formatFileSize(selectedFile.size)}
                      </p>
                    </div>
                  )}
                  <div className="flex justify-end w-full gap-2  mt-5 cursor-pointer text-white">
                    <button
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={bulkLoading}
                      className="bg-[#1C1D1E] font-[600] text-white w-[120px] h-[45px] rounded-[10px] flex justify-center items-center"
                    >
                      {bulkLoading ? <ButtonLoader_1 /> : "Submit"}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default BulkUpload;
