/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { MdOutlinePersonAddAlt1 } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { HiOutlineDotsVertical } from "react-icons/hi";
import DashboardLayout from "../components/shared/DashboardLayout";
import AddTeacherModal from "../components/modals/AddTeacherModal";
import { useNavigate } from "react-router-dom";
import useGetTeachers from "../hooks/useGetTeachers";
import { skelton_data } from "../staticData/SkeltonData";
import { CardSkelton_4 } from "../components/shared/Skeltons";
import Paginations from "../components/shared/Paginations";
import GlobalContext from "../context/GlobalContext";
import DeleteModal from "../components/modals/DeleteModal";
import ShowMessage from "../components/shared/ShowMessage";
import { TbCloudUpload } from "react-icons/tb";
import BulkUpload from "../components/modals/BulkUpload";
import AppHead from "../seo/AppHead";
import { debounce } from "lodash";

const TeacherPage = () => {
  return (
    <>
      <AppHead title="Teachers - ELEMENT EDUCATION" />
      <DashboardLayout>
        <div className="bg-backgroundColor px-5 py-2 flex flex-col w-full h-auto">
          <MainLayout />
        </div>
      </DashboardLayout>
    </>
  );
};

export default TeacherPage;

function MainLayout() {
  const Navigate = useNavigate();
  const { updateResponse } = useContext(GlobalContext);
  const [showMenuIndex, setShowMenuIndex] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalBulkUpload, setIsOpenModalBulkUpload] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [query, setQuery] = useState("");

  const {
    GetTeachers,
    teacherLoading,
    teacherMessage,
    teachersData,
    totalPagesTeacher,
    currentPageTeacher,
    setCurrentPageTeacher
  } = useGetTeachers();

  const debouncedSearch = useCallback(
    debounce((value) => {
      setCurrentPageTeacher(1);
      GetTeachers(`?page=${currentPageTeacher}&search=${value}`);
    }, 1000),
    []
  );

  useEffect(() => {
    GetTeachers(`?page=${currentPageTeacher}&search=${query}`);
  }, [currentPageTeacher, updateResponse]);

  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    debouncedSearch(value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPageTeacher(value);
  };

  const handleMenu = (item, e, index) => {
    e.stopPropagation();
    setShowMenuIndex(index);
    setSelectedTeacher(item);
  };
  const NavigateToDetailsPage = (item) => {
    Navigate(`/teachers/${item.id}`);
  };

  const dropdownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowMenuIndex(null);
        setSelectedTeacher(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="flex md:flex-row xs:flex-col md:gap-0 xs:gap-2 md:items-center justify-between w-full">
        <div>
          <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[500] lg:text-[30px] font-roboto text-[#263238]">
            Teacher
          </h1>
          <span className="2xl:text-[18px] font-[400] lg:text-[15px] font-roboto text-[#7A7A7A]">
            Show current teachers in school
          </span>
        </div>
        <div className="flex items-center gap-3 xs:justify-end md:justify-start">
          <div className="flex items-center border bg-white border-[#DCDCDC] hover:border-[#017663] focus-within:border-[#017663] rounded-[10px] p-2 w-[280px] h-[50px] ">
            <input
              type="search"
              value={query}
              onChange={handleChange}
              placeholder="Search..."
              className="outline-none border-none w-[100%] h-full bg-transparent px-3"
            />
            <CiSearch className="text-[20px] text-[#C1C1C1]" />
          </div>
          <div
            className="flex items-center border bg-transparent border-[#017663] rounded-[6px] px-5 gap-3 lg:min-w-[150px] xs:w-auto h-[50px] cursor-pointer text-[#017663]"
            onClick={() => {
              setIsOpenModalBulkUpload(true);
            }}
          >
            <TbCloudUpload className="text-[18px] text-[#017663]" />
            <p className="2xl:font-[700] 2xl:text-[14px] lg:font-[500] lg:text-[14px] font-roboto lg:block xs:hidden">
              Bulk Upload
            </p>
          </div>
          <div
            className="  flex items-center border bg-[#017663] border-none rounded-[6px] px-5 gap-3 lg:min-w-[150px] xs:w-auto h-[50px] cursor-pointer text-white"
            onClick={() => {
              setIsOpenModal(true);
              setIsEditMode(false);
            }}
          >
            <MdOutlinePersonAddAlt1 className="text-[18px] text-white" />
            <p className="2xl:font-[700] 2xl:text-[14px] lg:font-[500] lg:text-[14px] font-roboto lg:block xs:hidden">
              Add New Teacher
            </p>
          </div>
        </div>
      </div>
      {teacherLoading ? (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mt-5">
          {skelton_data.slice(0, 8).map((_, index) => (
            <CardSkelton_4 key={index} />
          ))}
        </div>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mt-5">
          {teachersData?.map((item, index) => (
            <div key={index}>
              <TeacherCard
                key={index}
                item={item}
                index={index}
                show={showMenuIndex === index}
                handleMenu={(e) => handleMenu(item, e, index)}
                NavigateToDetailsPage={() => NavigateToDetailsPage(item)}
                setDeleteModal={setDeleteModal}
                setIsEditMode={setIsEditMode}
                setIsOpenModal={setIsOpenModal}
                setShowMenuIndex={setShowMenuIndex}
                dropdownRef={dropdownRef}
              />
            </div>
          ))}
        </div>
      )}
      {!teacherLoading && teacherMessage && (
        <div className="w-full h-[50vh] flex justify-center items-center">
          <ShowMessage message={teacherMessage} />
        </div>
      )}
      <div className="w-full flex justify-center items-center mt-5">
        <Paginations
          totalPages={totalPagesTeacher}
          currentPage={currentPageTeacher}
          handlePageChange={handlePageChange}
        />
      </div>

      <AddTeacherModal
        isOpenModal={isOpenModal}
        setIsOpenModal={setIsOpenModal}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        selectedTeacher={selectedTeacher}
        setSelectedTeacher={setSelectedTeacher}
      />

      <BulkUpload
        isOpenModal={isOpenModalBulkUpload}
        setIsOpenModal={setIsOpenModalBulkUpload}
        type="teachers"
      />

      <DeleteModal
        isOpenModal={deleteModal}
        setIsOpenModal={setDeleteModal}
        setSelected={setSelectedTeacher}
        url={`/api/teacher/delete/${selectedTeacher?.id}/`}
      />
    </>
  );
}

function TeacherCard({
  item,
  index,
  show,
  handleMenu,
  NavigateToDetailsPage,
  setDeleteModal,
  setIsEditMode,
  setIsOpenModal,
  setShowMenuIndex,
  dropdownRef
}) {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  return (
    <div
      className="w-full bg-[#FFFFFF] rounded-[10px] p-[20px] flex flex-col items-center justify-center relative cursor-pointer border border-transparent hover:border-[#017663]"
      key={index}
      onClick={NavigateToDetailsPage}
    >
      <div className="flex flex-col gap-1 items-center justify-center mb-4">
        {item.profile_picture && (
          <img
            src={`${BASE_URL}${item?.profile_picture}`}
            alt=""
            className="w-[60px] h-[60px] rounded-full"
          />
        )}
        {item.profile_picture === null && (
          <img
            src={"/avatar_dummy.png"}
            alt=""
            className="w-[60px] h-[60px] rounded-full"
          />
        )}
        <span className="2xl:text-[20px] font-[500] lg:text-[15px] font-roboto text-[#1C1D1E] mt-1">
          {item.first_name}
        </span>
        <span className="2xl:text-[16px] font-[400] lg:text-[12px] font-roboto text-[#7A7A7A]">
          {item.teacher_qualification}
        </span>
      </div>
      <div className="flex gap-2">
        <div className="flex flex-col items-center">
          <span className="text-[12px] font-[400] font-roboto text-[#7A7A7A] text-center">
            Total Assesments
          </span>
          <span className="2xl:text-[20px] font-[400] lg:text-[15px] font-roboto text-[#1C1D1E]">
            {item.total_assessments}
          </span>
        </div>
        <div className="border border-[#DCDCDC]"></div>
        <div className="flex flex-col items-center">
          <span className="text-[12px] font-[400] font-roboto text-[#7A7A7A] text-center">
            On Going Assesments
          </span>
          <span className="2xl:text-[20px] font-[400] lg:text-[15px] font-roboto text-[#1C1D1E]">
            {item.on_going_assessments}
          </span>
        </div>
        <div className="border border-[#DCDCDC]"></div>

        <div className="flex flex-col items-center">
          <span className="text-[12px] font-[400] font-roboto text-[#7A7A7A] text-center">
            No. of classes
          </span>
          <span className="2xl:text-[20px] font-[400] lg:text-[15px] font-roboto text-[#1C1D1E]">
            {item.total_classes}
          </span>
        </div>
      </div>
      <div className="absolute right-1 top-1 p-2">
        <HiOutlineDotsVertical
          className="text-[20px] text-[#7A7A7A] z-40"
          onClick={handleMenu}
        />
      </div>
      {show && (
        <DropMenu
          setDeleteModal={setDeleteModal}
          setIsEditMode={setIsEditMode}
          setIsOpenModal={setIsOpenModal}
          setShowMenuIndex={setShowMenuIndex}
          dropdownRef={dropdownRef}
        />
      )}
    </div>
  );
}

function DropMenu({
  setDeleteModal,
  setIsEditMode,
  setIsOpenModal,
  setShowMenuIndex,
  dropdownRef
}) {
  return (
    <div
      className="w-[80px] rounded border border-[#DCDCDC] bg-[#F9F9FF] p-1 absolute right-[2%] top-[15%]  flex flex-col z-[8888] "
      ref={dropdownRef}
    >
      <span
        onClick={(e) => {
          e.stopPropagation();
          setDeleteModal(true);
          setShowMenuIndex(null);
        }}
        className=" font-[400] text-[13px] font-roboto text-[#1C1D1E]  hover:bg-[#f09ea073] rounded px-2 cursor-pointer"
      >
        Remove
      </span>
      <span
        onClick={(e) => {
          e.stopPropagation();
          setIsEditMode(true);
          setIsOpenModal(true);
          setShowMenuIndex(null);
        }}
        className=" font-[400] text-[13px] font-roboto text-[#1C1D1E]  hover:bg-[#f09ea073] rounded px-2 cursor-pointer"
      >
        Edit
      </span>
    </div>
  );
}
