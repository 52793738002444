import React, { useEffect } from "react";
import TopPerformer from "../components/TopPerformer";
import DashboardLayout from "../components/shared/DashboardLayout";
import DashboardCard from "../components/DashboardCard";
import { FaArrowRightLong } from "react-icons/fa6";
import AssessmentCard from "../components/AssessmentCard";
import { useNavigate } from "react-router-dom";
import LowGradeStudent from "../components/LowGradeStudent";
import useGetAssessments from "../hooks/useGetAssessments";
import useGetStudents from "../hooks/useGetStudents";
import { CardSkelton_2, CardSkelton_3 } from "../components/shared/Skeltons";
import { skelton_data } from "../staticData/SkeltonData";
import useGetTotalMemebrs from "../hooks/useGetTotalMemebrs";
import ShowMessage from "../components/shared/ShowMessage";
import AppHead from "../seo/AppHead";

function DashboardPage() {
  const Navigate = useNavigate();
  const {
    GetAssessments,
    assessmentLoading,
    assessmentsData,
    assessmentMessage,
  } = useGetAssessments();
  const { GetStudents, studentLoading, studentsData, studentMessage } =
    useGetStudents();
  const { GetMembers, membersLoading, members } = useGetTotalMemebrs();

  useEffect(() => {
    GetAssessments(``);
    GetStudents(`?filter=low_grade`);
    GetMembers();
  }, []);

  const NavigateToDetailsPage = (item) => {
    Navigate(`/assessments/${item.id}`);
  };
  return (
    <>
      <AppHead title="ELEMENT EDUCATION" />
      <DashboardLayout>
        <div className="px-5 pt-4 pb-5">
          <DashboardCard membersLoading={membersLoading} members={members} />
          {/* Students */}
          <div className="my-5">
            <h1 className="2xl:font-[700] 2xl:text-[26px] lg:font-[500] lg:text-[20px]  font-roboto">
              Students
            </h1>
            {studentLoading ? (
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mt-2">
                {skelton_data.slice(0, 4).map((_, index) => (
                  <CardSkelton_2 key={index} />
                ))}
              </div>
            ) : (
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mt-2">
                {studentsData?.results?.slice(0, 4).map((item, index) => (
                  <div key={index}>
                    <LowGradeStudent item={item} index={index} />
                  </div>
                ))}
              </div>
            )}
            {!studentLoading && studentMessage && (
              <ShowMessage message={studentMessage} />
            )}
            <div className="w-full justify-end flex mt-3">
              <div
                className="flex items-center gap-2 border-b cursor-pointer text-[#2D2D2D] border-gray-600 hover:border-themeColor hover:text-themeColor"
                onClick={() => Navigate("/students")}
              >
                <p className="font-[300] text-[12px] font-roboto">View All</p>
                <FaArrowRightLong className="text-[12px]" />
              </div>
            </div>
          </div>
          {/* Assessments */}
          <div className="my-5">
            <h1 className="2xl:font-[700] 2xl:text-[26px] lg:font-[500] lg:text-[20px]  font-roboto">
              Assessments
            </h1>
            {assessmentLoading ? (
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mt-2">
                {skelton_data.slice(0, 4).map((_, index) => (
                  <CardSkelton_3 key={index} />
                ))}
              </div>
            ) : (
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-4 mt-2">
                {assessmentsData?.slice(0, 4).map((item, index) => (
                  <div key={index}>
                    <AssessmentCard
                      key={index}
                      item={item}
                      index={index}
                      NavigateToDetailsPage={() => NavigateToDetailsPage(item)}
                    />
                  </div>
                ))}
              </div>
            )}
            {!assessmentLoading && assessmentMessage && (
              <ShowMessage message={assessmentMessage} />
            )}
            <div className="w-full justify-end flex mt-3">
              <div
                className="flex items-center gap-2 border-b cursor-pointer text-[#2D2D2D] border-gray-600 hover:border-themeColor hover:text-themeColor"
                onClick={() => Navigate("/assessments")}
              >
                <p className="font-[300] text-[12px] font-roboto">View All</p>
                <FaArrowRightLong className="text-[12px]" />
              </div>
            </div>
          </div>
          {/* Top Performer */}
          <TopPerformer />
        </div>
      </DashboardLayout>
    </>
  );
}

export default DashboardPage;
