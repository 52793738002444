/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  Fragment,
  useRef,
  useState,
  useEffect,
  useContext
} from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Dialog, Transition } from "@headlessui/react";
import test from "../../assets/test.png";
import hand from "../../assets/hand.png";
import { FaPlus } from "react-icons/fa6";
import { BsTextareaT } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { MdOutlineDateRange } from "react-icons/md";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import { assessments } from "../../staticData/AssessmentsData";
import useAddAssessment from "../../hooks/useAddAssessment";
import { ButtonLoader_1 } from "../shared/Loaders";
import GlobalContext from "../../context/GlobalContext";
import ClassGradeDropdown from "../ClassGradeDropdown";
import AssessmentDropdown from "../AssessmentDropdown";

function AddAssessmentModal(props) {
  const cancelButtonRef = useRef(null);
  const [createAssignment, setCreateAssignment] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [filteredAssessments, setFilteredAssessments] = useState(null);
  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedParts, setSelectedParts] = useState({});
  const [formData, setFormData] = useState({
    assessment_name: "",
    due_date: "",
    Class: "",
    grade: "",
    assessment_data: []
  });
  const handleClose = () => {
    props.setIsOpenModal(false);
    setCreateAssignment(false);
    setFilteredAssessments(null);
    setFormData({
      assessment_name: "",
      due_date: "",
      Class: "",
      grade: "",
      assessment_data: []
    });
    setSelectedParts(null);
  };

  return (
    <Transition.Root show={props.isOpenModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-center justify-center sm:p-4 xs:p-1 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#fff]  text-left shadow-xl transition-all 2xl:w-[1100px] lg:w-[900px] xs:w-full min-h-[660px] xs:mx-2 md:p-6 xs:p-3">
                <div className="absolute top-[10px] right-[10px] cursor-pointer">
                  <AiOutlineClose onClick={handleClose} />
                </div>
                {createAssignment ? (
                  <>
                    <h1 className="2xl:font-[700] 2xl:text-[36px] lg:font-[600] lg:text-[32px]  font-roboto">
                      New Assessment
                    </h1>
                    <ModalBar
                      formData={formData}
                      setFormData={setFormData}
                      setShowErrors={setShowErrors}
                      showErrors={showErrors}
                      filteredAssessments={filteredAssessments}
                      setFilteredAssessments={setFilteredAssessments}
                      selectedGrade={selectedGrade}
                      setSelectedGrade={setSelectedGrade}
                      selectedTerm={selectedTerm}
                      setSelectedTerm={setSelectedTerm}
                      selectedParts={selectedParts}
                      setSelectedParts={setSelectedParts}
                    />
                    {filteredAssessments?.length === 0 && (
                      <div className="flex flex-col justify-center items-center gap-2 mt-10 py-2">
                        <img src={hand} alt="" />
                        <h1 className="mt-2 font-[500] text-[36px] text-[#1C1D1E]">
                          Choose Your Preference
                        </h1>
                        <p className="font-[400] text-[20px] text-[#4E4949]">
                          Select Your Preferred Assessment Category from the
                          Available Options!
                        </p>
                      </div>
                    )}

                    {filteredAssessments?.length > 0 && (
                      <AssessmentForm
                        formData={formData}
                        setFormData={setFormData}
                        setShowErrors={setShowErrors}
                        showErrors={showErrors}
                        handleClose={handleClose}
                        filteredAssessments={filteredAssessments}
                        selectedTerm={selectedTerm}
                        setSelectedTerm={setSelectedTerm}
                        selectedParts={selectedParts}
                        setSelectedParts={setSelectedParts}
                      />
                    )}
                  </>
                ) : (
                  <div className="w-full h-[90vh] flex flex-col items-center justify-center">
                    <img src={test} alt="" className="lg:w-[55%] 2xl:w-[50%]" />
                    <h1 className="2xl:font-[700] lg:font-[500] 2xl:text-[20px] lg:text-[18px] text-[#4E4949] font-roboto">
                      Build your test here
                    </h1>
                    <div className="bg-[#1C1D1E] rounded py-2 px-6 flex items-center justify-center gap-3 mt-6">
                      <FaPlus className="text-white" />
                      <button
                        className="2xl:font-[700] lg:font-[500] 2xl:text-[20px] lg:text-[18px] font-roboto text-white outline-none "
                        onClick={() => setCreateAssignment(true)}
                      >
                        Create assignment
                      </button>
                    </div>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AddAssessmentModal;

function ModalBar({
  formData,
  setFormData,
  setShowErrors,
  showErrors,
  filteredAssessments,
  setFilteredAssessments,
  selectedGrade,
  setSelectedGrade,
  selectedTerm,
  setSelectedTerm,
  selectedParts,
  setSelectedParts
}) {
  const titleRef = useRef(null);
  const dueDateRef = useRef(null);
  const [editableField, setEditableField] = useState("");

  const handleFieldClick = (field) => {
    setEditableField(field);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        titleRef.current &&
        !titleRef.current.contains(event.target) &&
        dueDateRef.current &&
        !dueDateRef.current.contains(event.target)
      ) {
        setEditableField("");
        setShowErrors(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  useEffect(() => {
    const filteredData = assessments.filter(
      (item) =>
        item.grade_check === String(formData?.grade?.grade) &&
        item.term === selectedTerm
    );
    setFilteredAssessments(filteredData);
  }, [formData.grade, selectedTerm, assessments]);

  return (
    <>
      <div className="w-[100%] py-4 px-6 min-h-[110px] rounded-[10px] mt-2 flex items-center bg-[#F0F0F5]">
        <div className="flex gap-2 w-[30%] px-2">
          <BsTextareaT className="text-[20px] mt-1" />
          <div className="w-full">
            <h1 className="2xl:font-[700] lg:font-[500] 2xl:text-[20px] lg:text-[18px] font-roboto">
              Title
            </h1>
            <div className="w-full flex h-[35px]" ref={titleRef}>
              {editableField === "title" ? (
                <>
                  <input
                    type="text"
                    placeholder="Enter assessment name"
                    name="assessment_name"
                    value={formData.assessment_name}
                    onChange={handleInputChange}
                    maxLength={30}
                    className="w-full border-[#017663] border px-2 py-1 rounded-[8px]"
                    autoFocus
                  />
                </>
              ) : (
                <div className="w-full flex items-center justify-between py-1">
                  <p className="">
                    {formData.assessment_name || "Enter assessment name"}
                  </p>
                  <BiEdit
                    className="cursor-pointer text-[15px] text-[#7A7A7A]"
                    onClick={() => handleFieldClick("title")}
                  />
                </div>
              )}
            </div>
            {showErrors && !formData.assessment_name && (
              <p className="text-red-500 text-[12px] mt-[1px]">
                This field is required
              </p>
            )}
          </div>
        </div>

        <div className="flex gap-2 w-[30%] px-2">
          <MdOutlineDateRange className="text-[20px] mt-1" />
          <div className="w-full">
            <h1 className="2xl:font-[700] lg:font-[500] 2xl:text-[20px] lg:text-[18px] font-roboto">
              Due Date
            </h1>
            <div className="w-full flex h-[35px]" ref={dueDateRef}>
              {editableField === "due_date" ? (
                <>
                  <input
                    type="date"
                    name="due_date"
                    placeholder="Choose date"
                    value={formData.due_date}
                    onChange={handleInputChange}
                    className="w-full border-[#017663] border px-2 py-1 rounded-[8px]"
                  />
                </>
              ) : (
                <div className="w-full flex items-center justify-between py-1">
                  <p className="">{formData.due_date || "Choose date"}</p>
                  <BiEdit
                    className="cursor-pointer text-[15px] text-[#7A7A7A]"
                    onClick={() => handleFieldClick("due_date")}
                  />
                </div>
              )}
            </div>
            {showErrors && !formData.due_date && (
              <p className="text-red-500 text-[12px] mt-[1px]">
                This field is required
              </p>
            )}
          </div>
        </div>
        <div className="flex justify-end gap-3 w-[40%]">
          <div className="min-w-[20%]">
            <ClassGradeDropdown
              formData={formData}
              setFormData={setFormData}
              selectedGrade={selectedGrade}
              setSelectedGrade={setSelectedGrade}
              selectedTerm={selectedTerm}
              setSelectedTerm={setSelectedTerm}
            />
            {showErrors && !formData.Class && !formData.grade && (
              <p className="text-red-500 text-[12px] mt-[1px]">
                This field is required
              </p>
            )}
          </div>
          <div className="min-w-[20%]">
            <AssessmentDropdown
              filteredAssessments={filteredAssessments}
              setFilteredAssessments={setFilteredAssessments}
              selectedGrade={selectedGrade}
              selectedTerm={selectedTerm}
              setSelectedTerm={setSelectedTerm}
              selectedParts={selectedParts}
              setSelectedParts={setSelectedParts}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function AssessmentForm({
  formData,
  setShowErrors,
  showErrors,
  handleClose,
  filteredAssessments,
  selectedTerm,
  setSelectedTerm,
  selectedParts,
  setSelectedParts
}) {
  const { userInfo } = useContext(GlobalContext);
  const { loading, addAssessment } = useAddAssessment();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const filteredAssessmentsData = filteredAssessments
    .filter((assessment) => assessment.checked)
    .map((assessment) => {
      if (!Array.isArray(assessment.assessment_data)) {
        return null;
      }

      const filteredData = assessment.assessment_data.filter((data) => {
        const partKey = `Part ${data?.name?.split(" - ")[0].trim()}`;
        const isSelected = selectedParts[selectedTerm]?.[partKey] || false; // Check based on selected term

        return isSelected; // Only include parts that are selected
      });

      return {
        ...assessment,
        assessment_data: filteredData
      };
    })
    .filter(
      (assessment) => assessment && assessment.assessment_data.length > 0
    );

  const mergedAssessmentData = filteredAssessmentsData.reduce(
    (accumulator, assessment) => {
      return accumulator.concat(assessment.assessment_data);
    },
    []
  );

  const [skillsData, setSkillsData] = useState(
    mergedAssessmentData.map((tab) => ({
      skillToReview: "",
      skillToTeach: ""
    }))
  );

  const handleSkillToReviewChange = (e, tabIndex) => {
    const updatedSkillsData = [...skillsData];
    updatedSkillsData[tabIndex].skillToReview = e.target.value;
    setSkillsData(updatedSkillsData);
  };

  const handleSkillToTeachChange = (e, tabIndex) => {
    const updatedSkillsData = [...skillsData];
    updatedSkillsData[tabIndex].skillToTeach = e.target.value;
    setSkillsData(updatedSkillsData);
  };

  const handleNextTab = () => {
    const nextIndex =
      selectedTabIndex < mergedAssessmentData.length - 1
        ? selectedTabIndex + 1
        : selectedTabIndex;
    setSelectedTabIndex(nextIndex);
  };

  const handlePreviousTab = () => {
    const prevIndex =
      selectedTabIndex > 0 ? selectedTabIndex - 1 : selectedTabIndex;
    setSelectedTabIndex(prevIndex);
  };

  const handleSubmit = async () => {
    setShowErrors(true);
    if (
      formData.assessment_name !== "" &&
      formData.due_date !== "" &&
      formData.grade !== ""
    ) {
      setShowErrors(false);
      const assessmentData = mergedAssessmentData?.map((tab, index) => {
        const terms = ["Fall", "Winter", "Spring"].map((term) => ({
          checked: term === selectedTerm,
          name: term
        }));

        return {
          name: tab.name,
          total_score: tab.total_score,
          skill_to_review: skillsData[index]?.skillToReview,
          skill_to_teach: skillsData[index]?.skillToTeach,
          headings: JSON.stringify(tab.headings),
          columns: tab.columns,
          category: tab.category,
          counts: tab.counts
        };
      });

      const payload = {
        assessment_name: formData?.assessment_name,
        due_date: formData?.due_date,
        grade: parseInt(formData?.grade?.grade_id),
        Class: parseInt(formData?.Class),
        user: userInfo?.user?.is_teacher ? userInfo?.user?.id : userInfo?.id,
        assessment_data: assessmentData,
        assessment_length: mergedAssessmentData.length,
        terms: selectedTerm
      };
      await addAssessment(payload, handleClose);
    }
  };

  function isLastIndex(array, index) {
    return index === array.length - 1;
  }

  return (
    <div className="mt-5">
      <p className="font-[500] 2xl:text-[26px] lg:text-[20px] font-roboto text-[#1C1D1E]">
        {mergedAssessmentData[selectedTabIndex] && (
          <p className="font-[500] 2xl:text-[26px] lg:text-[20px] font-roboto text-[#1C1D1E]">
            {mergedAssessmentData[selectedTabIndex]?.name}
          </p>
        )}
      </p>

      {mergedAssessmentData?.length > 0 && (
        <>
          <div className="max-h-[350px] overflow-y-auto border border-[#DCDCDC] rounded">
            <div className="w-full bg-backgroundColor p-4 relative">
              <div
                className={`w-full rounded-[10px] bg-white p-6 mt-2 ${
                  mergedAssessmentData[selectedTabIndex]?.headings?.length >
                    0 && "flex justify-center items-center gap-x-16"
                }`}
              >
                {/* {mergedAssessmentData[selectedTabIndex]?.headings?.length >
                  0 && (
                  <div className="grid grid-cols-1 gap-x-10 gap-y-8">
                    {mergedAssessmentData[selectedTabIndex]?.headings?.map(
                      (item, index) => (
                        <div
                          className={`${
                            mergedAssessmentData[selectedTabIndex]?.columns ===
                            3
                              ? "w-auto"
                              : "w-auto"
                          } h-[70px] flex items-center`}
                          key={index}
                        >
                          <p className="font-[400] text-[20px] text-[#1C1D1E]">
                            {item}
                          </p>
                        </div>
                      )
                    )}
                  </div>
                )} */}
                <div
                  className={`grid ${
                    mergedAssessmentData[selectedTabIndex]?.columns === 9
                      ? "grid-cols-9"
                      : mergedAssessmentData[selectedTabIndex]?.columns === 5
                      ? "grid-cols-5"
                      : mergedAssessmentData[selectedTabIndex]?.columns === 3 &&
                        "grid-cols-3"
                  }  gap-x-10 gap-y-8`}
                >
                  {mergedAssessmentData[selectedTabIndex]?.counts?.map(
                    (item, index) => (
                      <div
                        className={`relative ${
                          mergedAssessmentData[selectedTabIndex]?.columns ===
                            3 ||
                          mergedAssessmentData[selectedTabIndex]?.columns === 5
                            ? "w-[130px]"
                            : "w-[70px]"
                        } h-[70px] flex justify-center items-center`}
                        key={index}
                      >
                        <input
                          type="text"
                          value={item}
                          readOnly
                          maxLength={1}
                          className={`w-full h-full text-center rounded-[10px] bg-[#F9F9FF] text-[#7A7A7A] text-[20px] font-bold`}
                          placeholder="-"
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-3 mt-4">
                <div className="flex border border-gray-400 bg-white rounded py-3 px-2 w-[35%]">
                  <p className="font-robototo font-[400] text-[16px]">Score:</p>
                  <input
                    type="text"
                    readOnly
                    placeholder={`----------------------------/${mergedAssessmentData[selectedTabIndex]?.total_score}`}
                    className="ml-2 w-[100%] font-robototo font-[400] text-[16px] "
                  />
                </div>
                <div className="flex border border-gray-400 bg-white rounded py-3 px-2 w-[100%]">
                  <p className="font-robototo font-[400] text-[16px] w-[16%]">
                    Skill to review:
                  </p>
                  <input
                    type="text"
                    value={skillsData[selectedTabIndex]?.skillToReview}
                    onChange={(e) =>
                      handleSkillToReviewChange(e, selectedTabIndex)
                    }
                    placeholder="---------------------------------------------------------------------------------------------------------------------------------------------------"
                    className="ml-2 w-[100%] font-robototo font-[400] text-[16px] "
                  />
                </div>
                <div className="flex border border-gray-400 bg-white rounded py-3 px-2 w-[100%]">
                  <p className="font-robototo font-[400] text-[16px] w-[15%]">
                    Skill to teach:
                  </p>
                  <input
                    type="text"
                    value={skillsData[selectedTabIndex]?.skillToTeach}
                    onChange={(e) =>
                      handleSkillToTeachChange(e, selectedTabIndex)
                    }
                    placeholder="---------------------------------------------------------------------------------------------------------------------------------------------------"
                    className="ml-2 w-[100%] font-robototo font-[400] text-[16px] "
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center mt-4">
            {selectedTabIndex === 0 ? (
              <div className="flex gap-2 items-center justify-start text-gray-400 font-[700] text-[16px] font-roboto min-w-[120px] h-[45px]">
                <FaArrowLeft />
                <button>Previous</button>
              </div>
            ) : (
              <div
                onClick={handlePreviousTab}
                className="flex gap-2 items-center justify-start text-[#AD2126] font-[700] text-[16px] font-roboto min-w-[120px] h-[45px] cursor-pointer"
              >
                <FaArrowLeft />
                <button>Previous</button>
              </div>
            )}

            {isLastIndex(mergedAssessmentData, selectedTabIndex) ? (
              <button
                className="min-w-[120px] h-[45px] bg-[#017663] rounded-[6px] text-white flex justify-center items-center px-6 cursor-pointer font-[700] text-[16px] font-roboto"
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? <ButtonLoader_1 /> : "Create"}
              </button>
            ) : (
              <div
                onClick={handleNextTab}
                className="flex items-center justify-end gap-2 text-[#017663] min-w-[120px] h-[45px] cursor-pointer font-[700] text-[16px] font-roboto"
              >
                <button>Next</button>
                <FaArrowRight />
              </div>
            )}
          </div>
        </>
      )}

      {/* If Data not found */}
      {mergedAssessmentData?.length === 0 && (
        <div className="flex flex-col justify-center items-center gap-2 mt-10 py-2">
          <img src={hand} alt="" />
          <h1 className="mt-2 font-[500] text-[36px] text-[#1C1D1E]">
            Choose Your Preference
          </h1>
          <p className="font-[400] text-[20px] text-[#4E4949]">
            Select Your Preferred Assessment Category from the Available
            Options!
          </p>
        </div>
      )}
    </div>
  );
}
